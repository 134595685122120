export class APIUtils {

    static getAPIEndpoint(signature) {

        const apiGateWay = window.location.href.includes('localhost') ? 'http://localhost:3001/api' : '/api';

        const frontendEndpoints = {
            'log-in': '/login',
            'sign-up': '/signup',
            'update-profile': '/update-profile',
            'get-profile': '/get-profile',
            'change-password': '/change-password',
            'upload-img-to-s3': '/upload-img-to-s3',
            'departments': '/departments',
            'doctors': '/doctors',
            'doctor-details': '/doctor-details',
            'book-appointment': '/book-appointment',
            'create-order': '/create-order',
            'servicetype-list': '/servicetype-list',
            'myappointment-list': '/myappointment-list',
            'addresses': '/addresses',
            'retry-payment': '/retry-payment',
            'cancel-appointment': '/cancel-appointment',
            'update-appointment-time': '/update-appointment-time',
        };

        return apiGateWay + (frontendEndpoints[signature] || '');
    }

    static getAdminAPIEndpoint(signature) {

        const apiGateWay = window.location.href.includes('localhost') ? 'http://localhost:3001/api' : '/api';

        const adminEndpoints = {
            'login': '/login',
            'signup': '/signup',
            'department': '/department',
            'qualification': '/qualification',
            'medicinecategory': '/medicinecategory',
            'doctor': '/doctor',
            'import-doctor': '/import-doctor',
            'vendor': '/vendor',
            'import-vendor': '/import-vendor',
            'medicine': '/medicine',
            'uploadmedicine': '/uploadmedicine',
            'servicetype': '/servicetype',
            'create-servicetype': '/create-servicetype',
            'update-servicetype': '/update-servicetype',
            'servicetype-list': '/servicetype-list',
            'delete-servicetype': '/delete-servicetype',
            'create-fieldexecutive': '/create-fieldexecutive',
            'update-fieldexecutive': '/update-fieldexecutive',
            'fieldexecutive-list': '/fieldexecutive-list',
            'patient-list': '/patient-list',
            'update-user-status': '/update-user-status',
            'delete-user': '/delete-user',
            'addtest': '/addtest',
            'edittest': '/edittest',
            'uploadtest': '/uploadtest',
            'testlist': '/testlist',
            'delete-test': '/delete-test',
            'appointments': '/appointments',
            'update-appointment-time': '/update-appointment-time',
        };

        return apiGateWay + '/admin' + (adminEndpoints[signature] || '');
    }
}
