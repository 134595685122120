import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PropTypes from 'prop-types';
import { confirmBox, formatDateTime } from '../../../../utils/utils';
import Swal from 'sweetalert2';
import httpClient from '../../../../services/http';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { CustomDateTimePicker } from '../../../../components/datetimepicker';
import { APIUtils } from '../../../../utils/apiutils';
import { DATE_TIME_FORMAT } from '../../../../utils/constants';

export function ViewAppointment({ open, data, setOpen, refresh }) {
    const [appointmentData, setAppointmentData] = useState(data);
    const [openRescheduleAppt, setOpenRescheduleAppt] = useState(false);
    const [newApptDateTime, setNewApptDateTime] = useState(data?.apptdatetime);

    useEffect(() => {
        setAppointmentData(data);
        setNewApptDateTime(data.apptdatetime);
    }, [data]);

    const updateStatus = async (status) => {
        let text = '';
        if (status === 'CONFIRMED') text = 'You want to Accept?';
        else if (status === 'CANCELLED') text = 'You want to Decline?';
        let icon = status === 'CONFIRMED' ? 'info' : 'warning';
        if (await confirmBox(text, 'Are you sure?', icon)) {
            const url = APIUtils.getAdminAPIEndpoint('appointments');
            await httpClient.put(url + `/${data.id}`, { status });
            setAppointmentData({ ...appointmentData, status });
            const message = `${status} Successfully!`;
            Swal.fire('Status!', message, 'success');
            handleClose();
            refresh();
        }
    };

    const updateAppointmentTime = async () => {
        const url = APIUtils.getAdminAPIEndpoint('update-appointment-time');
        const res = await httpClient.post(url, { id: data.id, apptdatetime: newApptDateTime }).then(res => res.data);
        if (res.status === 'success') {
            Swal.fire('Status!', 'Appointment date & time updated successfully!', 'success');
            setOpenRescheduleAppt(false);
            handleClose();
            refresh();
        } else {
            Swal.fire('Something Went Wrong!', '', 'error');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getStatus = (status) => {
        switch (status) {
            case 'PENDING':
                return <Typography variant='body1' sx={{ color: '#ed6c02', fontWeight: 600 }}>{status}</Typography>;
            case 'CONFIRMED':
                return <Typography variant='body1' sx={{ color: 'green', fontWeight: 600 }}>{status}</Typography>;
            case 'CANCELLED':
                return <Typography variant='body1' sx={{ color: 'red', fontWeight: 600 }}>{status}</Typography>;
            case 'COMPLETED':
                return <Typography variant='body1' sx={{ color: 'blue', fontWeight: 600 }}>{status}</Typography>;
            default:
                return <Typography variant='body1' sx={{ color: 'gray', fontWeight: 600 }}>{status}</Typography>;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
                className='viewDetails'
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>View Appointment</div>
                    <IconButton onClick={handleClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Doctor Name</Grid>
                        <Grid item md='6' sm='12' xs='12'>{[data?.doctorprofile?.firstName, data?.doctorprofile?.middleName, data?.doctorprofile?.lastName].filter(e => e).join(' ')}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Name</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.name}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Email ID</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.email}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Mobile Number</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.mobile}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Appointment Date & Time</Grid>
                        <Grid item md='6' sm='12' xs='12'>{formatDateTime(data.apptdatetime)}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Appointment Type</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.appointmentType === 1 ? 'Personal Visit' : 'Tele Consultation'}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Description</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.description}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Payment</Grid>
                        <Grid item md='6' sm='12' xs='12'>{data.paymentStatus}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md='6' sm='12' xs='12'>Status</Grid>
                        <Grid item md='6' sm='12' xs='12'>{getStatus(data.status)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2, mb: 2, p: 1 }}>
                    {
                        (data.paymentStatus === 'PAID' && data.status === 'PENDING') && <>
                            <Button variant='contained' color='error' onClick={() => updateStatus('CANCELLED')}>Reject</Button>
                            <Button variant='contained' color='success' onClick={() => updateStatus('CONFIRMED')}>Accept</Button>
                        </>
                    }
                    {
                        (data.status === 'CONFIRMED') && <>
                            <Button variant='contained' color='warning' onClick={() => setOpenRescheduleAppt(true)}>Reschedule</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openRescheduleAppt} maxWidth='xs' fullWidth>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>Reschedule Appointment</div>
                    <IconButton size='small' onClick={() => setOpenRescheduleAppt(false)}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container className='profile-grid'>
                        <Grid item md='12' sm='12' xs='12'>
                            <CustomDateTimePicker
                                name={'apptdatetime'}
                                value={dayjs(new Date(newApptDateTime))}
                                handleChange={(value) => setNewApptDateTime(format(new Date(value), DATE_TIME_FORMAT))}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='secondary' onClick={() => setOpenRescheduleAppt(false)}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={() => updateAppointmentTime()}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ViewAppointment.propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};
