import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PropTypes from 'prop-types';

function ViewVendorDetails({ data, open, closePopup }) {

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={() => closePopup()}
            fullWidth={true}
            maxWidth='md'
            className='viewDetails'
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    Vendor Info
                </div>
                <IconButton onClick={() => closePopup()}>
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Legal Name</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.vendorLegalName}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Name</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.name}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Profile Pic</Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        {data?.profilePic && <img style={{ maxWidth: '100px' }} src={data?.profilePic} alt='profile pic' />}
                        {!data?.profilePic && <span>Not Available</span>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Legal Doc</Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        {data?.legalDoc && <a href={data?.legalDoc}>{data?.legalDoc.split('/').pop()}</a>}
                        {!data?.legalDoc && <span>Not Available</span>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Registration No</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.registrationNumber}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Email ID</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.user?.email}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Mobile Number</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.mobileNumber}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Service Hour</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.serviceStartHour}-{data?.serviceEndHour}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Bank Name</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.bankName}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>IFSC Code</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.IFSCCode}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Bank Account No.</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.bankAccountNo}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>UPI Id</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.UPIID}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Account Holder Name</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.accountHolderName}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Service Type</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.serviceTypeName}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Service Cost</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.serviceCost}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Settlement Type</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.settlementType}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Address</Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        {[data?.houseOrPlotNo, data?.area, data?.city, data?.district, data?.state, data?.zipcode, data?.country].filter(e => e).join(',')}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Admin Type</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.adminType}</Grid>
                </Grid>
                <Grid container>
                    <Grid item md='6' sm='12' xs='12'>Status</Grid>
                    <Grid item md='6' sm='12' xs='12'>{data?.user.status}</Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

ViewVendorDetails.propTypes = {
    data: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
};

export default ViewVendorDetails;
