import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { doctors } from './constants';
import personIcon from '../../../assets/images/person_icon.svg';
import videoIcon from '../../../assets/images/video_icon.svg';

function MeetOurDoctors() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				backgroundColor: '#FFF8E4',
				minHeight: 700,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '60px',
			}}
		>
			<Grid>
				<Typography gutterBottom variant='h3'>
					Meet Our Certified Doctors
				</Typography>
				<Typography gutterBottom variant='subtitle1' color='text.secondary'>
					Our online doctors have an average of 15 years experience and a
					<br /> 98% satisfaction rating, they really set us apart!
				</Typography>
			</Grid>
			<Grid
				container
				spacing={2}
				columns={{ xs: 1, sm: 8, md: 12 }}
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{doctors.map(
					({
						audioConsultation,
						designation,
						image,
						name,
						videoConsultation,
					}, index) => (
						<Grid item key={'doctor-' + index}>
							<Card sx={{ width: 206, height: 310 }}>
								<CardMedia component='img' height='192' image={image} />
								<CardContent
									style={{ padding: 4 }}
									sx={{ height: '80px', boxSizing: 'border-box' }}
								>
									<Typography gutterBottom className='doc-name'>
										{name}
									</Typography>
									<Typography variant='body2' color='text.secondary'>
										{designation}
									</Typography>
								</CardContent>
								<CardActions
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Button size='small'>
										<img src={personIcon} alt='audio icon' />
										<Typography
											variant='body2'
											color='text.secondary'
											style={{ marginLeft: '2px' }}
										>
											{audioConsultation}
										</Typography>
									</Button>
									<Button size='small'>
										<img src={videoIcon} alt='video icon' />
										<Typography
											variant='body2'
											color='text.secondary'
											style={{ marginLeft: '2px' }}
										>
											{videoConsultation}
										</Typography>
									</Button>
								</CardActions>
							</Card>
						</Grid>
					)
				)}
			</Grid>
			<Grid>
				<Button
					variant='contained'
					className='btn-main'
					onClick={() => navigate('/finddoctors')}
				>
					Explore All
				</Button>
			</Grid>
			<br />
		</Box>
	);
}

export default MeetOurDoctors;
