import React, { useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { Divider, Grid, IconButton } from '@mui/material';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { objectToFormData } from '../../../utils/utils';
import { uploadMedicine } from './service';
import CustomDropdown from '../../../components/customdropdown';

export function BulkUploadModal(props) {
    const { open, categoryList, setOpen, refresh } = props;
    const fileRef = useRef(null);
    const [fileName, setFileName] = useState('');

    const initialValues = {
        medicineCategoryId: '',
        file: undefined
    };

    const validationSchema = () => Yup.object().shape({
        medicineCategoryId: Yup.string().required('Please select category'),
        file: Yup.string().required('Please browse csv file'),
    });

    const browseFile = (e, form, field) => {
        setFileName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (values) => {
        Swal.showLoading();
        try {
            const formData = objectToFormData(values);
            let res = await uploadMedicine(formData);
            if (res.status === 'success') {
                Swal.fire(res.message, '', 'success');
                refresh();
                handleClose();
            } else {
                Swal.fire(res.message, '', 'error');
            }
        } catch (e) {
            console.error(e);
            Swal.fire('Something Went Wrong!', '', 'error');
        } finally {
            Swal.hideLoading();
        }
    };

    // const handleFileChange = async (e) => {
    //     const file = e.target.files[0];
    //     setFileName(file.name);
    //     const tmp = { ...formData, 'file': file };
    //     setFormData(tmp);
    // };

    const downloadSampleCSV = () => {
        const header = ['composition', 'weight', 'trade_name', 'medicine_name', 'product_type', 'price', 'sell_price', 'quantity_info', 'manufacture', 'mfgCountry', 'status', 'product_details', 'users', 'benefits', 'side_effect', 'howToUse'];
        const sampleData = ['500-mg', '10 mg', 'GlaxoSmithKline', 'Paracetamol', 'Analgesic and antipyretic drug', '20', '30', '2 Tablets per day', 'Cipla', 'Inida', 'active', 'Paracetamol is an analgesic and antipyretic drug that is used to temporarily relieve mild-to-moderate pain and fever', 'Adults and children above 10 years', 'Relieve mild-to-moderate pain and fever', 'Skin rashes, itching or hives', 'After lunch & dinner'];
        const csvContent = 'data:text/csv;charset=utf-8,' + header.join(',') + '\n' + sampleData.join(',') + '\n';
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'import-medicines.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Dialog open={open} maxWidth='xs' fullWidth>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            Upload Medicines (<a href='javascript:;' onClick={downloadSampleCSV}>Sample CSV</a>)
                        </div>
                        <IconButton onClick={handleClose} size='small'>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                                <Field
                                    required
                                    id='medicineCategoryId'
                                    name='medicineCategoryId'
                                    label='Select Category'
                                    options={categoryList}
                                    component={CustomDropdown}
                                />
                                <ErrorMessage name='medicineCategoryId'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} sx={{ mb: 2 }}>
                                <Field name='file'>
                                    {({ field, form }) => (
                                        <>
                                            <input type='file' hidden ref={fileRef}  onChange={(e) => browseFile(e, form, field)} accept=".csv" />
                                            <Button color='secondary' onClick={() => fileRef.current.click()}>
                                                <FileUploadOutlinedIcon />
                                            </Button>
                                            <Typography>{fileName || 'Browse File'}</Typography>
                                        </>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <FormControl>
                            <Button size='small' variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
                        </FormControl>
                        <FormControl >
                            <Button type='submit' size='small' variant='contained' color='primary'>Submit</Button>
                        </FormControl>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
}

BulkUploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    categoryList: PropTypes.string.isRequired,
    setOpen: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};