import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, DialogTitle, Box, Typography, IconButton, Button, Grid, DialogActions } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { importDoctors } from './service';
import PropTypes from 'prop-types';
import { objectToFormData } from '../../../utils/utils';

function ImportDoctors({ open, closePopup, refresh }) {
    const [fileName, setFileName] = useState('');
    const validationSchema = Yup.object().shape({
        file: Yup.string().required('Please browse the file to import'),
    });

    const initialValues = {
        file: undefined
    };

    const handleClose = () => closePopup();

    const browseFile = (e, form, field) => {
        setFileName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const handleSubmit = async (values) => {
        try {
            const formData = objectToFormData(values);
            const res = await importDoctors(formData);
            if (res?.status === 'success') {
                Swal.fire(res?.message, '', 'success');
                refresh();
                handleClose();
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    const downloadSampleCSV = () => {
        const header = ['firstName', 'middleName', 'lastName', 'mobileNumber', 'email', 'gender', 'age', 'awards', 'experience', 'vendorType', 'registrationNumber', 'serviceCost', 'settlementType', 'country', 'state', 'district', 'city', 'street', 'area', 'houseOrPlotNo', 'zipcode', 'serviceName', 'personalVisitPrice', 'teleConsultationPrice', 'about', 'departmentId', 'qualificationId', 'departmentId', 'qualificationId', 'serviceTypeId', 'teleConsultation'];
        const sampleData = ['Alok', 'Kumar', 'Mohanty', '9861456789', 'alokmohanty@gmail.com', 'male', 33, 'Best Doctor', 10, 'Clinic', 'REGD1234567890', 500, 'UPI', 'India', 'Odisha', 'Khordha', 'Bhubaneswar', 'Rasulgarh', 'Mancheswar', '101', '751010', 'Radiance Clinic', 500, 600, 'About', 1, 1, 1, 1, 1, 1];
        const csvContent = 'data:text/csv;charset=utf-8,' + header.join(',') + '\n' + sampleData.join(',') + '\n';
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'doctors.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth='xs'
                className='viewDetails'
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form>
                            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>Import Doctors</div>
                                <IconButton onClick={() => handleClose()}><CloseOutlinedIcon /></IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{ m: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Browse CSV File ( <a href='javascript:;' onClick={downloadSampleCSV}>Sample CSV</a>):
                                            </Typography>
                                            <Field name='file'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept=".csv"
                                                                name={field.name}
                                                                hidden={true}
                                                                onChange={(e) => browseFile(e, form, field)}
                                                            />
                                                            Chose File
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{fileName ? fileName : 'No file selected'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary'>Submit</Button>
                                </Box>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>

        </>
    );
}

ImportDoctors.propTypes = {
    open: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default ImportDoctors;
