import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import httpClient from '../../../../services/http';
import { formatDateTime } from '../../../../utils/utils';
import { ViewAppointment } from './view';
import { APIUtils } from '../../../../utils/apiutils';

export default function Appointments() {
    const [rows, setRows] = useState([]);
    const [viewAppointment, setViewAppointment] = useState(false);
    const [data, setData] = useState(null);

    const columns = [
        { field: 'slNo', headerName: 'Sl No', flex: 0.5 },
        {
            field: 'doctor', headerName: 'Doctor Name', flex: 1,
            renderCell: ({ row }) => [row.doctorprofile.firstName, row.doctorprofile.middleName, row.doctorprofile.lastName].filter(e => e).join(' ')
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'apptdatetime', headerName: 'Date & Time', flex: 1.5,
            renderCell: ({ row }) => formatDateTime(row.apptdatetime)
        },
        {
            field: 'appointmentType', headerName: 'Cosulation Type', flex: 1,
            renderCell: ({ row }) => row.appointmentType === 1 ? 'Personal Visit' : 'Tele Consultation'
        },
        {
            field: 'paymentStatus',
            headerName: 'Payment Status',
            flex: 1,
            renderCell: ({ row }) => {
                if (row.paymentStatus === 'PAID') {
                    return <Chip label={row.paymentStatus} color='success' />;
                } else if (row.paymentStatus === 'UNPAID') {
                    return <Chip label={row.paymentStatus} color='warning' />;
                } else {
                    return <Chip label={row.paymentStatus} color='error' />;
                }
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                if (row.status === 'PENDING') {
                    return <Chip label={row.status} color='warning' />;
                } else if (row.status === 'CONFIRMED') {
                    return <Chip label={row.status} color='success' />;
                } else if (row.status === 'CANCELLED') {
                    return <Chip label={row.status} color='error' />;
                } else {
                    return <Chip label={row.status} color='error' />;
                }
            }
        }
    ];

    const viewAppointmentPopup = (data) => {
        setViewAppointment(true);
        setData(data);
    };

    const fetchData = async () => {
        try {
            const url = APIUtils.getAdminAPIEndpoint('appointments');
            const res = await httpClient.get(url).then(res => res.data);
            if (res.status === 'success') {
                setRows(res.data);
            } else {
                setRows([]);
            }
        } catch (e) {
            setRows([]);
            console.log(e);
        }
    };

    const handleRowClick = (params) => {
        viewAppointmentPopup(params.row);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const rowsWithSlNo = rows.map((row, index) => ({
        ...row,
        slNo: index + 1,
    }));

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}>
                    <GridToolbarQuickFilter />
                </div>
            </GridToolbarContainer>
        );
    };

    return (
        <div className='admin-area-2'>
            <Typography variant='h5'>Appointments List</Typography>
            <div className='page-content'>
                <Paper>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        columns={columns}
                        slots={{ toolbar: CustomToolbar }}
                        rows={rowsWithSlNo}
                        pageSize={25}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                showExport: false,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        onRowClick={handleRowClick}
                    />
                </Paper>
            </div>

            {data && <ViewAppointment
                open={viewAppointment}
                data={data}
                refresh={fetchData}
                setOpen={setViewAppointment}
            />}

        </div>
    );
}
