import React, { useEffect, useState } from 'react';
import './style.css';
// import { useSelector } from 'react-redux';
import { Paper, Container, Divider, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, IconButton } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { formatDateTime, confirmBox } from '../../../utils/utils';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { CustomDateTimePicker } from '../../../components/datetimepicker';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { DATE_TIME_FORMAT } from '../../../utils/constants';
import TreatuLogo from '../../../assets/logos/treatu_logo.png';

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
};

export default function MyAppointmentList() {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openRescheduleAppt, setOpenRescheduleAppt] = useState(false);
    const [newApptDateTime, setNewApptDateTime] = useState(selectedRow?.apptdatetime);
    // const userInfo = useSelector(state => state.profile?.result);

    const rowsWithSlNo = rows.map((row, index) => ({
        ...row,
        slNo: index + 1,
        orderID: '#' + row.id.toString().padStart(6, '0'),
        doctorName: [row.doctorprofile.firstName, row.doctorprofile.middleName, row.doctorprofile.lastName].filter(e => e).join(' '),
        date: formatDateTime(row.apptdatetime),
        cosulationType: row.appointmentType === 1 ? 'Personal Visit' : 'Tele Consultation',
        doctorFees: 'Rs. ' + row.amount
    }));

    const getStatus = (status) => {
        switch (status) {
            case 'PENDING':
                return <Typography variant='body1' sx={{ color: '#ed6c02', fontWeight: 600 }}>{status}</Typography>;
            case 'PAID':
            case 'CONFIRMED':
                return <Typography variant='body1' sx={{ color: 'green', fontWeight: 600 }}>{status}</Typography>;
            case 'FAILED':
            case 'CANCELLED':
                return <Typography variant='body1' sx={{ color: 'red', fontWeight: 600 }}>{status}</Typography>;
            case 'COMPLETED':
                return <Typography variant='body1' sx={{ color: 'blue', fontWeight: 600 }}>{status}</Typography>;
            default:
                return <Typography variant='body1' sx={{ color: 'gray', fontWeight: 600 }}>{status}</Typography>;
        }
    };

    const columns = [
        { field: 'slNo', headerName: 'SL No', minWidth: 100 },
        { field: 'orderID', headerName: 'Order ID', flex: 1, minWidth: 100 },
        { field: 'doctorName', headerName: 'Doctor', flex: 1, minWidth: 100 },
        { field: 'date', headerName: 'Date & Time', flex: 1, minWidth: 100 },
        { field: 'cosulationType', headerName: 'Cosulation Type', flex: 1, minWidth: 100 },
        { field: 'doctorFees', headerName: 'Doctor Fees', flex: 1, minWidth: 100 },
        { field: 'paymentStatus', headerName: 'Payment Status', flex: 1, minWidth: 100, renderCell: ({ row }) => getStatus(row.paymentStatus) },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 100, renderCell: ({ row }) => getStatus(row.status) }
    ];

    const fetchData = async () => {
        try {
            const url = APIUtils.getAPIEndpoint('myappointment-list');
            const res = await httpClient.get(url).then(res => res.data);
            if (res.status === 'success') {
                setRows(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (params) => {
        setSelectedRow(params.row);
        setNewApptDateTime(params.row.apptdatetime);
    };

    const handleClose = () => {
        setSelectedRow(null);
        setOpenRescheduleAppt(false);
    };

    const retryPayment = async () => {
        try {
            Swal.showLoading();
            const url = APIUtils.getAPIEndpoint('retry-payment');
            const payload = { id: selectedRow.id, paymentStatus: 'PAID' };
            const { data } = await httpClient.post(url, payload);
            if (data.status === 'success') {
                Swal.fire(data.message, '', 'success');
                handleClose();
                fetchData();
            } else {
                Swal.fire(data.message, '', 'error');
            }
        } catch (error) {
            Swal.fire('Something went wrong!', '', 'error');
        } finally {
            Swal.hideLoading();
        }
    };

    const cancelAppointment = async () => {
        if (await confirmBox('You want to cancel the appointment?')) {
            try {
                Swal.showLoading();
                const url = APIUtils.getAPIEndpoint('cancel-appointment');
                const { data } = await httpClient.post(url, { uniqueId: selectedRow.uniqueId });
                if (data.status === 'success') {
                    Swal.fire(data.message, '', 'success');
                    handleClose();
                    fetchData();
                } else {
                    Swal.fire(data.message, '', 'error');
                }
            } catch (error) {
                Swal.fire('Something went wrong!', '', 'error');
            } finally {
                Swal.hideLoading();
            }
        }
    };

    const updateAppointmentTime = async () => {
        const url = APIUtils.getAPIEndpoint('update-appointment-time');
        const res = await httpClient.post(url, { uniqueId: selectedRow.uniqueId, apptdatetime: newApptDateTime }).then(res => res.data);
        if (res.status === 'success') {
            Swal.fire('Status!', 'Appointment date & time updated successfully!', 'success');
            handleClose();
            fetchData();
        } else {
            Swal.fire('Something Went Wrong!', '', 'error');
        }
    };

    const handlePayment = async () => {
        // Implement payment logic here
        try {
            const payload = {
                amount: selectedRow.amount,
                description: 'Appointment Booking',
                productName: 'Doctor Consultation',
                email: selectedRow.email,
                contact: selectedRow.mobile,
                personName: selectedRow.name
            };
            const orderData = await httpClient.post(APIUtils.getAPIEndpoint('create-order'), payload);
            const res = orderData?.data;
            if (res?.success) {
                const options = {
                    key: res.keyId,
                    amount: res.amount,
                    currency: 'INR',
                    name: res.product_name,
                    description: res.description,
                    image: TreatuLogo,
                    'order_id': res.orderId,
                    prefill: {
                        contact: res.contact,
                        name: res.name,
                        email: res.email
                    },
                    notes: {
                        description: res.description
                    },
                    theme: {
                        color: '#2300a3'
                    },
                    handler: response => {
                        console.log(response, 'response');
                        // if (response.status_code === 200) {
                        Swal.fire('Payment Success!', '', 'success');
                        retryPayment();
                        // } else {
                        //     Swal.fire('Payment Failed!', '', 'error');
                        // }
                    }
                };
                const razorpayObject = new window.Razorpay(options);
                razorpayObject.open();
            }
        } catch (error) {
            console.log(error);
            Swal.fire('Something Went Wrong!', '', 'error');
        } finally {
            Swal.hideLoading();
        }
    };

    return (
        <Container component='main' maxWidth='lg' sx={{ mt: 3, mb: 3 }}>
            <Paper elevation={3} className={'mydata-grid'}>
                <Box>
                    <Typography variant='h5' className={'heading'} sx={{ textAlign: 'left' }}>
                        Appointment List
                    </Typography>
                </Box>
                <Divider />
                <Box>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        rows={rowsWithSlNo}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                showExport: false,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        onRowClick={handleRowClick}
                    />
                </Box>
            </Paper>

            {selectedRow && (
                <Dialog open={!!selectedRow} onClose={handleClose}>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>Appointment Details</div>
                        <IconButton onClick={handleClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom><strong>Order ID:</strong> {selectedRow.orderID}</Typography>
                        <Typography gutterBottom><strong>Doctor Name:</strong> {selectedRow.doctorName}</Typography>
                        <Typography gutterBottom><strong>Date & Time:</strong> {selectedRow.date}</Typography>
                        <Typography gutterBottom><strong>Address:</strong> {selectedRow.doctoravailibility?.address}</Typography>
                        <Typography gutterBottom><strong>Consultation Type:</strong> {selectedRow.cosulationType}</Typography>
                        <Typography gutterBottom><strong>Doctor Fees:</strong> {selectedRow.doctorFees}</Typography>
                        <Typography gutterBottom><strong>Payment Status:</strong> {selectedRow.paymentStatus}</Typography>
                        <Typography gutterBottom><strong>Status:</strong> {selectedRow.status}</Typography>
                        {/* Add more details here as needed */}
                    </DialogContent>
                    <DialogActions>
                        {['UNPAID', 'FAILED'].includes(selectedRow.paymentStatus) && (
                            <Button onClick={handlePayment} color="primary" variant="contained">
                                Pay Now
                            </Button>
                        )}
                        {!['COMPLETED', 'CANCELLED'].includes(selectedRow.status) && (
                            <Button onClick={cancelAppointment} color="error" variant="contained">
                                Cancel Appointment
                            </Button>
                        )}
                        {['CONFIRMED', 'PENDING'].includes(selectedRow.status) && (
                            <Button onClick={() => setOpenRescheduleAppt(true)} color="primary" variant="contained">
                                Reschedule
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}

            {openRescheduleAppt && (
                <Dialog open={openRescheduleAppt} maxWidth='xs' fullWidth>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>Reschedule Appointment</div>
                        <IconButton size='small' onClick={() => setOpenRescheduleAppt(false)}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className='profile-grid'>
                            <Grid item xs={12}>
                                <CustomDateTimePicker
                                    name={'apptdatetime'}
                                    value={dayjs(new Date(newApptDateTime))}
                                    handleChange={(value) => setNewApptDateTime(format(new Date(value), DATE_TIME_FORMAT))}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' color='secondary' onClick={() => setOpenRescheduleAppt(false)}>Cancel</Button>
                        <Button variant='contained' color='primary' onClick={() => updateAppointmentTime()}>Submit</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
}
