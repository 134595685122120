import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, OPEN_LOGIN_POPUP, OPEN_SIGNUP_POPUP, CLOSE_LOGIN_POPUP, CLOSE_SIGNUP_POPUP, LOGIN_COMPLETED } from '../types/authtypes';

const initialState = {
    loading: false,
    user: null,
    error: null,
    loginResult: null,
    signupResult: null,
    openSignup: false,
    openLogin: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP_REQUEST:
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                loginResult: null,
                signupResult: null,
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                signupResult: action.payload,
            };
        case LOGIN_COMPLETED:
            return {
                ...state,
                loading: false,
                loginResult: action.payload,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case SIGNUP_FAILURE:
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case LOGOUT:
            return {
                ...initialState
            };
        case OPEN_LOGIN_POPUP:
            return {
                ...state,
                openSignup: false,
                openLogin: true,
            };
        case CLOSE_LOGIN_POPUP:
            return {
                ...state,
                openSignup: false,
                openLogin: false,
            };
        case OPEN_SIGNUP_POPUP:
            return {
                ...state,
                openSignup: true,
                openLogin: false,
            };
        case CLOSE_SIGNUP_POPUP:
            return {
                ...state,
                openSignup: false,
                openLogin: false,
            };
        default:
            return state;
    }
};

export default authReducer;
