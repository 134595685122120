import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './navbar.css';
import {
    AppBar,
    Toolbar,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material';
import AdminToolBar from '../admin/navbardrawer/admintoolbar';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import NavDrawer from '../navdrawer/navdrawer';
import useAuth from '../../../customhooks/user/useauth';
import logo from '../../../assets/logos/treatu_logo.png';
import ProfileMenu from '../../profile';
import { openLoginPopup, deleteLoginState } from '../../../redux/actions/authActions';
import { useSelector } from 'react-redux';

const useStyles = styled((theme) => ({
    navlinks: {
        marginLeft: theme.spacing(5),
        display: 'flex',
    },
    logo: {
        flexGrow: '1',
        cursor: 'pointer',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '20px',
        marginLeft: theme.spacing(20),
        borderBottom: '1px solid transparent',
        '&:hover': {
            color: 'yellow',
            borderBottom: '1px solid white',
        },
    },
}));


function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const [pathName, setPathName] = useState('/');
    const [isAdmin, setAdmin] = useState(false);
    const auth = useAuth();
    const userState = useSelector(state => state.auth.user);

    const logout = () => {
        deleteLoginState();
        navigate('/');
        window.location.reload();
    };

    useEffect(() => {
        setPathName(location.pathname);
        if (location.pathname.includes('/admin')) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }, [location.pathname]);

    return (
        <>
            {isAdmin ? (
                <AdminToolBar logout={logout} userInfo={userState} />
            ) : (
                <AppBar position='static'>
                    <Container>
                        <Toolbar className='space-between no-padding'>
                            <div>
                                <img src={logo} className={classes.logo} alt='logo' />
                            </div>
                            {isMobile ? (
                                <NavDrawer
                                    userInfo={userState}
                                    logout={logout}
                                    handleLoginClick={() => openLoginPopup()}
                                />
                            ) : (
                                <div className={classes.navlinks}>
                                    <Link to='/' className={`${classes.link} link-main ${pathName === '/' && 'selected'}`}>
                                        Home
                                    </Link>
                                    <Link to='/finddoctors' className={`${classes.link} link-main ${(pathName === '/finddoctors' || pathName.includes('/finddoctors/')) && 'selected'}`}>
                                        Find Doctors
                                    </Link>
                                    <Link to='/medicine' className={`${classes.link} link-main ${pathName === '/medicine' && 'selected'}`}>
                                        Medicine
                                    </Link>
                                    <Link to='/labtest' className={`${classes.link} link-main ${pathName === '/labtest' && 'selected'}`}>
                                        Lab Test
                                    </Link>
                                    <Link to='/surgeries' className={`${classes.link} link-main ${pathName === '/surgeries' && 'selected'}`}>
                                        Surgeries
                                    </Link>
                                    {!auth && <Button variant='contained' className='btn-main' onClick={() => openLoginPopup()}>Login/Signup</Button>}
                                    {auth && <ProfileMenu userInfo={userState} logout={logout} />}
                                </div>
                            )}
                        </Toolbar>
                    </Container>
                </AppBar>
            )}
        </>
    );
}
export default Navbar;