import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { AddEditTest } from './addTestModal';
import { BulkUploadTestsModal } from './uploadTestsModal';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const testMasterCategories = [
    { value: '', label: '--Select Master Category--' },
    { value: '1', label: 'Pathology Test' },
    { value: '2', label: 'Xray / CT / MRI / Ultrasound' },
    { value: '3', label: 'Other Tests' }
];

export const testSubCategories = [
    { value: '', label: '--Select Category--' },
    { value: '1', label: 'Biochemistry' },
    { value: '2', label: 'Urea Test' },
    { value: '3', label: 'Lipid Test' },
    { value: '4', label: 'Other Tests' }
];

export default function TestMaster() {
    const [openAddService, setOpenAddService] = useState(false);
    const [editService, setEditService] = useState(false);
    const [editData, setEditdata] = useState({});
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [rows, setRows] = useState([]);
    const masterCategoryMap = testMasterCategories.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
    }, {});
    const subCategoryMap = testSubCategories.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
    }, {});

    const columns = [
        { field: 'id', headerName: 'Sl No', flex: 1 },
        { field: 'servicetype', headerName: 'Service Type', flex: 2, renderCell: ({ row }) => masterCategoryMap[row.servicetype] },
        { field: 'category', headerName: 'Category', flex: 1, renderCell: ({ row }) => subCategoryMap[row.category] },
        { field: 'servicename', headerName: 'Service Name', flex: 1 },
        { field: 'serviceprice', headerName: 'Service Price', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                return row.status === 'active'
                    ? <Chip label='Active' color='success' onClick={() => updateStatus(row)} />
                    : <Chip label='Pending' color='warning' onClick={() => updateStatus(row)} />;
            }
        },
        {
            field: 'adf',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <IconButton>
                        <EditOutlinedIcon onClick={() => {
                            setEditService(true);
                            setEditdata(row);
                            setOpenAddService('Edit Service');
                        }} />
                    </IconButton>
                    <IconButton onClick={() => deleteTest(row)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </>
            )
        },
    ];

    const deleteTest = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const url = APIUtils.getAdminAPIEndpoint('delete-test');
                await httpClient.post(url, { id: data.id });
                const updatedRows = rows.filter(item => item.id !== data.id);
                setRows(updatedRows);
                Swal.fire('Deleted!', 'Test has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
            }
        });
    };

    const updateStatus = (data) => {
        const newStatus = data.status === 'active' ? 'inactive' : 'active';
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await httpClient.post(APIUtils.getAdminAPIEndpoint('edittest'), { id: data.id, status: newStatus });
                const updatedRows = rows.map(item => {
                    if (item.id === data.id) {
                        return { ...item, status: newStatus };
                    }
                    return item;
                });
                setRows(updatedRows);
                const message = newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!';
                Swal.fire('Status!', message, 'success');
            }
        });
    };

    const bootstrapApplication = async () => {
        try {
            const res = await httpClient.get(APIUtils.getAdminAPIEndpoint('testlist')).then(res => res.data);
            if (res.status === 'success') {
                setRows(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        bootstrapApplication();
    }, []);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}>
                    <GridToolbarQuickFilter />
                </div>
                <div>
                    <Button size='small' variant='contained' sx={{ mr: 1 }} color='primary' onClick={() => setOpenBulkUpload('Other Test Excel Upload')}>Other Test Excel Upload</Button>
                    <Button size='small' variant='contained' sx={{ mr: 1 }} color='primary' onClick={() => setOpenBulkUpload('Radiology Excel Upload')}>Radiology Excel Upload</Button>
                    <Button size='small' variant='contained' sx={{ mr: 1 }} color='primary' onClick={() => setOpenBulkUpload('Pathology Excel Upload')}>Pathology Excel Upload</Button>
                    <Button size='small'
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setOpenAddService('Add Service');
                            setEditService(false);
                        }}
                    >Add Service</Button>
                </div>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            <div className='admin-area-2'>
                <Typography variant='h5'>Category Services List</Typography>
                <div className='page-content'>
                    <Paper>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            columns={columns}
                            slots={{ toolbar: CustomToolbar }}
                            rows={rows}
                            pageSize={25}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    showExport: false,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Paper>
                </div>
            </div>

            <AddEditTest
                open={openAddService}
                isEdit={editService}
                data={editData}
                setOpen={setOpenAddService}
                refresh={bootstrapApplication}
            />

            <BulkUploadTestsModal
                open={openBulkUpload}
                setOpen={setOpenBulkUpload}
            />

        </>
    );
}
