import store from '../store';
import * as profileTypes from '../types/profiletypes';
import httpClient from '../../services/http';
import { encryptStorage } from '../../utils/storage';
import { APIUtils } from '../../utils/apiutils';

const dispatch = store.dispatch;

export const uploadProfilePicdProfilePic = (payload) => {
    return httpClient.post(APIUtils.getAPIEndpoint('upload-profile-pic'), payload).then(res => res.data);
};

export const updateProfile = (payload) => {
    return httpClient.post(APIUtils.getAPIEndpoint('update-profile'), payload).then(res => res.data);
};

export const setProfile = async (payload) => {
    dispatch({ type: profileTypes.ON_GET_PROFILE_LOADED, payload: payload });
};

export const getProfile = async () => {
    return await httpClient.get(APIUtils.getAPIEndpoint('get-profile')).then((res) => {
        encryptStorage.setItem('user-info', res.data.data);
        dispatch({ type: profileTypes.ON_GET_PROFILE_LOADED, payload: res.data.data });
    }).catch(() => {
        dispatch({ type: profileTypes.ON_GET_PROFILE_FAILURE });
    });
};