import { applyMiddleware, compose, legacy_createStore as createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import categoryReducer from './reducers/categoryreducer';
import vendorReducer from './reducers/vendorreducer';
import profileReducer from './reducers/profilereducer';
import authReducer from './reducers/authReducer';
import adminReducer from './reducers/adminreducer';

const combinedReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    category: categoryReducer,
    vendor: vendorReducer,
    profile: profileReducer,
});

function getStore(reducers){
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware (thunk))
    );
    return store;
}

const store = getStore(combinedReducer);

export default store;