
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getDepartments = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('department')).then(res => res.data);
};

export const createDepartment = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('department'), payload).then(res => res.data);
};

export const updateDepartment = (id, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('department') + `/${id}`, payload).then(res => res.data);
};

export const deleteDepartment = (id) => {
    return httpClient.delete(APIUtils.getAdminAPIEndpoint('department') + `/${id}`).then(res => res.data);
};