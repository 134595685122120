
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import echo from '../../../assets/images/banner/echo.jpg';
import ctscan from '../../../assets/images/banner/ctscan.jpg';
import endoscopy from '../../../assets/images/banner/endoscopy.jpg';
import mri from '../../../assets/images/banner/mri.jpg';
import ultrasound from '../../../assets/images/banner/ultrasound.jpg';
import xray from '../../../assets/images/banner/xray.jpg';
import WithPrescription from './withprescription';
import './home.css';
import OurServices from './services';
import MeetOurDoctors from './ourdoctors';
import * as React from 'react';

function Home() {
	return (
		<div className='App'>
			<Carousel
				autoPlay={true}
				infiniteLoop={true}
				interval={2000}
				showArrows={true}
			>
				<div>
					<img src={echo} alt={echo} />
				</div>
				<div>
					<img src={ctscan} alt={ctscan} />
				</div>
				<div>
					<img src={endoscopy} alt={endoscopy} />
				</div>
				<div>
					<img src={mri} alt={mri} />
				</div>
				<div>
					<img src={ultrasound} alt={ultrasound} />
				</div>
				<div>
					<img src={xray} alt={xray} />
				</div>
			</Carousel>
			<OurServices />
			<WithPrescription/>
			<br/>
			<MeetOurDoctors />
		</div>
	);
}
export default Home;