import React from 'react';
import './admindashboard.css';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Customers from '../../../assets/images/customers.png';
import Vendors from '../../../assets/images/vendors.png';
import Executives from '../../../assets/images/executives.png';
import Order from '../../../assets/images/order.png';
import AcceptedOrder from '../../../assets/images/acceptedorder.png';



export default function AdminDashboard() {

    return (
        <div className='admin-area'>
            <Grid container spacing={3} sx={{ padding: '0px 24px' }}>
                <Grid item xs={12} sm={4}>
                    <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Customers</Typography>
                                    <Typography className='medium-orange-txt'>240</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Customers} alt='Customers' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Vendors</Typography>
                                    <Typography className='medium-orange-txt'>120</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Vendors} alt='Vendors' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Field Executives</Typography>
                                    <Typography className='medium-orange-txt'>3</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Executives} alt='Executives' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Orders</Typography>
                                    <Typography className='medium-orange-txt'>20</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Order} alt='Order' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Accepted Order</Typography>
                                    <Typography className='medium-orange-txt'>50</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={AcceptedOrder} alt='AcceptedOrder' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Closed Order</Typography>
                                    <Typography className='medium-orange-txt'>12</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Customers} alt='Customers' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card className='card-box'>
                        <CardContent className='card-box-content'>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box className='vertical-ruler'></Box>
                                <Box sx={{ margin: '0px 30px' }}>
                                    <Typography className='small-gray-txt'>Order Amount</Typography>
                                    <Typography className='medium-orange-txt'>12390</Typography>
                                </Box>
                            </Box>
                            <Box className='card-img-box'>
                                <img src={Customers} alt='Customers' />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
