import React, { useRef, useState, useEffect } from 'react';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	Divider,
	IconButton,
	MenuItem,
	Select,
	InputLabel
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { CustomDateTimePicker } from '../../../components/datetimepicker';
import { validateRequired } from '../../../utils/common';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';
import { DATE_TIME_FORMAT } from '../../../utils/constants';
import TreatuLogo from '../../../assets/logos/treatu_logo.png';

const defaultValue = { name: '', email: '', mobile: '', description: '', doctoravailibilityId: '' };
const requiredFields = ['name', 'mobile', 'doctoravailibilityId'];

export function BookAppointment({ open, setOpen, doctorDetails, userInfo, appointmentType }) {
	const [formData, setFormData] = useState(defaultValue);
	const [errors, setErrors] = useState({});
	const [amount, setAmount] = useState(0);
	const formSubmitted = useRef(false);

	useEffect(() => {
		setErrors({});
		formSubmitted.current = false;
		setFormData({
			doctorId: doctorDetails.userId,
			name: userInfo?.fullName || '',
			mobile: userInfo?.mobileNumber || '',
			email: userInfo?.email || '',
			apptdatetime: format(new Date(), DATE_TIME_FORMAT),
			appointmentType: appointmentType,
			amount: appointmentType === 1 ? doctorDetails.personalVisitPrice : doctorDetails.teleConsultationPrice,
			paymentStatus: 'PAID',
			description: '',
			doctoravailibilityId: ''
		});
		setAmount(appointmentType === 1 ? doctorDetails?.personalVisitPrice : doctorDetails?.teleConsultationPrice);
	}, [doctorDetails, userInfo, appointmentType]);

	const handleClose = () => {
		setOpen(false);
	};

	const setFormValue = (field, value) => {
		setFormData(prevFormData => {
			const updatedFormData = { ...prevFormData, [field]: value };
			if (formSubmitted.current) {
				setErrors(validateRequired(requiredFields, updatedFormData));
			}
			return updatedFormData;
		});
	};

	const bookAppointment = async () => {
		try {
			Swal.showLoading();
			const url = APIUtils.getAPIEndpoint('book-appointment');
			const res = await httpClient.post(url, formData);
			if (res.data.status === 'success') {
				Swal.fire(res.data.message, '', 'success');
				handleClose();
			} else {
				Swal.fire(res.data.message, '', 'error');
			}
		} catch (error) {
			Swal.fire('Something went wrong!', '', 'error');
		} finally {
			Swal.hideLoading();
		}
	};

	const handleSubmit = async () => {
		formSubmitted.current = true;
		const currentErrors = validateRequired(requiredFields, formData);
		if (Object.keys(currentErrors).length > 0) {
			setErrors(currentErrors);
			return;
		}

		try {
			const payload = {
				amount,
				description: 'Appointment Booking',
				productName: 'Doctor Consultation',
				email: formData.email,
				contact: formData.mobile,
				personName: formData.name
			};
			const orderData = await httpClient.post(APIUtils.getAPIEndpoint('create-order'), payload);
			const res = orderData?.data;
			if (res?.success) {
				const options = {
					key: res.keyId,
					amount: res.amount,
					currency: 'INR',
					name: res.product_name,
					description: res.description,
					image: TreatuLogo,
					'order_id': res.orderId,
					prefill: {
						contact: res.contact,
						name: res.name,
						email: res.email
					},
					notes: {
						description: res.description
					},
					theme: {
						color: '#2300a3'
					},
					handler: response => {
						console.log(response, 'response');
						// if (response.status_code === 200) {
						bookAppointment();
						// } else {
						// 	Swal.fire('Payment Failed!', '', 'error');
						// }
					}
				};
				const razorpayObject = new window.Razorpay(options);
				razorpayObject.open();
			}
		} catch (error) {
			Swal.fire('Something Went Wrong!', '', 'error');
		} finally {
			Swal.hideLoading();
		}
	};

	return (
		<Dialog open={open}>
			<DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ flex: 1 }}>Book Appointment: [{appointmentType === 1 ? 'Personal Visit' : 'Tele Consultation'}]</div>
				<IconButton onClick={handleClose} size='small'>
					<CloseOutlinedIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label='Name'
							variant='outlined'
							fullWidth
							size='small'
							sx={{ mt: 1 }}
							value={formData.name}
							onChange={e => setFormValue('name', e.target.value)}
							error={Boolean(errors.name)}
							helperText={errors.name && 'Please enter name'}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label='Mobile'
							variant='outlined'
							fullWidth
							size='small'
							value={formData.mobile}
							onChange={e => setFormValue('mobile', e.target.value)}
							error={Boolean(errors.mobile)}
							helperText={errors.mobile && 'Please enter mobile number'}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label='Email'
							variant='outlined'
							fullWidth
							size='small'
							value={formData.email}
							onChange={e => setFormValue('email', e.target.value)}
							error={Boolean(errors.email)}
							helperText={errors.email && 'Please enter your email'}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomDateTimePicker
							name='apptdatetime'
							value={dayjs(formData.apptdatetime)}
							handleChange={value => setFormValue('apptdatetime', format(new Date(value), DATE_TIME_FORMAT))}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth size='small' error={!!errors.doctoravailibilityId}>
							<InputLabel>Select Address</InputLabel>
							<Select
								value={formData?.doctoravailibilityId}
								label='Select Address'
								onChange={e => setFormValue('doctoravailibilityId', e.target.value)}
							>
								{doctorDetails?.availibilities?.map(item => {
									return <MenuItem key={'avail-' + item.id} value={item.id}>{item.address}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label='Description'
							multiline
							rows={4}
							value={formData.description}
							onChange={e => setFormValue('description', e.target.value)}
							fullWidth
							size='small'
							error={Boolean(errors.description)}
							helperText={errors.description && 'Please enter description'}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<Divider />
			<DialogActions>
				<FormControl>
					<Button size='small' variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
				</FormControl>
				<FormControl>
					<Button size='small' variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
				</FormControl>
			</DialogActions>
		</Dialog>
	);
}

BookAppointment.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	doctorDetails: PropTypes.object,
	userInfo: PropTypes.object,
	appointmentType: PropTypes.number.isRequired,
};
