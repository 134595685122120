import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import './footer.css';
import logo from '../../assets/logos/treatu_logo_light.png';
import twitter from '../../assets/logos/Twitter.png';
import facebook from '../../assets/logos/Facebook.png';
import instagram from '../../assets/logos/Instagram.png';
import linkedin from '../../assets/logos/Linkedin.png';
import google from '../../assets/logos/Google.png';

function Footer() {
    const [isAdmin, setAdmin] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const pName = location.pathname;
        if (pName.includes('/admin')) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }, [location.pathname]);

    return (
        <>
            {
                isAdmin ? <></> :
                    <Box className='footer-content'>
                        <Container>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={2}>
                                        <img src={logo} className='footer-logo' alt='logo' />
                                        <Typography className='small-brown-txt'>
                                            The ultimate destination for all of your medical needs
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Typography className='small-brown-txt'>
                                                Follow Us
                                            </Typography>
                                            <Stack direction='row' spacing={2}>
                                                <Avatar sx={{ width: 23, height: 23 }} alt='Twitter' src={twitter} variant='square' />
                                                <Avatar sx={{ width: 23, height: 23 }} alt='Instagram' src={instagram} variant='square' />
                                                <Avatar sx={{ width: 13, height: 23 }} alt='Facebook' src={facebook} variant='square' />
                                                <Avatar sx={{ width: 23, height: 23 }} alt='Google' src={google} variant='square' />
                                                <Avatar sx={{ width: 23, height: 23 }} alt='Linkedin' src={linkedin} variant='square' />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={2}>
                                        <Typography className='medium-yellow-txt'>
                                            Explore
                                        </Typography>
                                        <a href='#' className='small-brown-txt'>
                                            Privacy Policy
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            Refund Policy
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            Legal Document
                                        </a>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={2}>
                                        <Typography className='medium-yellow-txt'>
                                            About Us
                                        </Typography>
                                        <a href='#' className='small-brown-txt'>
                                            Services
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            About Us
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            Enquiry
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            Terms & Conditions
                                        </a>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Stack spacing={2}>
                                        <Typography className='medium-yellow-txt'>
                                            Contact
                                        </Typography>
                                        <a href='#' className='small-brown-txt'>
                                            +91 9938042225
                                        </a>
                                        <a href='#' className='small-brown-txt'>
                                            support@treatu.in
                                        </a>
                                        <Typography className='small-brown-txt'>
                                            Flat-1, Naivedya, Plot-17,<br /> Soubhagya Nagar,Bhubaneswar,<br /> Odisha, India,751003
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
            }
        </>
    );
}

export default Footer;