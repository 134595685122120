
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getServiceTypes = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('servicetype-list'))
        .then(res => res.data)
        .then(res => res.data.map((item, index) => { return { ...item, index: index + 1 }; }));
};

export const createServiceType = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('create-servicetype'), payload).then(res => res.data);
};

export const updateServiceType = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('update-servicetype'), payload).then(res => res.data);
};

export const deleteServiceType = (id) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('delete-servicetype'), { id }).then(res => res.data);
};