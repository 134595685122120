import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import styled from '@emotion/styled';
import useAuth from '../../../customhooks/user/useauth';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';

const useStyles = styled(() => ({
  root: {
    //Your styling rules
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
    fontSize: '20px',
  },
  icon: {
    color: 'white'
  }
}));

function NavDrawer(props) {
  const { userInfo, logout, handleLoginClick } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const [pathName, setPathName] = useState('/');
  const [fullName, setFullName] = useState('');
  const auth = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    setOpenDrawer(false);
    logout();
  };

  const openLoginPopup = (e) => {
    e.preventDefault();
    handleLoginClick();
  };

  useEffect(() => {
    setOpenDrawer(false);
    setPathName(location.pathname);
    if (userInfo) {
      const fullName = [userInfo?.firstName, userInfo?.middleName, userInfo?.lastName].filter(e => e).join(' ');
      setFullName(fullName);
    }
  }, [location.pathname, userInfo]);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to='/' className={`${classes.link} link-main  ${pathName === '/' && 'selected'}`}>Home</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to='/finddoctors' className={`${classes.link} link-main ${pathName === '/finddoctors' && 'selected'}`}>Find Doctors</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to='/medicine' className={`${classes.link} link-main ${pathName === '/medicine' && 'selected'}`}>Medicine</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to='/labtest' className={`${classes.link} link-main ${pathName === '/medicine' && 'selected'}`}>Lab Test</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to='/surgeries' className={`${classes.link} link-main ${pathName === '/medicine' && 'selected'}`}>Surgeries</Link>
            </ListItemText>
          </ListItem>
          {
            !auth ? <ListItem>
              <ListItemText>
                <Link to='/login' className={`${classes.link} link-main ${pathName === '/login' && 'selected'}`} onClick={openLoginPopup}>
                  Login/Signup
                </Link>
              </ListItemText>
            </ListItem> :
              <>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/myprofile' className={`${classes.link} link-main ${pathName === '/myprofile' && 'selected'}`}>Profile<span style={{ fontSize: '11px' }}>{' (' + fullName + ') '}</span></Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/myaddress' className={`${classes.link} link-main ${pathName === '/myaddress' && 'selected'}`}>My Address</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/mycart' className={`${classes.link} link-main ${pathName === '/mycart' && 'selected'}`}>My Cart</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/myappointment' className={`${classes.link} link-main ${pathName === '/myappointment' && 'selected'}`}>My Appointment</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/myrequest' className={`${classes.link} link-main ${pathName === '/myrequest' && 'selected'}`}>My Request</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to='/change-password' className={`${classes.link} link-main ${pathName === '/myrequest' && 'selected'}`}>Change Password</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Link to='/' className={`${classes.link} link-main ${pathName === '/' && 'selected'}`} onClick={handleLogout}>Logout</Link>
                  </ListItemText>
                </ListItem>
              </>
          }
          <Divider />
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

NavDrawer.propTypes = {
  logout: PropTypes.func.isRequired,
  handleLoginClick: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired
};

export default NavDrawer;