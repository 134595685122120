import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import './medicinedetails.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { productDetails } from './constants';

function MedicineDetails() {
	return (
		<Container>
			<Grid
				container
				spacing={2}
				sx={{
					marginTop: '20px',
					display: 'flex',
				}}
			>
				<Grid
					container
					sx={{
						marginTop: '20px',
						display: 'flex',
						gap: '40px',
						alignItems: 'center',
						justifyContent: 'center',
						flexWrap: 'nowrap',
					}}
					columns={{ xs: 1, sm: 12, md: 12 }}
				>
					<Grid item>
						<Card
							sx={{
								width: 348,
								height: 384,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CardMedia
								component='img'
								height='250'
								image={productDetails.image}
								sx={{
									backgroundSize: 'contain',
									objectFit: 'contain',
								}}
							/>
						</Card>
					</Grid>
					<Grid item>
						<Card
							sx={{
								maxWidth: '543px',
								height: 384,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'flex-start',
								textAlign: 'start',
								boxShadow: 'none',
							}}
						>
							<CardContent
								style={{ padding: 4 }}
								sx={{ boxSizing: 'border-box' }}
							>
								<Typography gutterBottom variant='h5'>
									{productDetails.name}
								</Typography>
								<Typography variant='body2' color='text.secondary'>
									{productDetails.description}
								</Typography>
							</CardContent>
							<CardActions
								sx={{
									display: 'flex',
								}}
							>
								<Button size='small' variant='contained' className='btn-square'>
									<Typography variant='body2' color='text.secondary'>
										Add to Cart
									</Typography>
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						textAlign: 'start',
						gap: '20px',
						margin: '20px 0px',
					}}
				>
					<Grid
						key='benifits'
						sx={{
							width: '100%',
						}}
					>
						<div className='heading-section'>
							<Typography className='heading-section-font'>
								Benifits :
							</Typography>
						</div>
						<Typography className='details-font'>
							{productDetails.benefits}
						</Typography>
					</Grid>
					<Grid
						key='how_to_use'
						sx={{
							width: '100%',
						}}
					>
						<div className='heading-section'>
							<Typography className='heading-section-font'>
								How to use :
							</Typography>
						</div>
						<Typography className='details-font'>
							{productDetails.usage}
						</Typography>
					</Grid>
					<Grid
						key='safety_advise'
						sx={{
							width: '100%',
						}}
					>
						<div className='heading-section'>
							<Typography className='heading-section-font'>
								Safety Advice :
							</Typography>
						</div>
						<Typography className='details-font'>
							{productDetails.safetyAdvice}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}
export default MedicineDetails;
