import React, { useState } from 'react';
import './fieldExecutive.css';
import Swal from 'sweetalert2';
import { Grid, Typography, IconButton, Divider, TextField, Paper, Button, Box } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CgSpinner } from 'react-icons/cg';
import { createFieldExecutive, updateFieldExecutive } from './service';
import { objectToFormData } from '../../../utils/utils';
import PropTypes from 'prop-types';

function AddFieldEcecutive(props) {
    const { data, isEdit, closeAdding, refresh } = props;
    const [certificateName, setCertificateName] = useState('');

    const initialValues = {
        firstName: data?.firstName || '',
        middleName: data?.middleName || '',
        lastName: data?.lastName || '',
        mobileNumber: data?.mobileNumber || '',
        profilePic: data?.profilePic || '',
        aadharNumber: data?.aadharNumber || '',
        email: data?.email || '',
        gender: data?.gender || '',
        age: data?.age || '',
        district: data?.district || '',
        city: data?.city || '',
        certificate: data?.certificate || '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name Required'),
        lastName: Yup.string().required('Last Name Required'),
        email: Yup.string().required('Email is required').email('Invalid email address'),
        mobileNumber: Yup.string().required('Mobile Number Required'),
        password: isEdit ? Yup.string().optional() : Yup.string().required('Password is Required'),
    });

    const handleSubmit = async (values) => {
        try {
            let res;
            const formData = objectToFormData(values);
            if (isEdit) {
                formData.append('userId', data.user.id);
                res = await updateFieldExecutive(formData);
            } else {
                res = await createFieldExecutive(formData);
            }
            if (res?.status === 'success') {
                Swal.fire(res?.message, '', 'success');
                closeAdding();
                refresh();
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    const browseCertificate = (e, form, field) => {
        setCertificateName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    return (
        <div className='admin-area-2'>
            <div className='page-header'>
                <IconButton onClick={() => closeAdding(false)}><ArrowBackIcon /></IconButton>
                <Typography variant='h5'>{isEdit ? 'Edit' : 'Add'} Field Executive</Typography>
            </div>
            <div className='page-content'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Paper>
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='firstName'
                                                id='firstName'
                                                label='First Name'
                                                placeholder='First Name'
                                                helperText={<ErrorMessage name='firstName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='middleName'
                                                id='middleName'
                                                label='Middle Name'
                                                placeholder='Middle Name'
                                                helperText={<ErrorMessage name='middleName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='lastName'
                                                id='lastName'
                                                label='Last Name'
                                                placeholder='Last Name'
                                                helperText={<ErrorMessage name='lastName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <div className='styled-dropdown'>
                                                <Field
                                                    as='select'
                                                    id='gender'
                                                    name='gender'>
                                                    <option value=''>Select Gender</option>
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                </Field>
                                                <ErrorMessage name='gender' />
                                            </div>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                id='mobile'
                                                name='mobileNumber'
                                                label='Mobile Number'
                                                placeholder='Mobile number'
                                                helperText={<ErrorMessage name='mobileNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='aadharNumber'
                                                id='aadharNumber'
                                                label='Aadharcard No.'
                                                placeholder='Aadharcard No.'
                                                helperText={<ErrorMessage name='aadharNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                disabled={isEdit}
                                                size='small'
                                                name='email'
                                                label='Email'
                                                id='email'
                                                placeholder='Email address'
                                                helperText={<ErrorMessage name='email' />}
                                            />
                                        </Grid>
                                        {!isEdit &&
                                            <Grid item md='6' sm='6' xs='12'>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    size='small'
                                                    name='password'
                                                    type='password'
                                                    label='Password'
                                                    placeholder='********'
                                                    helperText={<ErrorMessage name='password' />}
                                                />
                                            </Grid>
                                        }
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='district'
                                                id='district'
                                                label='District'
                                                placeholder='District'
                                                helperText={<ErrorMessage name='district' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='city'
                                                id='city'
                                                label='City'
                                                placeholder='City'
                                                helperText={<ErrorMessage name='city' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                required
                                                placeholder='Age'
                                                name='age'
                                                label='Age'
                                                type='number'
                                                id='age'
                                                helperText={<ErrorMessage name='age' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Certificate:</Typography>
                                            <Field name='certificateNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='application/pdf'
                                                                name={field.name}
                                                                hidden
                                                                onChange={(e) => browseCertificate(e, form, field)}
                                                            />
                                                            Chose File
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{certificateName ? certificateName : 'No file selected'}</Typography>
                                            {data?.certificate && <div><a href={data?.certificate}>{data?.certificate.split('/').pop()}</a></div>}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 1 }} onClick={() => closeAdding(false)}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                                        {isSubmitting && <CgSpinner size={20} className='mt-1 animate-spin' />} Save
                                    </Button>
                                </Box>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

AddFieldEcecutive.propTypes = {
    data: PropTypes.object.isRequired,
    isEdit: PropTypes.bool.isRequired,
    closeAdding: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired
};

export default AddFieldEcecutive;