import { USER_ROLES } from './constants';
// Component imports
import AdminDashboard from '../pages/admin/admindashboard/admindashboard';
import ListDoctor from '../pages/admin/doctor';
// import AdminLogin from '../pages/admin/adminlogin/adminlogin';
// import Category from '../pages/admin/category/category';
// import VendorType from '../pages/admin/vendorType/vendorType';
import Customers from '../pages/admin/customers/customers';
import FieldExecutive from '../pages/admin/fieldExecutive';
import Vendors from '../pages/admin/vendors';
import MedicineMaster from '../pages/admin/medicineMaster';
import TestMaster from '../pages/admin/testMaster';
import Appointments from '../pages/admin/Services/appointments';
import DepartmentList from '../pages/admin/department';
import QualificationList from '../pages/admin/qualification';
import ServiceTypeList from '../pages/admin/servicetype';
import MedicineCategoryList from '../pages/admin/medicinecategory';
// import PathologyTest from '../pages/admin/Services/pathologyTest';
// import XRayEtc from '../pages/admin/Services/xRayEtc';
// import OtherTests from '../pages/admin/Services/otherTests';
// import MedicineOnline from '../pages/admin/Services/medicineOnline';
// import BookDialysis from '../pages/admin/Services/bookDialysis';
// import TreatAtHome from '../pages/admin/Services/treatAtHome';
// import BookHealthCheckup from '../pages/admin/Services/bookHealthCheckup';
// import BookAdvancedSurgery from '../pages/admin/Services/bookAdvancedSurgery';
// import EmergencyAssistance from '../pages/admin/Services/emergencyAssistance';
// import ServiceOrders from '../pages/admin/serviceOrder';

// Icon imports
// import LayersIcon from '@mui/icons-material/Layers';
import CategoryIcon from '@mui/icons-material/Category';
import DepartmentIcon from '@mui/icons-material/MedicalServices';
import QualificationIcon from '@mui/icons-material/School';
import SettingsIcon from '@mui/icons-material/Settings';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import Face5Icon from '@mui/icons-material/Face5';
import HailIcon from '@mui/icons-material/Hail';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
// import BiotechIcon from '@mui/icons-material/Biotech';
// import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
// import AltRouteIcon from '@mui/icons-material/AltRoute';
// import BloodtypeIcon from '@mui/icons-material/Bloodtype';
// import VaccinesIcon from '@mui/icons-material/Vaccines';
// import HomeWorkIcon from '@mui/icons-material/HomeWork';
// import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
// import MasksIcon from '@mui/icons-material/Masks';
// import EmergencyShareOutlinedIcon from '@mui/icons-material/EmergencyShareOutlined';
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';

const menuItems = [
    {
        path: '/admin',
        text: 'Dashboard',
        icon: DashboardOutlinedIcon,
        component: AdminDashboard,
        allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR, USER_ROLES.DOCTOR]
    },
    {
        text: 'Master',
        icon: SettingsIcon,
        children: [
            // {
            //     path: '/admin/category',
            //     text: 'Category',
            //     icon: DepartmentIcon,
            //     component: Category,
            //     allowUser: [USER_ROLES.SUPER_ADMIN]
            // }, {
            //     path: '/admin/vendor-type',
            //     text: 'Vendor Type',
            //     icon: TypeSpecimenIcon,
            //     component: VendorType,
            //     allowUser: [USER_ROLES.SUPER_ADMIN]
            // },
            {
                path: '/admin/department',
                text: 'Departments',
                icon: DepartmentIcon,
                component: DepartmentList,
                allowUser: [USER_ROLES.SUPER_ADMIN]
            },
            {
                path: '/admin/qualification',
                text: 'Qualifications',
                icon: QualificationIcon,
                component: QualificationList,
                allowUser: [USER_ROLES.SUPER_ADMIN]
            },
            {
                path: '/admin/servicetype',
                text: 'Service Types',
                icon: CategoryIcon,
                component: ServiceTypeList,
                allowUser: [USER_ROLES.SUPER_ADMIN]
            },
            {
                path: '/admin/medicinecategory',
                text: 'Medicine Categories',
                icon: LocalPharmacyOutlinedIcon,
                component: MedicineCategoryList,
                allowUser: [USER_ROLES.SUPER_ADMIN]
            }
        ],
        allowUser: [USER_ROLES.SUPER_ADMIN]
    },
    {
        text: 'Users',
        icon: AccountCircleOutlinedIcon,
        children: [
            {
                path: '/admin/customers',
                text: 'Customers',
                icon: PeopleOutlinedIcon,
                component: Customers,
                allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
            },
            {
                path: '/admin/doctors',
                text: 'Doctors',
                icon: MedicationOutlinedIcon,
                component: ListDoctor,
                allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
            },
            {
                path: '/admin/vendors',
                text: 'Vendors',
                icon: HailIcon,
                component: Vendors,
                allowUser: [USER_ROLES.SUPER_ADMIN]
            },
            {
                path: '/admin/fieldExecutive',
                text: 'Field Executive',
                icon: Face5Icon,
                component: FieldExecutive,
                allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
            }
        ],
        allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
    },
    {
        path: '/admin/appointments',
        text: 'Appointments',
        icon: ContactPhoneIcon,
        component: Appointments,
        allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.DOCTOR]
    },
    {
        path: '/admin/medicine-master',
        text: 'Medicine Master',
        icon: LocalPharmacyOutlinedIcon,
        component: MedicineMaster,
        allowUser: [USER_ROLES.SUPER_ADMIN]
    },
    {
        path: '/admin/test-master',
        text: 'Test Master',
        icon: ScienceOutlinedIcon,
        component: TestMaster,
        allowUser: [USER_ROLES.SUPER_ADMIN]
    },
    // {
    //     text: 'Services',
    //     icon: MedicalInformationOutlinedIcon,
    //     children: [{
    //         path: '/admin/consult-doctor',
    //         text: 'Consult a doctor',
    //         icon: ContactPhoneIcon,
    //         component: ConsultDoctor,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/pathology-test',
    //         text: 'Pathology Test',
    //         icon: BiotechIcon,
    //         component: PathologyTest,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/x-ray-etc',
    //         text: 'Xray / CT / MRI / Ultrasound',
    //         icon: FilterBAndWIcon,
    //         component: XRayEtc,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/other-tests',
    //         text: 'Other Tests ',
    //         icon: AltRouteIcon,
    //         component: OtherTests,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/medicine-online',
    //         text: 'Medicines Online ',
    //         icon: BloodtypeIcon,
    //         component: MedicineOnline,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/book-dialysis',
    //         text: 'Book Dialysis ',
    //         icon: VaccinesIcon,
    //         component: BookDialysis,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/treat-at-home',
    //         text: 'Treat at Home ',
    //         icon: HomeWorkIcon,
    //         component: TreatAtHome,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/book-health-checkup',
    //         text: 'Book Health Checkup ',
    //         icon: CollectionsBookmarkIcon,
    //         component: BookHealthCheckup,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/book-advance-surgery',
    //         text: 'Book For Advanced Surgery ',
    //         icon: MasksIcon,
    //         component: BookAdvancedSurgery,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }, {
    //         path: '/admin/emergency-assistance',
    //         text: 'Emergency Assistance ',
    //         icon: EmergencyShareOutlinedIcon,
    //         component: EmergencyAssistance,
    //         allowUser: [USER_ROLES.SUPER_ADMIN]
    //     }],
    //     allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
    // },
    // {
    //     path: '/admin/service-orders',
    //     text: 'Service Orders',
    //     icon: AccountBalanceWalletIcon,
    //     component: ServiceOrders,
    //     allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
    // },
    // {
    //     path: '/admin/medicinemaster',
    //     text: 'Medicine Master',
    //     allowUser: [USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR]
    // },
    // {
    //     path: '/admin/services',
    //     text: 'Services',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/testdescriptionrequests',
    //     text: 'Test Prescription Requests',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/ordercancelrequests',
    //     text: 'Order Cancel Requests',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/newblogs',
    //     text: 'New/Blogs',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/medicalguidance',
    //     text: 'Medical Guidance',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/settlement',
    //     text: 'Settlement',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/categoriesoffer',
    //     text: 'Categories Offer',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/notification',
    //     text: 'Notification',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/managejob',
    //     text: 'Manage Job',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/enquirylist',
    //     text: 'Enquiry List',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // },
    // {
    //     path: '/admin/logactivity',
    //     text: 'Log Activity',
    //     allowUser: [USER_ROLES.SUPER_ADMIN]
    // }
];

function getFlatComponent() {
    let temp = [];
    for (let path of menuItems) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(10).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();

export default menuItems;