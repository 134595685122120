import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const PhoneInputField = ({ form, field, ...otherProps }) => {
    return (
        <>
            <PhoneInput
                {...field}
                {...otherProps}
                inputProps={{ name: field.name }}
                country={'in'}
                onChange={(value) => form.setFieldValue(field.name, value)}
            />
        </>
    );
};

PhoneInputField.propTypes = {
    form: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    otherProps: PropTypes.any, // otherProp is optional
};

export default PhoneInputField;
