
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getQualifications = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('qualification'))
        .then(res => res.data)
        .then(res => res.data.map((item, index) => { return { ...item, index: index + 1 }; }));
};

export const createQualification = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('qualification'), payload).then(res => res.data);
};

export const updateQualification = (id, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('qualification') + `/${id}`, payload).then(res => res.data);
};

export const deleteQualification = (id) => {
    return httpClient.delete(APIUtils.getAdminAPIEndpoint('qualification') + `/${id}`).then(res => res.data);
};