
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getMedicines = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('medicine')).then(res => res.data);
};

export const createMedicine = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('medicine'), payload).then(res => res.data);
};

export const updateMedicine = (id, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('medicine') + `/${id}`, payload).then(res => res.data);
};

export const deleteMedicine = (id) => {
    return httpClient.delete(APIUtils.getAdminAPIEndpoint('medicine') + `/${id}`).then(res => res.data);
};

export const uploadMedicine = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('uploadmedicine'), payload).then(res => res.data);
};