import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Swal from 'sweetalert2';
import { Paper, Container, Grid, TextField, Divider, Button, Box, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import httpClient from '../../../services/http';
import { passwordRegex, invalidPwdMsg } from '../../../utils/constants';
import { APIUtils } from '../../../utils/apiutils';

export default function ChangePassword() {
    const navigate = useNavigate();
    const initialValues = {
        currPassword: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
        currPassword: Yup.string()
            .required('Please enter current password'),
        password: Yup.string()
            .required('Please enter password')
            .matches(passwordRegex, invalidPwdMsg),
        confirmPassword: Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password')], 'Password not matched')
    });


    const handleSubmit = async (values) => {
        try {
            let res = await httpClient.post(APIUtils.getAPIEndpoint('change-password'), values);
            if (res?.data?.status === 'success') {
                Swal.fire(res?.data?.message, '', 'success');
                navigate('/myprofile');
            } else {
                Swal.fire(res?.data?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    return (
        <Container component='main' maxWidth='lg' sx={{ mt: 3, mb: 3 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <Paper elevation={3} className={'passwordContainer'}>
                            <Box>
                                <Typography variant='h5' className={'padding-20'} sx={{ textAlign: 'left' }}>
                                    Change Password
                                </Typography>
                            </Box>
                            <Divider />
                            <Grid container className={'change-password-grid'}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>Current Password*</Typography>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        size='small'
                                        required
                                        name='currPassword'
                                        type='password'
                                        label='Current Password'
                                        placeholder='Enter current password'
                                        helperText={<ErrorMessage name='currPassword' />}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>Password*</Typography>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        size='small'
                                        required
                                        name='password'
                                        type='password'
                                        label='Password'
                                        placeholder='Enter new  password'
                                        helperText={<ErrorMessage name='password' />}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle1' sx={{ textAlign: 'left' }}>Confirm Password*</Typography>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        size='small'
                                        required
                                        name='confirmPassword'
                                        type='password'
                                        label='Confirm Password'
                                        placeholder='Enter Confirm Password'
                                        helperText={<ErrorMessage name='confirmPassword' />}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Box className={'padding-20'} sx={{ textAlign: 'right' }}>
                                <Button type='reset' variant='outlined' color='secondary' sx={{ mr: 1, width: 135 }} >Reset</Button>
                                <Button type='submit' variant='contained' sx={{ width: 135 }} fullWidth className='btn-square'>Submit</Button>
                            </Box>
                        </Paper>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}