import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

export function BulkUploadTestsModal(props) {
    const { open, setOpen } = props;
    const fileRef = useRef(null);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        setFileName('');
    }, [open]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
    };

    return (
        <Dialog open={open} maxWidth='sm' fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    Bulk Data Upload ({open})
                </div>
                <IconButton onClick={handleClose} size='small'>
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <input type='file' hidden ref={fileRef} onChange={handleFileChange} accept='image/png, image/jpeg' />
                <Box
                    className='fileUploadArea'
                    onClick={() => fileRef.current.click()}
                >
                    <Button color='secondary'>
                        <FileUploadOutlinedIcon />
                    </Button>
                    <Typography>{fileName || 'Choose File'}</Typography>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <FormControl>
                    <Button size='small' variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
                </FormControl>
                <FormControl >
                    <Button size='small' variant='contained' color='primary' onClick={handleClose}>Save</Button>
                </FormControl>
            </DialogActions>
        </Dialog>
    );
}

BulkUploadTestsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};