import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import InsertCommentIcon from '@mui/icons-material/InsertComment';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import { USER_ROLES } from '../../utils/constants';

const styles = {
    profileMenuBefore: {
        // eslint-disable-next-line
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
    MuiAvatarRoot: {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    }
};

function ProfileMenu({ userInfo, logout }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [isAdmin, setAdmin] = useState(false);

    useEffect(() => {
        if (userInfo) {
            const { firstName, middleName, lastName } = userInfo;
            const nameParts = [firstName, middleName, lastName].filter(Boolean); // Filter out undefined or null values
            setFullName(nameParts.join(' '));
            setAdmin([USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR, USER_ROLES.DOCTOR].includes(userInfo.role));
        }
    }, [userInfo]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const adminMenuItems = [
        {
            text: 'Profile', icon: <PersonIcon fontSize='small' />, onClick: () => {
                if (userInfo?.role === USER_ROLES.DOCTOR) {
                    navigate(`admin/doctors/edit/${userInfo?.userId}`);
                } else {
                    navigate('/admin/profile');
                }
            }
        },
        { text: 'Change Password', icon: <LockIcon fontSize='small' />, onClick: () => navigate('/admin/change-password') },
    ];

    const userMenuItems = [
        { text: 'Profile', icon: <PersonIcon fontSize='small' />, onClick: () => navigate('/myprofile') },
        { text: 'My Address', icon: <LocationOnIcon fontSize='small' />, onClick: () => navigate('/myaddress') },
        // { text: 'My Cart', icon: <ShoppingCartIcon fontSize='small' /> },
        { text: 'My Appointments', icon: <CalendarMonthIcon fontSize='small' />, onClick: () => navigate('/myappointments') },
        { text: 'Change Password', icon: <LockIcon fontSize='small' />, onClick: () => navigate('/change-password') },
    ];

    return (
        <>
            <Tooltip title="Open profile menu">
                <IconButton variant='contained' onClick={handleClick}>
                    <PersonIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                slotProps={{
                    paper: {
                        className: 'profile-menu-paper',
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': styles.MuiAvatarRoot,
                            '&:before': styles.profileMenuBefore,
                        },
                    }
                }}
            >
                <MenuItem onClick={handleClose}>
                    {fullName}
                </MenuItem>
                <Divider />
                {(isAdmin ? adminMenuItems : userMenuItems).map((item, index) => (
                    <MenuItem key={index} onClick={item.onClick}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {item.text}
                    </MenuItem>
                ))}
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize='small' />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}

ProfileMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    userInfo: PropTypes.shape({
        userId: PropTypes.number,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.string,
    }),
};

export default ProfileMenu;
