import * as profileTypes from '../types/profiletypes';

const initialState = {
    result: null,
    loading: false
};

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case profileTypes.ON_GET_PROFILE_LOADING:
            return {
                ...state,
                loading: true
            };
        case profileTypes.ON_GET_PROFILE_LOADED:
            return {
                ...state,
                result: action.payload
            };
        case profileTypes.ON_GET_PROFILE_FAILURE:
            return {
                ...state,
                result: null
            };
        default:
            return state;
    }
}