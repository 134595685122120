export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const OPEN_LOGIN_POPUP = 'OPEN_LOGIN_POPUP';
export const CLOSE_LOGIN_POPUP = 'CLOSE_LOGIN_POPUP';
export const OPEN_SIGNUP_POPUP = 'OPEN_SIGNUP_POPUP';
export const CLOSE_SIGNUP_POPUP = 'CLOSE_SIGNUP_POPUP';