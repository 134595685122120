
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getMedicineCategories = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('medicinecategory'))
        .then(res => res.data)
        .then(res => res.data.map((item, index) => { return { ...item, index: index + 1 }; }));
};

export const createMedicineCategory = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('medicinecategory'), payload).then(res => res.data);
};

export const updateMedicineCategory = (id, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('medicinecategory') + '/' + id, payload).then(res => res.data);
};

export const deleteMedicineCategory = (id) => {
    return httpClient.delete(APIUtils.getAdminAPIEndpoint('medicinecategory') + '/' + id).then(res => res.data);
};