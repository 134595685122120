import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { encryptStorage } from '../../../utils/storage';
import { login } from '../../../redux/actions/authActions';
import logo from '../../../assets/logos/treatu_logo.png';
import './adminlogin.css';
import useAuth from '../../../customhooks/admin/useauth';

export default function AdminLogin() {

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('Enter valid email').required('Required'),
		password: Yup.string().required('Required'),
	});

	const loading = useSelector(state => state.login.loading);
	const result = useSelector(state => state.login.loginResult);

	const navigate = useNavigate();
	const isAdmin = useAuth();

	const handleSubmit = (values) => {
		values.loginType = 'admin';
		login(values);
	};

	useEffect(() => {
		if (isAdmin) {
			navigate('/admin');
			return;
		}
		if (result) {
			if (result?.status === 'success') {
				encryptStorage.setItem('admin-info', result?.data);
				encryptStorage.setItem('user-token', result?.data?.token);
				navigate('/admin');
			} else if (result?.status === 'failure') {
				Swal.fire(result?.message, '', 'error');
			}
		}
	}, [result, isAdmin, navigate]);

	return (
		<Container component='main' className='admin-login' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 2,
					marginBottom: 6,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Box>
					<img src={logo} alt='Logo' />
				</Box>
				<Grid container justifyContent='flex-start'>
					<Typography component='h1' variant='h5'>
						Login
					</Typography>
				</Grid>
				<Box sx={{ mt: 3 }}>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
					>
						{() => (
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Field
											margin='normal'
											as={TextField}
											id='email'
											label='Email'
											name='email'
											fullWidth
											required
											autoFocus
											size='small'
											helperText={<ErrorMessage name='email' />}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											margin='normal'
											as={TextField}
											name='password'
											label='Password'
											type='password'
											id='password'
											fullWidth
											required
											size='small'
											helperText={<ErrorMessage name='password' />}
										/>
									</Grid>
									<Button
										type='submit'
										fullWidth
										variant='contained'
										sx={{ mt: 3, mb: 2, ml: 2 }}
										disabled={loading}
									>
										{loading ? 'Loading' : 'LOGIN'}
									</Button>
								</Grid>
							</Form>
						)}
					</Formik>
				</Box>
			</Box>
		</Container>
	);
}
