import React, { useState, useRef } from 'react';
import './medicine.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { products } from './constants';
import CustomPagination from '../../../components/custompagination/custompagination';
import { useNavigate } from 'react-router-dom';
import { validateRequired } from '../../../utils/common';

function Medicine() {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const formSubmitted = useRef(false);

	const goToDetailsPage = () => {
		navigate('/medicinedetails/1');
	};

	const prescriptionsMedicines = [
		{ value: '', label: '--  Prescription Medicine   --' },
		{ value: '1', label: 'Wellness' },
		{ value: '2', label: 'Beauty' },
		{ value: '3', label: 'Fitness' },
	];

	const requiredFields = ['prescriptionMedicine', 'searchQuery'];
	const setFormValue = (field, value) => {
		const tmp = { ...formData, [field]: value };
		setFormData(tmp);
		if (formSubmitted.current) {
			const errors = validateRequired(requiredFields, tmp);
			setErrors(errors);
		}
	};

	const handleSearch = async () => {
		formSubmitted.current = true;
		const errors = validateRequired(requiredFields, formData);
		if (errors) {
			setErrors(errors); return;
		}
	};

	return (
		<Container
			sx={{
				padding: '40px',
				boxSizing: 'border-box',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Grid
				container
				spacing={2}
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Grid item xs={12} sm={3}>
					<FormControl fullWidth>
						<TextField
							label='--   Prescription Medicine   --'
							value={formData.prescriptionMedicine || ''}
							fullWidth
							size='small'
							select
							onChange={e => setFormValue('prescriptionMedicine', e.target.value)}
							{...(errors.prescriptionMedicine ? { error: true, helperText: 'This field is required.' } : {})}
						>
							{prescriptionsMedicines.map(sm => <MenuItem key={`sm-menu-${sm.value}`} value={sm.value} name={sm.label}>{sm.label}</MenuItem>)}
						</TextField>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormControl fullWidth>
						<TextField
							label='Search Query'
							variant='outlined'
							size='small'
							fullWidth
							value={formData.searchQuery || ''}
							onChange={e => setFormValue('searchQuery', e.target.value)}
							{...(errors.searchQuery ? { error: true, helperText: 'This field is required.' } : {})}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={3}>
					<FormControl fullWidth>
						<Button variant='contained' fullWidth className='btn-square' onClick={handleSearch}>
							Search
						</Button>
					</FormControl>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				sx={{
					marginTop: '20px',
					display: 'flex',
					rowGap: '20px',
					columnGap: '30px',
					justifyContent: 'center',
				}}
				columns={{ xs: 1, sm: 8, md: 12 }}
			>
				{products.map(({ image, name, price }, index) => (
					<Grid key={'product-' + index}>
						<Card
							sx={{
								width: 260,
								height: 300,
								padding: '14px',
								boxSizing: 'border-box',
							}}
							onClick={goToDetailsPage}
						>
							<CardMedia
								component='img'
								height='120'
								image={image}
								sx={{
									objectFit: 'contain',
								}}
								onClick={goToDetailsPage}
							/>
							<CardContent
								style={{ padding: 4 }}
								sx={{ height: '80px', boxSizing: 'border-box' }}
							>
								<Typography gutterBottom className='doc-name'>
									{name}
								</Typography>
							</CardContent>
							<Typography gutterBottom className='doc-name'>
								Price:{price}
							</Typography>
							<CardActions
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Button
									size='small'
									variant='contained'
									className='btn-square'
									onClick={() => { }}
								>
									Add to Cart
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
			<Grid
				sx={{
					margin: '20px',
				}}
			>
				<CustomPagination />
			</Grid>
		</Container >
	);
}
export default Medicine;
