import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Grid, Typography, Divider, TextField, Paper, Button, Box } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';


export default function ChangePassword() {
    const navigate = useNavigate();

    const initialValues = {};

    const validationSchema = Yup.object().shape({
        currPassword: Yup.string().required('Please enter current password'),
        password: Yup.string()
            .required('Please enter password')
            .min(8, 'Password minimum length should be 8'),
        confirmPassword: Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password')], 'Password not matched')
    });

    const handleSubmit = async (values) => {
        try {
            let res = await httpClient.post(APIUtils.getAPIEndpoint('change-password'), values);
            if (res?.data?.status === 'success') {
                Swal.fire(res?.data?.message, '', 'success');
                navigate('/admin');
            } else {
                Swal.fire(res?.data?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    return (
        <div className='admin-area-2'>
            <div className='page-header'>
                <Typography variant='h5'>Change Password</Typography>
            </div>
            <div className='page-content'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form>
                            <Paper>
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='xs'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Current Password:</Typography>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='currPassword'
                                                type='password'
                                                label='Current Password'
                                                placeholder='********'
                                                helperText={<ErrorMessage name='currPassword' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Password:</Typography>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='password'
                                                type='password'
                                                label='Password'
                                                placeholder='********'
                                                helperText={<ErrorMessage name='password' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Confirm Password:</Typography>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='confirmPassword'
                                                type='password'
                                                label='Confirm Password'
                                                placeholder='********'
                                                helperText={<ErrorMessage name='confirmPassword' />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 1 }} onClick={() => navigate('/admin')}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary'>Submit</Button>
                                </Box>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
