export const ON_VENDOR_TYPES_LOAD_SUCCESS = 'ON_VENDOR_TYPES_LOAD_SUCCESS';
export const ON_VENDOR_TYPES_LOAD_FAILURE = 'ON_VENDOR_TYPES_LOAD_FAILURE';

export const ON_DEPARTMENTS_LOAD_SUCCESS = 'ON_DEPARTMENTS_LOAD_SUCCESS';
export const ON_DEPARTMENTS_LOAD_FAILURE = 'ON_DEPARTMENTS_LOAD_FAILURE';

export const ON_QUALIFICATIONS_LOAD_SUCCESS = 'ON_QUALIFICATIONS_LOAD_SUCCESS';
export const ON_QUALIFICATIONS_LOAD_FAILURE = 'ON_QUALIFICATIONS_LOAD_FAILURE';

export const ON_SERVICETYPES_LOAD_SUCCESS = 'ON_SERVICETYPES_LOAD_SUCCESS';
export const ON_SERVICETYPES_LOAD_FAILURE = 'ON_SERVICETYPES_LOAD_FAILURE';

export const ON_DEPARTMENT_TYPES_LOAD_SUCCESS = 'ON_DEPARTMENT_TYPES_LOAD_SUCCESS';
export const ON_DEPARTMENT_TYPES_LOAD_FAILURE = 'ON_DEPARTMENT_TYPES_LOAD_FAILURE';

export const ON_VENDOR_SIGNUP_SUCCESS = 'ON_VENDOR_SIGNUP_SUCCESS';
export const ON_VENDOR_SIGNUP_FAILURE = 'ON_VENDOR_SIGNUP_FAILURE';

export const ON_DOCTOR_SIGNUP_SUCCESS = 'ON_DOCTOR_SIGNUP_SUCCESS';
export const ON_DOCTOR_SIGNUP_FAILURE = 'ON_DOCTOR_SIGNUP_FAILURE';
