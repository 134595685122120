import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './finddoctors.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
// import bariatricSurgery from '../../../assets/logos/department/bariatricsurgery.png';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

function FindDoctors() {
	const navigate = useNavigate();
	const [departments, setDepartments] = useState([]);

	const invokeDocCriteria = (itm) => {
		navigate('/finddoctors/' + itm);
	};

	const getDepartments = useCallback(async () => {
		try {
			const res = await httpClient.get(APIUtils.getAPIEndpoint('departments')).then(res => res.data);
			if (res.status === 'success') {
				setDepartments(res.data);
			} else {
				setDepartments([]);
			}
		} catch (error) {
			console.error('Error fetching departments:', error);
			setDepartments([]);
		}
	}, []);

	useEffect(() => {
		getDepartments();
	}, [getDepartments]);

	return (
		<Container className='doc-cat-content'>
			<Box>
				<Typography className='ext-large-brown-txt'>
					Browse by Department to Consult a Doctor
				</Typography>
			</Box>
			<Grid container spacing={2}>
				{departments?.map((item, index) => {
					return (
						<Grid item xs={12} sm={3} key={'dept-' + index}>
							<Box
								className='doc-category'
								onClick={() => invokeDocCriteria(item.id)}
							>
								<Avatar
									sx={{ width: 40, height: 40 }}
									alt='Bariatric Surgery'
									src={item.image}
									variant='square'
								/>
								<Typography className='small-black-txt'>
									{item.name}
								</Typography>
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</Container>
	);
}

export default FindDoctors;
