// SignupPopup.jsx
import React, { useState, useEffect } from 'react';
import './signup.css';
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { TextField, FormControlLabel, Link, Grid, Box, IconButton, Typography } from '@mui/material';
import { Radio, RadioGroup, FormControl, FormLabel } from '@mui/material';
import { Stack } from '@mui/system';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OtpInput from 'otp-input-react';
import { CgSpinner } from 'react-icons/cg';
import { signup, openLoginPopup } from '../../../redux/actions/authActions';
import { auth } from '../../../config/firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toast, Toaster } from 'react-hot-toast';
import PhoneInputField from '../../../components/customphone';
import { invalidPwdMsg, passwordRegex } from '../../../utils/constants';
import PropTypes from 'prop-types';

const SignupPopup = ({ open, onClose }) => {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        mobileNumber: '',
        age: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('Please enter first name')
            .min(3, 'It\'s too short'),
        lastName: Yup.string()
            .required('Please enter last name')
            .min(3, 'It\'s too short'),
        email: Yup.string()
            .required('Please enter email')
            .email('Enter valid email'),
        gender: Yup.string()
            .oneOf(['male', 'female'], 'Required')
            .required('Please select gender'),
        mobileNumber: Yup.string()
            .required('Please enter mobile number')
            .min(12, 'Please enter valid mobile number'),
        age: Yup.number()
            .required('Please enter age')
            .typeError('Enter valid Age'),
        password: Yup.string()
            .required('Please enter password')
            .matches(passwordRegex, invalidPwdMsg),
        confirmPassword: Yup.string()
            .required('Please confirm your password')
            .oneOf([Yup.ref('password')], 'Password not matched')
    });

    const loading = useSelector(state => state.auth.loading);
    const result = useSelector(state => state.auth.signupResult);

    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState('');
    const otpMsg = 'Enter OTP to verify';
    const [sendingOTP, setSendingOTP] = useState(false);
    const [isMobileVerified, setMobileVerified] = useState(false);

    const handleSubmit = (values) => {
        if (!isMobileVerified) {
            Swal.fire('Please verify your mobile number', '', 'error');
            return;
        }
        signup(values);
    };

    const onCaptchVerify = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',
                {
                    size: 'invisible',
                    callback: () => { sendOtp(); },
                    'expired-callback': () => { },
                },
                auth
            );
        }
    };

    const sendOtp = (mobileNumber) => {
        if (!mobileNumber || mobileNumber.length < 12) return;
        setSendingOTP(true);
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const formatPh = '+' + mobileNumber;
        console.log(formatPh);
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setSendingOTP(false);
                setShowOTP(true);
                toast.success('OTP sent successfully!');
                setOtp('');
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.message);
                setSendingOTP(false);
            });
    };

    const onOTPVerify = () => {
        setSendingOTP(true);
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                console.log(res);
                setMobileVerified(true);
                toast.success('Mobile number verified successfully!');
                setShowOTP(false);
                setSendingOTP(false);
            })
            .catch((err) => {
                toast.error(err.message);
                setSendingOTP(false);
            });
    };

    useEffect(() => {
        if (result) {
            if (result?.status === 'success') {
                Swal.fire(result?.message, '', 'success');
                setTimeout(() => openLoginPopup(), 2000);
            } else {
                Swal.fire(result?.message, '', 'error');
            }
        }
    }, [result]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors }) => (
                    <Form>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1, fontWeight: 'bold' }}>
                                <Typography className='brown-md-txt' sx={{ textAlign: 'center', fontWeight: 'bold' }}>Signup</Typography>
                            </div>
                            <IconButton onClick={onClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                            <div id='recaptcha-container'></div>
                            <Toaster toastOptions={{ duration: 4000 }} />
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ mt: 3 }} >
                                <Grid container spacing={2} className='signup-grid'>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            name='firstName'
                                            id='firstName'
                                            label='First name'
                                            autoFocus={false}
                                            placeholder='First name'
                                            required
                                            helperText={<ErrorMessage name='firstName' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            name='lastName'
                                            id='lastName'
                                            label='Last name'
                                            placeholder='Last name'
                                            required
                                            helperText={<ErrorMessage name='lastName' />}
                                        />
                                    </Grid>
                                    {/* Mobile Number Start  */}
                                    <>
                                        {!showOTP ?
                                            <>
                                                <Grid item xs={8}>
                                                    <Field
                                                        fullWidth
                                                        required
                                                        size='small'
                                                        name='mobileNumber'
                                                        id='mobileNumber'
                                                        component={PhoneInputField}
                                                    />
                                                    <ErrorMessage name='mobileNumber'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant='outlined'
                                                        size='medium'
                                                        disabled={errors?.mobileNumber}
                                                        sx={{ height: 40 }}
                                                        onClick={() => sendOtp(values.mobileNumber)}
                                                    >
                                                        {sendingOTP && <CgSpinner size={20} className='mt-1 animate-spin' />}
                                                        <span>Verify</span>
                                                    </Button>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs='9'>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        OTPLength={6}
                                                        otpType='number'
                                                        disabled={false}
                                                        autoFocus
                                                        className='otp-input'
                                                    ></OtpInput>
                                                    <Box display={'flex'} justifyContent={'center'}>
                                                        <Box><p style={{ fontSize: '0.75rem', color: '#d32f2f', marginBottom: '0px', marginTop: '5px' }}>{otpMsg}</p></Box>
                                                        <Button variant='text' onClick={() => setShowOTP(false)}>Cancel</Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs='3'>
                                                    <Button fullWidth variant='outlined' color='secondary'
                                                        onClick={onOTPVerify}
                                                        sx={{ height: '38px' }}
                                                    >
                                                        {loading && <CgSpinner size={20} className='mt-1 animate-spin' />}
                                                        <span>Verify</span>
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                    {/* Mobile Number End  */}
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            required
                                            name='email'
                                            label='Email'
                                            id='email'
                                            placeholder='Email address'
                                            helperText={<ErrorMessage name='email' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component='fieldset'>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <FormLabel component='legend' sx={{ fontWeight: 'bold' }}>Gender</FormLabel>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Field
                                                    row
                                                    as={RadioGroup}
                                                    aria-label='gender'
                                                    name='gender'
                                                    style={{ display: 'initial' }}
                                                    defaultValue='male'
                                                    helperText={<ErrorMessage name='age' />}
                                                >
                                                    <FormControlLabel
                                                        value='male'
                                                        control={<Radio />}
                                                        label='Male'
                                                    />
                                                    <FormControlLabel
                                                        value='female'
                                                        control={<Radio />}
                                                        label='Female'
                                                    />
                                                </Field>
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            required
                                            placeholder='Age'
                                            name='age'
                                            label='Age'
                                            type='number'
                                            id='age'
                                            helperText={<ErrorMessage name='age' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            required
                                            name='password'
                                            type='password'
                                            label='Password'
                                            placeholder='Enter your password'
                                            helperText={<ErrorMessage name='password' />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            size='small'
                                            required
                                            name='confirmPassword'
                                            type='password'
                                            label='Enter Confirm Password'
                                            placeholder='Enter Confirm Password'
                                            helperText={<ErrorMessage name='confirmPassword' />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center', pl: 3, pr: 3 }}>
                            <Button
                                type='submit'
                                fullWidth
                                size='small'
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                            >
                                {loading ? 'Loading' : 'SIGN UP'}
                            </Button>
                        </DialogActions>
                        <Grid container justifyContent='center' sx={{ marginBottom: '20px' }}>
                            <Grid item>
                                Already have an account?&nbsp;
                                <Link href='javascript:void(0);' onClick={() => openLoginPopup()} variant='body2'>
                                    Login
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

SignupPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default SignupPopup;
