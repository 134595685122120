import axios from 'axios';
import store from '../../redux/store';
import Swal from 'sweetalert2';
import { encryptStorage } from '../../utils/storage';

const getAuthToken = () => {
    return encryptStorage.getItem('user-token');
};

const setupInterceptors = (instance) => {
    instance.interceptors.request.use(config => {
        if (config?.showSpinner) {
            store.dispatch({ type: 'SHOW_SPINNER' });
        }
        config.headers.Authorization = getAuthToken();
        return config;
    });

    instance.interceptors.response.use(
        response => {
            const { config } = response;
            if (config?.showSpinner) {
                store.dispatch({ type: 'HIDE_SPINNER' });
            }
            return response;
        },
        error => {
            const { config } = error;
            if (config?.showSpinner) {
                store.dispatch({ type: 'HIDE_SPINNER' });
            }
            if (error.response && error.response.status === 401) {
                Swal.fire('Session Expired..., redirecting to Login...', '', 'error');
                encryptStorage.clear();
                setTimeout(() => { window.location.href = '/'; }, 3000);
            } else {
                console.log('Something went wrong!');
            }
            return Promise.reject(error);
        }
    );
};

const httpClient = axios.create({
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    headers: {
        'Authorization': getAuthToken(),
    }
});

setupInterceptors(httpClient);

export default httpClient;
