import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, OPEN_LOGIN_POPUP, OPEN_SIGNUP_POPUP, CLOSE_LOGIN_POPUP, CLOSE_SIGNUP_POPUP, LOGIN_COMPLETED } from '../types/authtypes';
import httpClient from '../../services/http';
import store from '../store';
import { encryptStorage } from '../../utils/storage';
import { APIUtils } from '../../utils/apiutils';

const dispatch = store.dispatch;

export const signup = async (userData) => {
    dispatch({ type: SIGNUP_REQUEST });
    try {
        const response = await httpClient.post(APIUtils.getAPIEndpoint('sign-up'), userData);
        dispatch({ type: SIGNUP_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: SIGNUP_FAILURE, error: error.response.data.message });
    }
};

export const login = async (credentials) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
        const response = await httpClient.post(APIUtils.getAPIEndpoint('log-in'), credentials);
        dispatch({ type: LOGIN_COMPLETED, payload: response.data });
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, error: error.response.data.message });
    }
};

export const loginSuccess = async (payload) => {
    dispatch({ type: LOGIN_SUCCESS, payload });
};

export const deleteLoginState = () => {
    encryptStorage.removeItem('user-info');
    encryptStorage.removeItem('user-token');
    dispatch({ type: LOGOUT });
};

export const openLoginPopup = () => {
    dispatch({ type: OPEN_LOGIN_POPUP });
};

export const closeLoginPopup = () => {
    dispatch({ type: CLOSE_LOGIN_POPUP });
};

export const openSignupPopup = () => {
    dispatch({ type: OPEN_SIGNUP_POPUP });
};

export const closeSignupPopup = () => {
    dispatch({ type: CLOSE_SIGNUP_POPUP });
};


