import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { AddEditMedicine } from './addMedicineModal';
import { BulkUploadModal } from './bulkUploadModal';
import { getMedicines, updateMedicine, deleteMedicine as deleteMedicineService } from './service';
import { getMedicineCategories } from '../medicinecategory/service';

const MedicineMaster = () => {
    const [modalState, setModalState] = useState({
        openAddEditMedicine: false,
        isEditMedicine: false,
        editMedicineData: {},
        openBulkUpload: false,
        bulkUploadTitle: '',
        bulkUploadCategory: undefined,
    });
    const [rows, setRows] = useState([]);
    const [medicineCategoryList, setMedicineCategoryList] = useState([]);

    const medicineCategories = useMemo(() => {
        return medicineCategoryList?.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
        }, {});
    }, [medicineCategoryList]);

    const handleUpdateStatus = useCallback((data) => {
        const newStatus = data.status === 'active' ? 'inactive' : 'active';
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate' : 'Deactivate'}?`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await updateMedicine(data.id, { status: newStatus });
                setRows(prevRows => prevRows.map(item => item.id === data.id ? { ...item, status: newStatus } : item));
                Swal.fire('Status!', newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!', 'success');
            }
        });
    }, []);

    const handleOpenBulkUploadPopup = useCallback((title, category) => {
        setModalState(prevState => ({
            ...prevState,
            openBulkUpload: true,
            bulkUploadTitle: title,
            bulkUploadCategory: category
        }));
    }, []);

    const handleDeleteMedicine = useCallback((data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteMedicineService(data.id);
                setRows(prevRows => prevRows.filter(item => item.id !== data.id));
                Swal.fire('Deleted!', 'Medicine has been deleted.', 'success');
            }
        });
    }, []);

    const handleEditMedicine = useCallback((data) => {
        setModalState(prevState => ({
            ...prevState,
            isEditMedicine: true,
            editMedicineData: data,
            openAddEditMedicine: true,
        }));
    }, []);

    const columns = useMemo(() => [
        { field: 'slNo', headerName: 'Sl No', flex: 1 },
        { field: 'category', headerName: 'Category', flex: 1, renderCell: ({ row }) => medicineCategories[row.medicineCategoryId] },
        { field: 'medicine_name', headerName: 'Name', flex: 1 },
        { field: 'price', headerName: 'Price', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => (
                <Chip
                    label={row.status === 'active' ? 'Active' : 'Pending'}
                    color={row.status === 'active' ? 'success' : 'warning'}
                    onClick={() => handleUpdateStatus(row)}
                />
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <IconButton onClick={() => handleEditMedicine(row)}>
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteMedicine(row)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </>
            )
        },
    ], [medicineCategories, handleDeleteMedicine, handleEditMedicine, handleUpdateStatus]);

    const handleFetchData = useCallback(async () => {
        try {
            const res = await getMedicines();
            if (res.status === 'success') {
                setRows(res.data);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const getMedicineCategoryList = useCallback(async () => {
        try {
            const results = await getMedicineCategories();
            setMedicineCategoryList(results);
        } catch (error) {
            console.error(error);
            setMedicineCategoryList([]);
        }
    }, []);

    useEffect(() => {
        getMedicineCategoryList();
        handleFetchData();
    }, [getMedicineCategoryList, handleFetchData,]);

    const rowsWithSlNo = rows.map((row, index) => ({
        ...row,
        slNo: index + 1,
    }));

    const CustomToolbar = useCallback(() => (
        <GridToolbarContainer sx={{ p: 2 }}>
            <div style={{ flex: 1 }}>
                <GridToolbarQuickFilter />
            </div>
            <div>
                <Button
                    size='small'
                    variant='contained'
                    sx={{ mr: 1 }}
                    color='secondary'
                    onClick={() => handleOpenBulkUploadPopup('Fitness Excel', '1')}
                >
                    Import Medicines
                </Button>
                <Button
                    size='small'
                    variant='contained'
                    sx={{ mr: 1 }}
                    color='primary'
                    onClick={() => setModalState(prevState => ({
                        ...prevState,
                        isEditMedicine: false,
                        openAddEditMedicine: true
                    }))}
                >
                    Add Medicine
                </Button>
            </div>
        </GridToolbarContainer>
    ), [handleOpenBulkUploadPopup]);

    return (
        <>
            <div className='admin-area-2'>
                <Typography variant='h5'>Medicine Lists</Typography>
                <div className='page-content'>
                    <Paper>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            columns={columns}
                            rows={rowsWithSlNo}
                            pageSize={25}
                            slots={{ toolbar: CustomToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    showExport: false,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Paper>
                </div>
            </div>

            <AddEditMedicine
                open={modalState.openAddEditMedicine}
                isEdit={modalState.isEditMedicine}
                data={modalState.editMedicineData}
                categoryList={medicineCategoryList}
                setOpen={(isOpen) => setModalState(prevState => ({ ...prevState, openAddEditMedicine: isOpen }))}
                refresh={handleFetchData}
            />

            <BulkUploadModal
                open={modalState.openBulkUpload}
                categoryList={medicineCategoryList}
                setOpen={(isOpen) => setModalState(prevState => ({ ...prevState, openBulkUpload: isOpen }))}
                refresh={handleFetchData}
            />
        </>
    );
};

export default MedicineMaster;