// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: 'AIzaSyBnOYu_u3RlyHnzDC5J9MnsrVeS4YOU3aI',
//   authDomain: 'vavaclasses.com',
//   projectId: 'vavaclasses',
//   storageBucket: 'vavaclasses.appspot.com',
//   messagingSenderId: '564200417674',
//   appId: '1:564200417674:web:34664a7990d0f355e5c073',
//   measurementId: 'G-511PS37CEV'
// };

const firebaseConfig = {
  apiKey: 'AIzaSyBJxWC_yS3nBMeD_dyYDln4lqWU766L7QQ',
  authDomain: 'treatu-382218.firebaseapp.com',
  projectId: 'treatu-382218',
  storageBucket: 'treatu-382218.appspot.com',
  messagingSenderId: '756887168652',
  appId: '1:756887168652:web:313bcf6c26c664dcd93da9',
  measurementId: 'G-5NT7YPE120'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
