const surgeryServices = [
	{ label: 'Polypectomy', id: 1 },
	{ label: 'Septoplasty (Synus Surgery)', id: 2 },
	{ label: 'Tonsillectomy (Tonsil Surgery)', id: 3 },
	{ label: 'Throidectomy (Thyroid Surgery)', id: 4 },
	{ label: 'Interventional Neurology', id: 5 },
	{ label: 'Tympanoplasty(Ear Drum Surgery)', id: 6 },
];

export { surgeryServices };
