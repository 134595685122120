import React, { useCallback, useEffect, useState } from 'react';
import './addoctor.css';
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { format } from 'date-fns';
import {
    Grid, Typography, IconButton, Divider, TextField, Paper,
    Button, Box, Accordion, AccordionDetails, AccordionSummary,
    Checkbox, FormControlLabel, Autocomplete
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { CgSpinner } from 'react-icons/cg';
import CustomDropdown from '../../../components/customdropdown';
import TimePicker from '../../../components/timepicker';
import { vendorTypes, settlementTypes, daysOfWeek, DEFAULT_AVAILIBILITY, USER_ROLES } from '../../../utils/constants';
import { convertBase64, objectToFormData, formatDate } from '../../../utils/utils';
import { createDoctor, getDoctorDetails, updateDoctor } from './service';
import { getDepartmentList, getQualificationList, getServiceTypeList } from '../../../redux/actions/adminactions';

const timeAvailabilityFields = (index, dayPrefix, values) => (
    <Grid container sx={{ mb: 1.5 }}>
        <Grid item md={2} sm={2} xs={2}>
            <Field name={`availabilities.${index}.${dayPrefix}Avail`}>
                {({ form, field }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                color='primary'
                                checked={!!field.value}
                                onChange={(e) => form.setFieldValue(field.name, e.target.checked ? 1 : 0)}
                            />
                        }
                        label={dayPrefix.charAt(0).toUpperCase() + dayPrefix.slice(1)}
                    />
                )}
            </Field>
        </Grid>
        <Grid item md={5} sm={5} xs={5}>
            <Field name={`availabilities.${index}.${dayPrefix}StartTime`}>
                {({ field, form }) => (
                    <TimePicker
                        sx={{ ml: 3 }}
                        name={field.name}
                        selectedTime={formatDate(values['availabilities'][index][`${dayPrefix}StartTime`])}
                        onChange={(date) => form.setFieldValue(field.name, format(new Date(date), 'HH:mm:00'))}
                    />
                )}
            </Field>
            <ErrorMessage name={`${dayPrefix}StartTime`} component='div' />
        </Grid>
        <Grid item md={5} sm={5} xs={5}>
            <Field name={`availabilities.${index}.${dayPrefix}EndTime`}>
                {({ field, form }) => (
                    <TimePicker
                        name={field.name}
                        selectedTime={formatDate(values['availabilities'][index][`${dayPrefix}EndTime`])}
                        onChange={(date) => form.setFieldValue(field.name, format(new Date(date), 'HH:mm:00'))}
                    />
                )}
            </Field>
            <ErrorMessage name={`${dayPrefix}EndTime`} component='div' />
        </Grid>
    </Grid>
);

const setInitialVal = (res = {}) => {
    const {
        email = '',
        doctorprofile: data = {},
        doctoravailibilities: availabilities = [DEFAULT_AVAILIBILITY]
    } = res;

    return {
        firstName: data.firstName || '',
        middleName: data.middleName || '',
        lastName: data.lastName || '',
        mobileNumber: data.mobileNumber || '',
        profilePic: data.profilePic || '',
        email,
        gender: data.gender || '',
        age: data.age || '',
        about: data.about || '',
        password: '',
        qualifications: data?.qualifications ? data?.qualifications?.split(',') : [],
        awards: data.awards || '',
        experience: data.experience || '',
        vendorType: data.vendorType || '',
        registrationNumber: data.registrationNumber || '',
        legalDoc: data.legalDoc || '',
        serviceTypeId: data.serviceTypeId || '',
        serviceCost: data.serviceCost || '',
        settlementType: data.settlementType || '',
        country: data.country || '',
        state: data.state || '',
        street: data.street || '',
        district: data.district || '',
        city: data.city || '',
        area: data.area || '',
        houseOrPlotNo: data.houseOrPlotNo || '',
        zipcode: data.zipcode || '',
        serviceName: data.serviceName || '',
        personalVisitPrice: data.personalVisitPrice || '',
        teleConsultation: data.teleConsultation || 0,
        teleConsultationPrice: data.teleConsultationPrice || '',
        departmentId: data.departmentId || '',
        availabilities
    };
};


function AdDoctor() {
    const navigate = useNavigate();
    const { page, userId } = useParams();
    const [initialValues, setInitialValues] = useState(setInitialVal());
    const [loading, setLoading] = useState(!!userId);
    const [previewImg, setPreviewImg] = useState(initialValues?.profilePic);
    const [profilePicName, setProfilePicName] = useState('');
    const [legalDocName, setLegalDocName] = useState('');
    const userInfo = useSelector(state => state.auth?.user);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Please enter first name'),
        lastName: Yup.string().required('Please enter last name'),
        email: Yup.string().required('Please eneter email').email('Invalid email address'),
        password: userId ? Yup.string().optional() : Yup.string().required('Please enter password'),
        departmentId: Yup.string().required('Please select department'),
        experience: Yup.number().required('Please enter experience').positive('Experience must be a positive number'),
        vendorType: Yup.string().required('Please select vendor type'),
        registrationNumber: Yup.string().required('Please enter registration number'),
        serviceName: Yup.string().required('Please enter service name'),
        serviceTypeId: Yup.string().required('Please select service type'),
        serviceCost: Yup.string().required('Please enter service cost'),
        qualifications: Yup.array().min(1, 'Please select qualification'),
    });

    const departments = useSelector(state => state.admin.departments);
    const qualifications = useSelector(state => state.admin.qualifications);
    const serviceTypes = useSelector(state => state.admin.servicetypes);
    const loadingCount = useSelector(state => state.admin.loading);

    const fetchData = useCallback(async (userId) => {
        const res = await getDoctorDetails(userId);
        setInitialValues(setInitialVal(res));
        setPreviewImg(res?.doctorprofile?.profilePic);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (userId) fetchData(userId);
        if (!departments) getDepartmentList();
        if (!qualifications) getQualificationList();
        if (!serviceTypes) getServiceTypeList();
    }, [fetchData, departments, qualifications, serviceTypes, userId]);

    const browseProfilePic = async (e, form, field) => {
        setProfilePicName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
        const fileData = e.target.files[0];
        const base64 = await convertBase64(fileData);
        setPreviewImg(base64);
    };

    const browseLegalDoc = (e, form, field) => {
        setLegalDocName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const handleSubmit = async (formValues) => {
        try {
            const { availabilities, ...otherData } = formValues;
            const formData = objectToFormData(otherData);
            formData.append('availabilities', JSON.stringify(availabilities));
            const res = userId ? await updateDoctor(userId, formData) : await createDoctor(formData);
            if (res?.status === 'success') {
                if (userInfo?.role === USER_ROLES.DOCTOR) {
                    Swal.fire('Profile updated successfully!', '', 'success');
                    navigate('/admin');
                } else {
                    Swal.fire(res?.message, '', 'success');
                    navigate('/admin/doctors');
                }
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    if (loading || loadingCount) return <div>Loading...</div>;

    return (
        <div className='admin-area-2'>
            <div className='page-header'>
                <Link to="/admin/doctors" className='backBtnLink'>
                    <IconButton><ArrowBackIcon /></IconButton>
                    <Typography variant='span'>{page === 'edit' ? 'Edit' : 'Add'} Doctor</Typography>
                </Link>
            </div>
            <div className='page-content'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, isSubmitting, errors, touched, setFieldValue }) => (
                        <Form>
                            <Paper name='add'>
                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Personal Info:</Typography>
                                <Divider />
                                <div className='input-content-center'>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='firstName'
                                                id='firstName'
                                                label='First Name'
                                                placeholder='First Name'
                                                helperText={<ErrorMessage name='firstName' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='middleName'
                                                id='middleName'
                                                label='Middle Name'
                                                placeholder='Middle Name'
                                                helperText={<ErrorMessage name='middleName' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='lastName'
                                                id='lastName'
                                                label='Last Name'
                                                placeholder='Last Name'
                                                helperText={<ErrorMessage name='lastName' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                id='mobile'
                                                name='mobileNumber'
                                                label='Mobile Number'
                                                placeholder='Mobile number'
                                                helperText={<ErrorMessage name='mobileNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                disabled={!!userId}
                                                fullWidth
                                                size='small'
                                                name='email'
                                                label='Email'
                                                id='email'
                                                placeholder='Email address'
                                                helperText={<ErrorMessage name='email' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='age'
                                                label='Age'
                                                id='age'
                                                placeholder='Age'
                                                helperText={<ErrorMessage name='age' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                required
                                                id='departmentId'
                                                name='departmentId'
                                                label='Select Department'
                                                options={departments}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='departmentId'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <FieldArray name="qualifications">
                                                {() => (
                                                    <Autocomplete
                                                        multiple
                                                        options={qualifications}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(event, value) => {
                                                            setFieldValue('qualifications', value.map((option) => option.name));
                                                        }}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Select Qualifications"
                                                                placeholder="Choose..."
                                                                error={touched.qualifications && Boolean(errors.qualifications)}
                                                                helperText={touched.qualifications && errors.qualifications}
                                                            />
                                                        )}
                                                        value={qualifications.filter((option) => values?.qualifications?.includes(option.name))}
                                                        isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    />
                                                )}
                                            </FieldArray>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                multiline
                                                rows={3}
                                                size='small'
                                                name='about'
                                                id='about'
                                                label='About'
                                                placeholder='About'
                                                helperText={<ErrorMessage name='about' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                multiline
                                                rows={3}
                                                size='small'
                                                name='awards'
                                                id='awards'
                                                label='Award & Achievement'
                                                placeholder='Award & Achievement'
                                                helperText={<ErrorMessage name='awards' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='experience'
                                                id='experience'
                                                label='Experience'
                                                placeholder='Experience'
                                                helperText={<ErrorMessage name='experience' />}
                                            />
                                        </Grid>
                                        {!userId &&
                                            <Grid item md={6} sm={6} xs={12}>
                                                <Field
                                                    as={TextField}
                                                    required
                                                    fullWidth
                                                    size='small'
                                                    name='password'
                                                    type='password'
                                                    label='Password'
                                                    placeholder='********'
                                                    helperText={<ErrorMessage name='password' />}
                                                />
                                            </Grid>
                                        }
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field name='profilePicNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='image/png, image/gif, image/jpeg'
                                                                name={field.name}
                                                                hidden={true}
                                                                onChange={(e) => browseProfilePic(e, form, field)}
                                                            />
                                                            Browse Profile Pic
                                                        </Button>
                                                        <Typography variant='span' sx={{ ml: 2 }}>{profilePicName ? profilePicName : 'No file selected'}</Typography>
                                                    </>
                                                )}
                                            </Field>
                                            {previewImg && <div><img style={{ maxWidth: '80px', marginTop: '5px' }} src={previewImg} alt='profile pic' /></div>}
                                        </Grid>
                                    </Grid>
                                </div>
                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Basic Info:</Typography>
                                <Divider />
                                <div className='input-content-center'>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                required
                                                id='vendorType'
                                                name='vendorType'
                                                label='Vendor Type'
                                                options={vendorTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='departmentId'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='serviceName'
                                                id='serviceName'
                                                label='Service Name'
                                                placeholder='Service Name'
                                                helperText={<ErrorMessage name='serviceName' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='registrationNumber'
                                                id='registrationNumber'
                                                label='Registration Number'
                                                placeholder='Registration Number'
                                                helperText={<ErrorMessage name='registrationNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='personalVisitPrice'
                                                id='personalVisitPrice'
                                                label='Personal Visit Price (INR)'
                                                placeholder='Personal Visit Price (INR)'
                                                helperText={<ErrorMessage name='personalVisitPrice' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='teleConsultationPrice'
                                                id='teleConsultationPrice'
                                                label='Tele Consultation Price (INR)'
                                                placeholder='Tele Consultation Price (INR)'
                                                helperText={<ErrorMessage name='teleConsultationPrice' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field name='legalDocNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='application/pdf'
                                                                name={field.name}
                                                                hidden
                                                                onChange={(e) => browseLegalDoc(e, form, field)}
                                                            />
                                                            Browse Legal Document
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{legalDocName ? legalDocName : 'No file selected'}</Typography>
                                            {initialValues?.legalDoc && <div><a href={initialValues?.legalDoc}>{initialValues?.legalDoc.split('/').pop()}</a></div>}
                                        </Grid>
                                    </Grid>
                                </div>

                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Treatu Service Tax:</Typography>
                                <Divider />
                                <div className='input-content-center'>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                required
                                                id='serviceTypeId'
                                                name='serviceTypeId'
                                                label='Treatu Service Type'
                                                options={serviceTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='serviceTypeId'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='serviceCost'
                                                id='serviceCost'
                                                label='Service Cost Percentage'
                                                placeholder='Service Cost Percentage'
                                                inputProps={{ min: 1, max: 100 }}
                                                type="number"
                                                max={100}
                                                helperText={<ErrorMessage name='serviceCost' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                required
                                                id='settlementType'
                                                name='settlementType'
                                                label='Settlement Type'
                                                options={settlementTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='vendorType'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Personal Address:</Typography>
                                <Divider />
                                <div className='input-content-center'>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='country'
                                                id='country'
                                                label='Country'
                                                placeholder='Country'
                                                helperText={<ErrorMessage name='country' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='state'
                                                id='state'
                                                label='State'
                                                placeholder='State'
                                                helperText={<ErrorMessage name='state' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='district'
                                                id='district'
                                                label='District'
                                                placeholder='District'
                                                helperText={<ErrorMessage name='district' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='city'
                                                id='city'
                                                label='City'
                                                placeholder='City'
                                                helperText={<ErrorMessage name='city' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='area'
                                                id='area'
                                                label='Area'
                                                placeholder='Area'
                                                helperText={<ErrorMessage name='area' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='street'
                                                id='street'
                                                label='Street'
                                                placeholder='Street'
                                                helperText={<ErrorMessage name='street' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='houseOrPlotNo'
                                                id='houseOrPlotNo'
                                                label='House No / Plot No'
                                                placeholder='House No / Plot No'
                                                helperText={<ErrorMessage name='houseOrPlotNo' />}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='zipcode'
                                                id='zipcode'
                                                label='Zip Code'
                                                placeholder='Zip Code'
                                                helperText={<ErrorMessage name='zipcode' />}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Consultation Details:</Typography>
                                <Divider />
                                <Box>
                                    <FieldArray name="availabilities">
                                        {({ remove, push }) => (
                                            <>
                                                {values.availabilities.map((availability, index) => (
                                                    <Accordion key={'availability-' + index}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                        >
                                                            <Typography variant="h6" sx={{ paddingTop: '4px' }}>Address {index + 1}</Typography>
                                                            {values.availabilities.length > 1 && <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => remove(index)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>}
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ ml: 8 }}>
                                                            <Box sx={{ mb: 2, mr: 16 }}>
                                                                <Field name={`availabilities.${index}.address`}>
                                                                    {({ form, field }) => (
                                                                        <TextField
                                                                            name={field.name}
                                                                            value={availability['address']}
                                                                            fullWidth
                                                                            multiline
                                                                            rows={2}
                                                                            label='Address Details'
                                                                            onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Box>
                                                            <Grid container>
                                                                {daysOfWeek.map(day => timeAvailabilityFields(index, day, values))}
                                                            </Grid>
                                                            {/* {values.availabilities.length > 1 && <Button sx={{ mt: 1 }} variant='outlined' color='error' onClick={() => remove(index)}>Remove Availability</Button>} */}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                                <Button sx={{ m: 2 }} variant='outlined' color='primary' onClick={() => push({ ...DEFAULT_AVAILIBILITY })}>Add New Availability</Button>
                                            </>
                                        )}
                                    </FieldArray>
                                </Box>
                                <Divider />

                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 1 }} onClick={() => navigate('/admin/doctors')}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                                        {isSubmitting && <CgSpinner size={20} className='mt-1 animate-spin' />} Save
                                    </Button>
                                </Box>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default AdDoctor;