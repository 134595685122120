import React, { useNavigate } from 'react-router-dom';
import './browsesurgeries.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { surgeries } from './constants';
function BrowseBySurgeries() {
	const navigate = useNavigate();
	const handleNavigation = (id) => {
		navigate('/servicespersurgery/' + id);
	};
	return (
		<Container className='doc-cat-content'>
			<Box>
				<Typography className='ext-large-brown-txt'>
					Browse by Department to Book For Advanced Surgery
				</Typography>
			</Box>
			<Grid container spacing={2}>
				{surgeries.map(({ id, label, src }) => (
					<Grid key={id} item xs={12} sm={3}>
						<Box className='doc-category' onClick={() => handleNavigation(id)}>
							<Avatar
								sx={{ width: 40, height: 40 }}
								alt='Bariatric Surgery'
								src={src}
								variant='square'
							/>
							<Typography className='small-black-txt'>{label}</Typography>
						</Box>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
export default BrowseBySurgeries;
