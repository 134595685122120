import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Collapse, Toolbar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import logo from '../../../../assets/logos/treatu_logo.png';
import ProfileMenu from '../../../profile';
import AdminMenu from '../../../../utils/adminMenu';
import { mobileCheck } from '../../../../utils/common';
import './admintoolbar.css';
import { USER_ROLES } from '../../../../utils/constants';

const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function AdminToolBar({ logout, userInfo }) {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [pathName, setPathName] = useState('');
    const [openMenu, setOpenMenu] = useState({});
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const isMobileDevice = mobileCheck();
        setIsMobile(isMobileDevice);
        if (isMobileDevice) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        setPathName(location.pathname);
        if (![USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR, USER_ROLES.DOCTOR].includes(userInfo?.role)) {
            navigate('/');
        }
    }, [location.pathname, userInfo, navigate]);

    const handleDrawerOpen = () => {
        setOpen(true);
        setOpenMenu({});
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenMenu({});
    };

    const onItemClick = (item, idx) => {
        if (item.children) {
            openDropdown(idx);
            return;
        }
        navigate(item.path);
        if (isMobile) {
            setOpenMenu({});
        }
    };

    const openDropdown = (idx) => {
        setOpenMenu((prevState) => ({
            ...prevState,
            [idx]: !prevState[idx],
        }));
    };

    const getMenuItem = (item, index, userRole) => (
        <Fragment key={index}>
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => onItemClick(item, index)} className={pathName === item.path ? 'selected' : ''}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {item.icon ? <item.icon /> : <HideImageOutlinedIcon />}
                    </ListItemIcon>
                    <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                    {item.children && open && (openMenu[index] ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
            </ListItem>
            {item.children && (
                <Collapse in={openMenu[index]} timeout="auto" unmountOnExit sx={{ pl: 4 }} className={!open ? 'nested-menu-option' : ''}>
                    <List component="div" disablePadding>
                        {item.children
                            .filter((child) => child?.allowUser?.includes(userRole))
                            .map((cItem, cIdx) => getMenuItem(cItem, `${index}_${cIdx}`, userRole))}
                    </List>
                </Collapse>
            )}
        </Fragment>
    );

    return (
        userInfo && (
            <Box className="admin-toolbar" sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className="do-btn"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ position: 'absolute', right: '30px' }}>
                            <ProfileMenu userInfo={userInfo} logout={logout} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader className="space-between">
                        <Link to="/" target="_blank">
                            <img src={logo} alt="treatu logo" className="admin-treatu-logo" />
                        </Link>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {AdminMenu.filter((item) => item?.allowUser?.includes(userInfo.role)).map((item, index) => getMenuItem(item, index, userInfo?.role))}
                    </List>
                </Drawer>
            </Box>
        )
    );
}

AdminToolBar.propTypes = {
    logout: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
};

export default AdminToolBar;
