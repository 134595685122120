
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getVendors = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('vendor')).then(res => res.data.data);
};

export const createVendor = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('vendor'), payload).then(res => res.data);
};

export const updateVendor = (userId, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('vendor') + `/${userId}`, payload).then(res => res.data);
};

export const deleteVendor = (id) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id }).then(res => res.data);
};

export const updateUserStatus = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('update-user-status'), payload).then(res => res.data);
};

export const importVendors = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('import-vendor'), payload).then(res => res.data);
};