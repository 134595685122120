import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import { validateRequired } from '../../../utils/common';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { createMedicine, updateMedicine } from './service';

const defaultValue = { status: 'active' };
const requiredFields = ['medicineCategoryId', 'composition', 'weight', 'trade_name', 'medicine_name', 'product_type', 'price', 'sell_price', 'quantity_info', 'manufacture', 'mfgCountry', 'status', 'product_details', 'users', 'benefits', 'side_effect', 'howToUse'];

export function AddEditMedicine(props) {
    const { open, setOpen, isEdit, refresh, data, categoryList } = props;
    const [formData, setFormData] = useState(defaultValue);
    const [errors, setErrors] = useState({});
    const formSubmitted = useRef(false);
    const fileRef = useRef(null);

    useEffect(() => {
        setErrors({});
        formSubmitted.current = false;
        if (isEdit) {
            setFormData(data);
        } else {
            setFormData(defaultValue);
        }
    }, [open, isEdit, data]);

    const handleClose = () => setOpen(false);

    const setFormValue = (field, value) => {
        const tmp = { ...formData, [field]: value };
        setFormData(tmp);
        if (formSubmitted.current) {
            const errors = validateRequired(requiredFields, tmp);
            setErrors(errors);
        }
    };

    const handleSave = async () => {
        formSubmitted.current = true;
        const errors = validateRequired(requiredFields, formData);
        if (errors) {
            setErrors(errors); return;
        }
        Swal.showLoading();
        try {
            let res = isEdit ? await updateMedicine(data.id, formData) : await createMedicine(formData);
            if (res.status === 'success') {
                Swal.fire(res.message, '', 'success');
                handleClose();
                refresh();
            } else {
                Swal.fire(res.message, '', 'error');
            }
        } catch (e) {
            Swal.fire('Something Went Wrong!', '', 'error');
        } finally {
            Swal.hideLoading();
        }
    };

    return (
        <Dialog open={open} maxWidth='md' fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    {open}
                </div>
                <IconButton onClick={handleClose} size='small'>
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container className='profile-grid'>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Category'
                            value={formData.medicineCategoryId || ''}
                            fullWidth
                            size='small'
                            select
                            onChange={e => setFormValue('medicineCategoryId', e.target.value)}
                            {...(errors.medicineCategoryId ? { error: true, helperText: 'Category required.' } : {})}
                        >
                            {categoryList.map(item => <MenuItem key={`sm-menu-${item.id}`} value={item.id} name={item.name}>{item.name}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Composition'
                            value={formData.composition || ''}
                            fullWidth
                            size='small'
                            onChange={e => setFormValue('composition', e.target.value)}
                            {...(errors.composition ? { error: true, helperText: 'Composition is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            name='Outlined'
                            label='Molecular Weight'
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={formData.weight}
                            onChange={e => setFormValue('weight', e.target.value)}
                            {...(errors.weight ? { error: true, helperText: 'Molecular Weight is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Trade Name'
                            value={formData.trade_name}
                            onChange={e => setFormValue('trade_name', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.trade_name ? { error: true, helperText: 'Trade Name is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Medicine Name'
                            value={formData.medicine_name}
                            onChange={e => setFormValue('medicine_name', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.medicine_name ? { error: true, helperText: 'Medicine Name is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Product Type'
                            value={formData.product_type}
                            onChange={e => setFormValue('product_type', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.product_type ? { error: true, helperText: 'Product Type is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Price'
                            type='number'
                            value={formData.price}
                            onChange={e => setFormValue('price', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.price ? { error: true, helperText: 'Price is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Sell Price'
                            type='number'
                            value={formData.sell_price}
                            onChange={e => setFormValue('sell_price', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.sell_price ? { error: true, helperText: 'Sell Price is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Quantity Info'
                            value={formData.quantity_info}
                            onChange={e => setFormValue('quantity_info', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.quantity_info ? { error: true, helperText: 'Quantity Info is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Manufacture'
                            value={formData.manufacture}
                            onChange={e => setFormValue('manufacture', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.manufacture ? { error: true, helperText: 'Manufacture is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Country of Origin'
                            value={formData.mfgCountry}
                            onChange={e => setFormValue('mfgCountry', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.mfgCountry ? { error: true, helperText: 'Country of Origin is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Status'
                            value={formData.status || ''}
                            fullWidth
                            size='small'
                            select
                            onChange={e => setFormValue('status', e.target.value)}
                            {...(errors.status ? { error: true, helperText: 'Status is required.' } : {})}
                        >
                            <MenuItem value={'active'}>Active</MenuItem>
                            <MenuItem value={'in_active'}>In-Active</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md='12' sm='12' xs='12'>
                        <Typography>Product Image(s):</Typography>
                        <input type='file' hidden multiple ref={fileRef} accept='image/png, image/jpeg' />
                        <Box
                            className='fileUploadArea'
                            onClick={() => fileRef.current.click()}
                        >
                            <Button color='secondary'>
                                <FileUploadOutlinedIcon />
                            </Button>
                            <Typography>{'Choose File'}</Typography>
                        </Box>
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Product Details'
                            multiline
                            rows={4}
                            value={formData.product_details}
                            onChange={e => setFormValue('product_details', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.product_details ? { error: true, helperText: 'Product Details is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Users'
                            multiline
                            rows={4}
                            value={formData.users}
                            onChange={e => setFormValue('users', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.users ? { error: true, helperText: 'Users is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Benefits'
                            multiline
                            rows={4}
                            value={formData.benefits}
                            onChange={e => setFormValue('benefits', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.benefits ? { error: true, helperText: 'Benefits is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='Side Effect'
                            multiline
                            rows={4}
                            value={formData.side_effect}
                            onChange={e => setFormValue('side_effect', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.side_effect ? { error: true, helperText: 'Side Effect is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md='6' sm='12' xs='12'>
                        <TextField
                            label='How To Use'
                            multiline
                            rows={4}
                            value={formData.howToUse}
                            onChange={e => setFormValue('howToUse', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.howToUse ? { error: true, helperText: 'How To Use is required.' } : {})}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <FormControl>
                    <Button variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
                </FormControl>
                <FormControl >
                    <Button variant='contained' color='primary' onClick={handleSave}>Save</Button>
                </FormControl>
            </DialogActions>
        </Dialog>
    );
}

AddEditMedicine.propTypes = {
    open: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    data: PropTypes.object,
    categoryList: PropTypes.array,
};