import React, { useState, useEffect } from 'react';
import './address.css';
import {
    Paper,
    Container,
    Grid,
    TextField,
    Divider,
    Button,
    Box,
    Typography,
    IconButton,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { INDIAN_STATES } from '../../../utils/constants';
import httpClient from '../../../services/http';
import Swal from 'sweetalert2';
import { APIUtils } from '../../../utils/apiutils';

function Address({ setAddEdit, currentAddress }) {
    const [address, setAddress] = useState({
        type: '',
        contactPerson: '',
        contactNumber: '',
        state: '',
        city: '',
        locality: '',
        address: '',
        landmark: '',
        pincode: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (currentAddress) setAddress(currentAddress);
    }, [currentAddress]);

    const setFieldValue = (field, value) => {
        setAddress(prev => ({ ...prev, [field]: value }));
    };

    const validateFields = () => {
        const newErrors = {};
        if (!address.type) newErrors.type = 'Address type is required';
        if (!address.contactPerson) newErrors.contactPerson = 'Contact person is required';
        if (!address.contactNumber) newErrors.contactNumber = 'Contact number is required';
        if (!address.state) newErrors.state = 'State is required';
        if (!address.city) newErrors.city = 'City is required';
        if (!address.locality) newErrors.locality = 'Locality is required';
        if (!address.address) newErrors.address = 'Address is required';
        if (!address.landmark) newErrors.landmark = 'Landmark is required';
        if (!address.pincode) newErrors.pincode = 'Pincode is required';
        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            let res;
            const url = APIUtils.getAPIEndpoint('addresses');
            if (currentAddress)
                res = await httpClient.put(url + '/' + currentAddress.id, address);
            else
                res = await httpClient.post(url, address);
            if (res?.data?.status === 'success') {
                Swal.fire(res?.data?.message, '', 'success');
                setAddEdit(false);
            } else {
                Swal.fire(res?.data?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    return (
        <Container component='main' maxWidth='lg' sx={{ mt: 3, mb: 3, textAlign: 'left' }}>
            <Paper elevation={3} className='profile-grid'>
                <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                    <Typography variant='h5' className='padding-20' sx={{ flex: 1 }}>
                        {currentAddress ? 'Edit Address' : 'Add Address'}
                    </Typography>
                    <IconButton aria-label='Back' onClick={() => setAddEdit(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <form onSubmit={handleSubmit}>
                    <Grid container sx={{ p: 4, pb: 6 }} spacing={2}>
                        <Grid item md={6} sm={12} xs={12}>
                            <FormControl fullWidth size='small' error={!!errors.type}>
                                <InputLabel id='select-address-type'>Select Address type</InputLabel>
                                <Select
                                    labelId='select-address-type'
                                    value={address.type}
                                    label='Select Address type'
                                    onChange={e => setFieldValue('type', e.target.value)}
                                >
                                    <MenuItem value='Home'>Home</MenuItem>
                                    <MenuItem value='Office'>Office</MenuItem>
                                    <MenuItem value='Others'>Others</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Contact Person'
                                variant='outlined'
                                size='small'
                                value={address.contactPerson}
                                onChange={e => setFieldValue('contactPerson', e.target.value)}
                                fullWidth
                                error={!!errors.contactPerson}
                                helperText={errors.contactPerson}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Contact Number'
                                variant='outlined'
                                size='small'
                                value={address.contactNumber}
                                onChange={e => setFieldValue('contactNumber', e.target.value)}
                                fullWidth
                                error={!!errors.contactNumber}
                                helperText={errors.contactNumber}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <FormControl fullWidth size='small' error={!!errors.state}>
                                <InputLabel id='select-state'>Select State</InputLabel>
                                <Select
                                    labelId='select-state'
                                    value={address.state}
                                    label='Select State'
                                    onChange={e => setFieldValue('state', e.target.value)}
                                >
                                    {INDIAN_STATES.map((state, index) => (
                                        <MenuItem key={index} value={state}>{state}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='City'
                                variant='outlined'
                                size='small'
                                value={address.city}
                                onChange={e => setFieldValue('city', e.target.value)}
                                fullWidth
                                error={!!errors.city}
                                helperText={errors.city}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Locality'
                                variant='outlined'
                                size='small'
                                value={address.locality}
                                onChange={e => setFieldValue('locality', e.target.value)}
                                fullWidth
                                error={!!errors.locality}
                                helperText={errors.locality}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Address'
                                variant='outlined'
                                size='small'
                                value={address.address}
                                onChange={e => setFieldValue('address', e.target.value)}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Landmark'
                                variant='outlined'
                                size='small'
                                value={address.landmark}
                                onChange={e => setFieldValue('landmark', e.target.value)}
                                fullWidth
                                error={!!errors.landmark}
                                helperText={errors.landmark}
                            />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TextField
                                label='Pincode'
                                variant='outlined'
                                size='small'
                                value={address.pincode}
                                onChange={e => setFieldValue('pincode', e.target.value)}
                                fullWidth
                                error={!!errors.pincode}
                                helperText={errors.pincode}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box className='padding-20' sx={{ textAlign: 'right' }}>
                        <Button type='reset' variant='outlined' color='secondary' sx={{ mr: 2 }} onClick={() => setAddEdit(false)} >Cancel</Button>
                        <Button type='submit' variant='contained' color='primary' className='btn-square'>
                            {currentAddress ? 'Update' : 'Submit'}
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

Address.propTypes = {
    setAddEdit: PropTypes.func.isRequired,
    currentAddress: PropTypes.object
};

export default Address;
