import React from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { PropTypes } from 'prop-types';

export function CustomDateTimePicker(props) {
    const { value, minDate, handleChange } = props;
    const today = minDate !== undefined ? minDate : dayjs();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DateTimePicker
                    label="Date & Time"
                    value={value}
                    onChange={handleChange}
                    minDate={today}  // Disable past dates
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}

CustomDateTimePicker.propTypes = {
    value: PropTypes.instanceOf(dayjs).isRequired,
    minDate: PropTypes.instanceOf(dayjs) || null,
    handleChange: PropTypes.func.isRequired,
};


