import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './addVendors.css';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Grid, Typography, IconButton, Divider, TextField, Paper, Button, Box } from '@mui/material';
import { FormControlLabel, Checkbox } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CgSpinner } from 'react-icons/cg';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { convertBase64, objectToFormData } from '../../../utils/utils';
import { adminTypes, vendorTypes, settlementTypes, genders } from '../../../utils/constants';
import TimePicker from '../../../components/timepicker';
import CustomDropdown from '../../../components/customdropdown';
import { createVendor, updateVendor } from './service';
import dayjs from 'dayjs';
import { getServiceTypes } from '../../../redux/actions/vendoraction';
import PropTypes from 'prop-types';

const formatDate = (time) => {
    const date = new Date();
    if (time) {
        const [hours, minutes] = time.split(':');
        date.setHours(parseInt(hours), parseInt(minutes));
    }
    return dayjs(date);
};

function AddVendor(props) {
    const { isEdit, data, closeAdding, refresh } = props;
    const location = useLocation();
    const [previewImg, setPreviewImg] = useState(data?.profilePic);
    const [profilePicName, setProfilePicName] = useState('');
    const [legalDocName, setLegalDocName] = useState('');
    const [serviceStartTime, setServiceStartTime] = useState(formatDate(data?.serviceStartTime));
    const [serviceEndTime, setServiceEndTime] = useState(formatDate(data?.serviceEndTime));

    const initialValues = {
        vendorType: data?.vendorType || '',
        firstName: data?.firstName || '',
        middleName: data?.middleName || '',
        lastName: data?.lastName || '',
        mobileNumber: data?.mobileNumber || '',
        profilePic: data?.profilePic || '',
        aadharNumber: data?.aadharNumber || '',
        email: data?.email || '',
        gender: data?.gender || '',
        age: data?.age || '',
        password: '',
        vendorLegalName: data?.vendorLegalName || '',
        registrationNumber: data?.registrationNumber || '',
        doHomeCollection: data?.doHomeCollection ? 1 : 0,
        logo: data?.logo || '',
        legalDoc: data?.legalDoc || '',
        serviceStartTime: data?.serviceStartTime || '00:00:00',
        serviceEndTime: data?.serviceEndTime || '00:00:00',
        bankName: data?.bankName || '',
        IFSCCode: data?.IFSCCode || '',
        bankAccountNo: data?.bankAccountNo || '',
        UPIID: data?.UPIID || '',
        accountHolderName: data?.accountHolderName || '',
        serviceTypeId: data?.serviceTypeId || '',
        serviceCost: data?.serviceCost || '',
        settlementType: data?.settlementType || '',
        country: data?.country || '',
        state: data?.state || '',
        district: data?.district || '',
        city: data?.city || '',
        area: data?.area || '',
        street: data?.street || '',
        houseOrPlotNo: data?.houseOrPlotNo || '',
        zipcode: data?.zipcode || '',
        adminType: data?.adminType || '',
    };

    const validationSchema = Yup.object().shape({
        vendorType: Yup.string().required('Please select vendor type'),
        vendorLegalName: Yup.string().required('Please enter legal name'),
        registrationNumber: Yup.string().required('Please enter registration number'),
        firstName: Yup.string().required('First Name Required'),
        lastName: Yup.string().required('Last Name Required'),
        email: Yup.string().required('Email is required').email('Invalid email address'),
        mobileNumber: Yup.string().required('Mobile Number Required'),
        password: isEdit ? Yup.string().optional() : Yup.string().required('Please enter password'),
        serviceTypeId: Yup.string().required('Please select service type'),
        settlementType: Yup.string().required('Please select settlement type'),
        adminType: Yup.string().required('Please select admin type'),
    });

    const serviceTypes = useSelector(state => state.vendor.serviceTypes);

    const browseProfilePic = async (e, form, field) => {
        setProfilePicName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
        const fileData = e.target.files[0];
        const base64 = await convertBase64(fileData);
        setPreviewImg(base64);
    };

    const browseLegalDoc = (e, form, field) => {
        setLegalDocName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const handleSubmit = async (values) => {
        try {
            const formData = objectToFormData(values);
            let res = isEdit ? await updateVendor(data.userId, formData) : await createVendor(formData);
            if (res?.status === 'success') {
                Swal.fire(res?.message, '', 'success');
                if (location.pathname === '/admin/vendors') {
                    closeAdding();
                    refresh();
                }
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    useEffect(() => {
        if (!serviceTypes) {
            getServiceTypes();
        }
    }, [serviceTypes]);

    return (
        <div className='admin-area-2'>
            <div className='page-header'>
                {
                    (location.pathname === '/admin/vendors') ?
                        <>
                            <IconButton onClick={() => closeAdding()}><ArrowBackIcon /></IconButton>
                            <Typography variant='h5'>{isEdit ? 'Edit' : 'Add'} Vendor</Typography>
                        </>
                        : <Typography variant='h5'>Update Profile</Typography>
                }
            </div>
            <div className='page-content'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Paper>
                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Basic Info :</Typography>
                                <Divider />
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='vendorType'
                                                name='vendorType'
                                                label='Vendor Type'
                                                options={vendorTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='vendorType'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required={true}
                                                size='small'
                                                name='vendorLegalName'
                                                id='vendorLegalName'
                                                label='Vendor Legal Name'
                                                placeholder='Vendor Legal Name'
                                                helperText={<ErrorMessage name='vendorLegalName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Vendor Logo/Images:</Typography>
                                            <Field name='profilePicNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='image/png, image/gif, image/jpeg'
                                                                name={field.name}
                                                                hidden={true}
                                                                onChange={(e) => browseProfilePic(e, form, field)}
                                                            />
                                                            Chose File
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{profilePicName ? profilePicName : 'No file selected'}</Typography>
                                            {previewImg && <div><img style={{ maxWidth: '150px' }} src={previewImg} alt='profile pic' /></div>}
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Legal Document:</Typography>
                                            <Field name='legalDocNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='application/pdf'
                                                                name={field.name}
                                                                hidden
                                                                onChange={(e) => browseLegalDoc(e, form, field)}
                                                            />
                                                            Chose File
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{legalDocName ? legalDocName : 'No file selected'}</Typography>
                                            {data?.legalDoc && <div><a href={data?.legalDoc}>{data?.legalDoc.split('/').pop()}</a></div>}
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>&nbsp;</Typography>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required={true}
                                                size='small'
                                                name='registrationNumber'
                                                id='registrationNumber'
                                                label='Registration Number'
                                                placeholder='Registration Number'
                                                helperText={<ErrorMessage name='registrationNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Service Hour:</Typography>
                                            <Grid container sx={{ m: -1 }}>
                                                <Grid item md='6' sm='6' xs='12' sx={{ ml: -1 }}>
                                                    <Field name='serviceStartTime'>
                                                        {({ field, form }) => (
                                                            <TimePicker
                                                                name={field.name}
                                                                selectedTime={serviceStartTime}
                                                                onChange={(date) => {
                                                                    form.setFieldValue(field.name, format(new Date(date), 'HH:mm:00'));
                                                                    setServiceStartTime(date);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name='serviceStartTime' component='div' />
                                                </Grid>
                                                <Grid item md='6' sm='6' xs='12' sx={{ ml: -1 }}>
                                                    <Field name='serviceEndTime'>
                                                        {({ field, form }) => (
                                                            <TimePicker
                                                                name={field.name}
                                                                selectedTime={serviceEndTime}
                                                                onChange={(date) => {
                                                                    form.setFieldValue(field.name, format(new Date(date), 'HH:mm:00'));
                                                                    setServiceEndTime(date);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name='serviceEndTime' component='div' />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field name='doHomeCollection'>
                                                {({ form, field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                color='primary'
                                                                checked={field.value}
                                                                onChange={(e) => {
                                                                    form.setFieldValue(field.name, e.target.checked ? 1 : 0);
                                                                }}
                                                            />
                                                        }
                                                        label='We will do the home collection'
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Bank Info :</Typography>
                                <Divider />
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='bankName'
                                                id='bankName'
                                                label='Bank Name'
                                                placeholder='Bank Name'
                                                helperText={<ErrorMessage name='bankName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='IFSCCode'
                                                id='IFSCCode'
                                                label='IFSC Code'
                                                placeholder='IFSC Code'
                                                helperText={<ErrorMessage name='IFSCCode' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='bankAccountNo'
                                                id='bankAccountNo'
                                                label='Bank Account No.'
                                                placeholder='Bank Account No.'
                                                helperText={<ErrorMessage name='bankAccountNo' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='UPIID'
                                                id='UPIID'
                                                label='UPI Id'
                                                placeholder='UPI Id'
                                                helperText={<ErrorMessage name='UPIID' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='accountHolderName'
                                                id='accountHolderName'
                                                label='Account Holder Name'
                                                placeholder='Account Holder Name'
                                                helperText={<ErrorMessage name='accountHolderName' />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Treatu Service Tax :</Typography>
                                <Divider />
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='serviceTypeId'
                                                name='serviceTypeId'
                                                label='Treatu Service Type'
                                                options={serviceTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='serviceTypeId'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required={true}
                                                size='small'
                                                name='serviceCost'
                                                id='serviceCost'
                                                label='Treatu Service Cost'
                                                placeholder='Treatu Service Cost'
                                                helperText={<ErrorMessage name='serviceCost' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='settlementType'
                                                name='settlementType'
                                                label='Settlement Type'
                                                options={settlementTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='settlementType'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Vendor Location :</Typography>
                                <Divider />
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='country'
                                                id='country'
                                                label='Country'
                                                placeholder='Country'
                                                helperText={<ErrorMessage name='country' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='state'
                                                id='state'
                                                label='State'
                                                placeholder='State'
                                                helperText={<ErrorMessage name='state' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='district'
                                                id='district'
                                                label='District'
                                                placeholder='District'
                                                helperText={<ErrorMessage name='district' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='city'
                                                id='city'
                                                label='City'
                                                placeholder='City'
                                                helperText={<ErrorMessage name='city' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='area'
                                                id='area'
                                                label='Area'
                                                placeholder='Area'
                                                helperText={<ErrorMessage name='area' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='street'
                                                id='street'
                                                label='Street'
                                                placeholder='Street'
                                                helperText={<ErrorMessage name='street' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='houseOrPlotNo'
                                                id='houseOrPlotNo'
                                                label='House No / Plot No'
                                                placeholder='House No / Plot No'
                                                helperText={<ErrorMessage name='houseOrPlotNo' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='zipcode'
                                                id='zipcode'
                                                label='Zip Code'
                                                placeholder='Zip Code'
                                                helperText={<ErrorMessage name='zipcode' />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Typography variant='h6' sx={{ p: 2, pl: 3 }}>Vendor Admin :</Typography>
                                <Divider />
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='md'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='firstName'
                                                id='firstName'
                                                label='First Name'
                                                placeholder='First Name'
                                                helperText={<ErrorMessage name='firstName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                size='small'
                                                name='middleName'
                                                id='middleName'
                                                label='Middle Name'
                                                placeholder='Middle Name'
                                                helperText={<ErrorMessage name='middleName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='lastName'
                                                id='lastName'
                                                label='Last Name'
                                                placeholder='Last Name'
                                                helperText={<ErrorMessage name='lastName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required={true}
                                                fullWidth
                                                size='small'
                                                id='mobileNumber'
                                                name='mobileNumber'
                                                label='Mobile Number'
                                                placeholder='Mobile number'
                                                helperText={<ErrorMessage name='mobileNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required={true}
                                                disabled={isEdit}
                                                fullWidth
                                                size='small'
                                                name='email'
                                                label='Email'
                                                id='email'
                                                placeholder='Email address'
                                                helperText={<ErrorMessage name='email' />}
                                            />
                                        </Grid>
                                        {
                                            !isEdit && <Grid item md='6' sm='6' xs='12'>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    required={true}
                                                    size='small'
                                                    name='password'
                                                    type='password'
                                                    label='Password'
                                                    placeholder='********'
                                                    helperText={<ErrorMessage name='password' />}
                                                />
                                            </Grid>
                                        }
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='gender'
                                                name='gender'
                                                label='Gender'
                                                options={genders}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='gender'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='adminType'
                                                name='adminType'
                                                label='Vendor Admin Type'
                                                options={adminTypes}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='adminType'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 1 }} onClick={() => closeAdding()}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                                        {isSubmitting && <CgSpinner size={20} className='mt-1 animate-spin' />} Save
                                    </Button>
                                </Box>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

AddVendor.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    closeAdding: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default AddVendor;


