import * as vendorTypes from '../types/vendortypes';
const initialVendorState = {
    vendorTypes: undefined,
    serviceTypes: undefined,
    departments: undefined,
    qualifications: undefined,
    loading: false
};

export default function vendorReducer(state = initialVendorState, action) {
    switch (action.type) {
        case vendorTypes.ON_VENDOR_TYPES_LOAD_SUCCESS:
            return {
                ...state,
                vendorTypes: action.payload?.body
            };
        case vendorTypes.ON_VENDOR_TYPES_LOAD_FAILURE:
            return {
                ...state,
                vendorTypes: action.payload
            };
        case vendorTypes.ON_VENDOR_SIGNUP_SUCCESS:
            return {
                ...state,
                vendorTypes: action.payload
            };
        case vendorTypes.ON_VENDOR_SIGNUP_FAILURE:
            return {
                ...state,
                vendorTypes: action.payload
            };
        case vendorTypes.ON_DEPARTMENT_TYPES_LOAD_SUCCESS:
            return {
                ...state,
                departments: action.payload
            };
        case vendorTypes.ON_DEPARTMENT_TYPES_LOAD_FAILURE:
            return {
                ...state,
                departments: []
            };
        case vendorTypes.ON_QUALIFICATIONS_LOAD_SUCCESS:
            return {
                ...state,
                qualifications: action.payload
            };
        case vendorTypes.ON_QUALIFICATIONS_LOAD_FAILURE:
            return {
                ...state,
                qualifications: []
            };
        case vendorTypes.ON_SERVICETYPES_LOAD_SUCCESS:
            return {
                ...state,
                serviceTypes: action.payload
            };
        case vendorTypes.ON_SERVICETYPES_LOAD_FAILURE:
            return {
                ...state,
                serviceTypes: []
            };
        default:
            return state;
    }
}