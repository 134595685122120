import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  Grid,
  Avatar,
  MenuItem,
  FormControl,
  Button,
} from '@mui/material';
import './doctorscriteria.css';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';
import { validateRequired } from '../../../utils/common';
import { removeEmptyProperties } from '../../../utils/utils';
import { useCallback } from 'react';

function DocotorsCriteria() {
  const navigate = useNavigate();
  const { criteria } = useParams();
  const [formData, setFormData] = useState({ departmentId: criteria });
  const [errors, setErrors] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const formSubmitted = useRef(false);

  const preferences = [
    { value: '', label: '--   Your Preference   --' },
    { value: '0', label: 'Personal Visit' },
    { value: '1', label: 'Teleconsultation' },
  ];

  const requiredFields = [];

  const setFormValue = (field, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, [field]: value };
      if (formSubmitted.current) {
        setErrors(validateRequired(requiredFields, updatedData));
      }
      return updatedData;
    });
  };

  const viewDoctorDetails = (doctorId) => {
    navigate(`/finddoctors/${criteria}/${doctorId}`);
  };

  const handleSearch = async () => {
    formSubmitted.current = true;
    const errors = validateRequired(requiredFields, formData);
    if (errors) {
      setErrors(errors);
      return;
    }
    await getDoctors();
  };

  const getDepartmentList = useCallback(async () => {
    try {
      const url = APIUtils.getAPIEndpoint('departments');
      const { data } = await httpClient.get(url).then((res) => res.data);
      setDepartmentList(data);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setDoctors([]);
    }
  }, []);

  const getDoctors = useCallback(async () => {
    try {
      const url = APIUtils.getAPIEndpoint('doctors');
      const params = removeEmptyProperties(formData);
      const res = await httpClient.get(url, { params }).then((res) => res.data);
      setDoctors(res.status === 'success' ? res.data : []);
    } catch (error) {
      console.error('Error fetching doctors:', error);
      setDoctors([]);
    }
  }, [formData]);

  useEffect(() => {
    getDepartmentList();
    getDoctors();
  }, [getDepartmentList, getDoctors]);

  return (
    <Container className="doc-cat-content">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <TextField
              label="Department"
              value={formData.departmentId || ''}
              size="small"
              select
              onChange={(e) => setFormValue('departmentId', e.target.value)}
              error={!!errors.departmentId}
              helperText={errors.departmentId && 'This field is required.'}
            >
              <MenuItem value="">Select Department</MenuItem>
              {departmentList?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <TextField
              label="--   Your Preference   --"
              value={formData.preference || ''}
              size="small"
              select
              onChange={(e) => setFormValue('preference', e.target.value)}
              error={!!errors.preference}
              helperText={errors.preference && 'This field is required.'}
            >
              {preferences.map((preference) => (
                <MenuItem key={preference.value} value={preference.value}>
                  {preference.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Keyword"
            variant="outlined"
            fullWidth
            size="small"
            value={formData.keyword || ''}
            onChange={(e) => setFormValue('keyword', e.target.value)}
            error={!!errors.keyword}
            helperText={errors.keyword && 'This field is required.'}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" fullWidth onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={3}>
        {doctors.map((doctor, index) => {
          const { firstName, middleName, lastName, experience, personalVisitPrice, serviceName, profilePic, qualifications } = doctor;
          const name = [firstName, middleName, lastName].filter(Boolean).join(' ');
          return (
            <Grid key={'doctor-' + index} item xs={12} sm={6}>
              <Box className="doc-criteria">
                <Avatar className="doc-avatar" alt="Doctors Photo" src={profilePic} variant="square" />
                <Box className="doc-details">
                  <Typography className="medium-dark-txt">Dr. {name}</Typography>
                  <Typography className="small-yellow-txt">{doctor.department?.designation} | {experience} Years of Experience</Typography>
                  <Typography className="small-dark-txt">{qualifications}</Typography>
                  <Typography className="small-dark-brown-txt">{serviceName}</Typography>
                  <Box className="space-between">
                    <Typography className="medium-brown-txt">Rs. {personalVisitPrice}</Typography>
                    <Button
                      variant="outlined"
                      className="btn-square btn-small"
                      onClick={() => viewDoctorDetails(doctor.userId)}>
                      View
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default DocotorsCriteria;
