import React, { useNavigate } from 'react-router-dom';
import './servicespersurgery.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { surgeryServices } from './constants';
function ServicesPerSurgery() {
	const navigate = useNavigate();
	const handleNavigation = (id) => {
		navigate('/surgerybookings/' + id);
	};
	return (
		<Container className='doc-cat-content'>
			<Box>
				<Typography className='ext-large-brown-txt'>Services for</Typography>
			</Box>
			<Grid container spacing={2}>
				{surgeryServices.map(({ id, label }) => (
					<Grid key={id} item xs={12} sm={3}>
						<Box key={id} className='doc-category' onClick={() => handleNavigation(id)}>
							<Typography className='small-black-txt'>{label}</Typography>
						</Box>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
export default ServicesPerSurgery;
