import * as categoryTypes from '../types/categorytypes';
const initialCategoryState = {
    result: {},
    uploadImageResult: {},
    createCategoryResult: {},
    loading: false
};

export default function categoryReducer(state = initialCategoryState, action) {
    switch (action.type) {
        case categoryTypes.ON_CATEGORY_IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                uploadImageResult: action.payload
            };
        case categoryTypes.ON_CATEGORY_IMAGE_UPLOAD_FAILURE:
            return {
                ...state,
                uploadImageResult: action.payload
            };

        default:
            return state;
    }
}