import React, { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import labtest from '../../../assets/logos/prescription/labtest.png';
import ordermedicine from '../../../assets/logos/prescription/ordermedicine.png';
import Button from '@mui/material/Button';

function WithPrescription() {
	const navigate = useNavigate();
	const onServiceClicked = (label) => {
		switch (label) {
			case 'Consult a Doctor':
				navigate('/finddoctors');
				break;
			default:
			// code block
		}
	};

	return (
		<Container>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<Box className='labtest-with-prescription' onClick={() => onServiceClicked('2')}>
						<Box>
							<Avatar
								sx={{ width: 60, height: 60 }}
								alt='Lab Test With Prescription'
								src={labtest}
								variant='square'
							/>
						</Box>
						&nbsp;&nbsp;&nbsp;
						<Box>
							<Typography className='ext-large-brown-txt' sx={{ margin: '0px !important' }}>Lab Test With Prescription</Typography>
							<Typography className='small-dark-brown-txt' sx={{ margin: '0px !important' }}>Upload Prescription and tell us what you need. We do the rest !</Typography>
							<br/>
							<Button variant='contained' className='small-brown-btn'>Go</Button>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box className='medicine-with-prescription' onClick={() => onServiceClicked('3')}>
						<Box>
							<Avatar
								sx={{ width: 60, height: 60 }}
								alt='Order Medicine with prescription'
								src={ordermedicine}
								variant='square'
							/>
						</Box>&nbsp;&nbsp;&nbsp;
						<Box>
							<Typography className='ext-large-brown-txt' sx={{ margin: '0px !important' }}>Order Medicine With Prescription</Typography>
							<Typography className='small-dark-brown-txt' sx={{ margin: '0px !important' }}>Upload Prescription to order medicine</Typography>
							<br/>
							<Button variant='contained' className='small-brown-btn'>Go</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
}

export default WithPrescription;
