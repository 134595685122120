import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navbar from './components/navigation/navbar/navbar';
import Footer from './components/footer/footer';

import { activeRoutes } from './utils/adminMenu';
import AdminDashboard from './pages/admin/admindashboard/admindashboard';
import AdminLogin from './pages/admin/adminlogin/adminlogin';
import AdminProfile from './pages/admin/adminprofile';
import AdminChangePassword from './pages/admin/changepassword';
import AddDoctor from './pages/admin/doctor/addoctor';

import Home from './pages/user/home/home';
import LogIn from './pages/user/login/login';
import SignUp from './pages/user/signup/signup';
import FindDoctors from './pages/user/finddoctors/finddoctors';
import UserProfile from './pages/user/profile/profile';
import Address from './pages/user/address';
import DoctorsCriteria from './pages/user/doctorscriteria/doctorscriteria';
import DoctorDetails from './pages/user/doctordetails/doctordetails';
import Medicine from './pages/user/medicine/medicine';
import MedicineDetails from './pages/user/medicinedetails/medicinedetails';
import BrowseBySurgeries from './pages/user/surgeries/browsebysurgery/browsesurgeries';
import ServicesPerSurgery from './pages/user/surgeries/servicespersurgery/servicespersurgery';
import BookSurgery from './pages/user/surgeries/booksurgery/booksurgery';
import ChangePassword from './pages/user/changepassword';
import LoginPopup from './pages/user/login/loginpopup';
import SignupPopup from './pages/user/signup/signuppopup';
import { closeLoginPopup, closeSignupPopup, loginSuccess } from './redux/actions/authActions';
import { encryptStorage } from './utils/storage';
import MyAppointmentList from './pages/user/myappointment';


function App() {
	const location = useLocation();
	const [path, setPath] = useState('');

	const openLogin = useSelector(state => state.auth.openLogin);
	const openSignup = useSelector(state => state.auth.openSignup);

	useEffect(() => {
		const user = encryptStorage.getItem('user-info');
		if (user) loginSuccess(user);
	}, []);

	useEffect(() => {
		setPath(location.pathname.includes('/admin') ? 'admin' : 'user');
	}, [location]);

	return (
		<div className='App'>
			<div className={`${path === 'admin' && 'flex-box'}`}>
				<Navbar />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/login' element={<LogIn />} />
					<Route path='/signup' element={<SignUp />} />
					<Route path='/finddoctors' element={<FindDoctors />} />
					<Route path='/finddoctors/:criteria' element={<DoctorsCriteria />} />
					<Route path='/finddoctors/:criteria/:doctorId' element={<DoctorDetails />} />
					<Route path='/medicine' element={<Medicine />} />
					<Route path='/medicinedetails/:id' element={<MedicineDetails />} />
					<Route path='/surgeries/' element={<BrowseBySurgeries />} />
					<Route
						path='/servicespersurgery/:id'
						element={<ServicesPerSurgery />}
					/>
					<Route path='/surgerybookings/:id' element={<BookSurgery />} />
					<Route path='/myprofile' element={<UserProfile />} />
					<Route path='/myappointments' element={<MyAppointmentList />} />
					<Route path='/myaddress' element={<Address />} />
					<Route path='/change-password' element={<ChangePassword />} />

					<Route path='/admin/login' element={<AdminLogin />} />
					<Route path='/admin' element={<AdminDashboard />} />
					<Route path='/admin/profile' element={<AdminProfile />} />
					<Route path='/admin/change-password' element={<AdminChangePassword />} />
					<Route path='/admin/doctors/:page/:userId?' element={<AddDoctor />} />
					{
						activeRoutes.map(ar => <Route key={ar.text} path={ar.path} element={<ar.component />} />)
					}
				</Routes>

				<LoginPopup open={openLogin} onClose={() => closeLoginPopup()} />
				<SignupPopup open={openSignup} onClose={() => closeSignupPopup()} />
			</div>
			<Footer />
		</div>
	);
}

export default App;
