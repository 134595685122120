import React, { useEffect, useState } from 'react';
import './address.css';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PropTypes from 'prop-types';
import httpClient from '../../../services/http';
import Swal from 'sweetalert2';
import { APIUtils } from '../../../utils/apiutils';

function Address({ setAddEdit, setCurrentAddress }) {
    const [addresses, setAddresses] = useState([]);

    const deleteAddress = (id, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this address.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = APIUtils.getAPIEndpoint('addresses');
                    const res = await httpClient.delete(url + '/' + id);
                    if (res?.data?.status === 'success') {
                        Swal.fire(res?.data?.message, '', 'success');
                        setAddresses(addresses.filter((_, i) => i !== index));
                    } else {
                        Swal.fire(res?.data?.message, '', 'failure');
                    }
                } catch (error) {
                    console.error('Error fetching addresses:', error);
                    setAddresses([]);
                }
            }
        });
    };

    const fetchAddresses = async () => {
        try {
            const url = APIUtils.getAPIEndpoint('addresses');
            const res = await httpClient.get(url);
            if (res.data.status === 'success') {
                setAddresses(res.data.data);
            } else {
                setAddresses([]);
            }
        } catch (error) {
            console.error('Error fetching addresses:', error);
            setAddresses([]);
        }
    };

    useEffect(() => {
        fetchAddresses();
    }, []);

    return (
        <Container component='main' maxWidth='lg' sx={{ mt: 3, mb: 3, textAlign: 'left' }}>
            <Paper elevation={3} className={'profile-grid'}>
                <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                    <Typography variant='h5' className={'padding-20'} sx={{ textAlign: 'left', flex: 1 }}>
                        My Address
                    </Typography>
                    <div>
                        <Button variant='contained' color='primary' className='btn-square' onClick={() => { setAddEdit(true); setCurrentAddress(null); }}>Add New</Button>
                    </div>
                </Box>
                <Divider />
                <Grid container sx={{ p: 4, pb: 6 }}>
                    {addresses.map((address, index) =>
                        <Grid item md={6} sm={12} xs={12} key={address.id}>
                            <Paper elevation={3}>
                                <Box sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
                                    <Typography variant='h5' sx={{ p: 1, pl: 3, flex: 1 }}>{address.type}</Typography>
                                    <div>
                                        <IconButton aria-label='Edit'>
                                            <EditOutlinedIcon onClick={() => { setAddEdit(true); setCurrentAddress(address); }} />
                                        </IconButton>
                                        <IconButton aria-label='Delete'>
                                            <DeleteOutlineOutlinedIcon onClick={() => deleteAddress(address.id, index)} />
                                        </IconButton>
                                    </div>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 3 }}>
                                    <Typography><b>Contact Person -</b> {address.contactPerson}</Typography>
                                    <Typography><b>Contact Number -</b> {address.contactNumber}</Typography>
                                    <Typography><b>State -</b> {address.state}</Typography>
                                    <Typography><b>City -</b> {address.city}</Typography>
                                    <Typography><b>Locality -</b> {address.locality}</Typography>
                                    <Typography><b>Address -</b> {address.address}</Typography>
                                    <Typography><b>Landmark -</b> {address.landmark}</Typography>
                                    <Typography><b>Pin code -</b> {address.pincode}</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Container>
    );
}

Address.propTypes = {
    setAddEdit: PropTypes.func.isRequired,
    setCurrentAddress: PropTypes.func.isRequired,
};

export default Address;