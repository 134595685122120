import product from '../../../assets/images/product_image.svg';

const productDetails = {
	name: '20 Microns Herbal Ashwagandha Extract Capsule 60\'s (India)',
	description: '20 Microns Herbal Ashwagandha Extract Capsule is formulated with ayurvedic herb Ashwagandha which has various health benefits.It is enriched with antioxidants and helps in improving immunity.It helps in improving vigour and vitality and has a nourishing effect on the nervous system. Painful swelling can also be relieved by its usage.',
	image: product,
	benefits: 'Promotes male sexual function. Encourages more restful sleep. Helps improve memory and cognition. Enhanced energy and stamina. Helps in improving strength and muscle recovery',
	usage: '1 capsule in the morning, 1 capsule in the evening after Meal, with milk or water or as directed by a physician.',
	safetyAdvice: 'To be taken under medical supervision Keep in cool and dry place.',
};
export { productDetails };
