// LoginPopup.jsx
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Link, Grid, Box, Typography, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Swal from 'sweetalert2';
import { encryptStorage } from '../../../utils/storage';
import { closeLoginPopup, loginSuccess, openSignupPopup } from '../../../redux/actions/authActions';
import { USER_ROLES } from '../../../utils/constants';
import PropTypes from 'prop-types';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

const styles = {
    dialogTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    titleText: {
        textAlign: 'center',
        fontWeight: 'bold',
        flex: 1
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    formContainer: {
        padding: '0px 25px'
    },
    signUpText: {
        padding: '10px 0px 20px 0px',
        cursor: 'pointer'
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center'
    },
    errorText: {
        color: 'red'
    }
};

const LoginPopup = ({ open, onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const handleLogin = async (values) => {
        try {
            setLoading(true);
            const { data: res } = await httpClient.post(APIUtils.getAPIEndpoint('log-in'), values);
            if (res?.status === 'success') {
                closeLoginPopup();
                encryptStorage.setItem('user-info', res.data);
                encryptStorage.setItem('user-token', res.data.token);
                loginSuccess(res?.data);
                if ([USER_ROLES.SUPER_ADMIN, USER_ROLES.VENDOR, USER_ROLES.DOCTOR].includes(res.data.role)) {
                    navigate('/admin');
                } else if (location.pathname === '/') {
                    navigate('/myprofile');
                }
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            Swal.fire(error, '', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
            <Formik
                initialValues={initialValues}
                onSubmit={handleLogin}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <DialogTitle sx={styles.dialogTitle}>
                            <Typography sx={styles.titleText} className='brown-md-txt'>Login</Typography>
                            <IconButton onClick={onClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={styles.dialogContent}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            id='email'
                                            label='Email'
                                            name='email'
                                            type='email'
                                            fullWidth
                                            required
                                            autoFocus={false}
                                            size='small'
                                            helperText={<ErrorMessage name='email' component="div" style={styles.errorText} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name='password'
                                            label='Password'
                                            type='password'
                                            id='password'
                                            fullWidth
                                            required
                                            size='small'
                                            helperText={<ErrorMessage name='password' component="div" style={styles.errorText} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={styles.dialogActions}>
                            <Grid container spacing={3} sx={styles.formContainer}>
                                <Grid item xs={12}>
                                    <Button type='submit' disabled={loading} fullWidth variant='contained' color='primary'>Login</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        <Grid container justifyContent='center' sx={{ marginBottom: '20px' }}>
                            <Grid item>
                                Don&apos;t have an account?&nbsp;
                                <Link onClick={() => openSignupPopup()} variant='body2' sx={styles.signUpText}>
                                    SignUp Now
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

LoginPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default LoginPopup;
