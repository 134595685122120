import product from '../../../assets/images/product_image.svg';

/* eslint-disable */
const products = [
	{
		name: `20 Microns Herbal Ashwagandha Extract Capsule 60's`,
		price: 400,
		image: product,
	},
	{
		name: `20 Microns Herbal Ashwagandha Extract Capsule 60's`,
		price: 500,
		image: product,
	},
	{
		name: `20 Microns Herbal Ashwagandha Extract Capsule 60's`,
		price: 600,
		image: product,
	},
	{
		name: `20 Microns Herbal Ashwagandha Extract Capsule 60's`,
		price: 700,
		image: product,
	},
	{
		name: `20 Microns Herbal Ashwagandha Extract Capsule 60's`,
		price: 700,
		image: product,
	},
];
/* eslint-disable */

export { products };
