import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import './custompagination.css';

const List = styled('ul')({
	listStyle: 'none',
	padding: 0,
	margin: 0,
	display: 'flex',
});

export default function CustomPagination() {
	const { items } = usePagination({
		count: 4,
	});

	return (
		<nav>
			<List>
				{items.map(({ page, type, selected, ...item }, index) => {
					console.log(selected);
					let children = null;
					if (type === 'start-ellipsis' || type === 'end-ellipsis') {
						children = '…';
					} else if (type === 'page') {
						children = (
							<Button
								variant='outlined'
								className='pagination-btn-small'
								{...item}
							>
								{page}
							</Button>
						);
					} else {
						children = (
							<Button variant='outlined' className='btn-square' {...item}>
								{type}
							</Button>
						);
					}

					return <li key={index}>{children}</li>;
				})}
			</List>
		</nav>
	);
}
