import { encryptStorage } from '../../utils/storage';

export default function useInfo() {
    const userInfo = encryptStorage.getItem('user-info');
    if (userInfo) {
        return userInfo;
    } else {
        return undefined;
    }
}
