import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import { Divider, IconButton } from '@mui/material';
import { validateRequired } from '../../../utils/common';
import Swal from 'sweetalert2';
import httpClient from '../../../services/http';
import PropTypes from 'prop-types';
import { testMasterCategories, testSubCategories } from '.';
import { APIUtils } from '../../../utils/apiutils';

const defaultValue = { status: 'active' };
const requiredFields = ['servicetype', 'category', 'servicename', 'serviceprice', 'description', 'status'];

export function AddEditTest(props) {
    const { open, setOpen, isEdit, data, refresh } = props;
    const [formData, setFormData] = useState(defaultValue);
    const [errors, setErrors] = useState({});
    const formSubmitted = useRef(false);

    useEffect(() => {
        setErrors({});
        formSubmitted.current = false;
        setFormData(isEdit ? data : defaultValue);
    }, [data, isEdit]);

    const handleClose = () => {
        setOpen(false);
    };

    const setFormValue = (field, value) => {
        const tmp = { ...formData, [field]: value };
        setFormData(tmp);
        if (formSubmitted.current) {
            const errors = validateRequired(requiredFields, tmp);
            setErrors(errors);
        }
    };

    const handleSave = async () => {
        formSubmitted.current = true;
        const errors = validateRequired(requiredFields, formData);
        if (errors) {
            setErrors(errors); return;
        }
        Swal.showLoading();
        try {
            let res;
            if (isEdit) {
                res = await httpClient.post(APIUtils.getAdminAPIEndpoint('edittest'), formData).then(res => res.data);
            } else {
                res = await httpClient.post(APIUtils.getAdminAPIEndpoint('addtest'), formData).then(res => res.data);
            }
            console.log(res);
            if (res.status === 'success') {
                Swal.fire(res.message, '', 'success');
                refresh();
                handleClose();
            } else {
                Swal.fire(res.message, '', 'error');
            }
        } catch (e) {
            console.error(e);
            Swal.fire('Something Went Wrong!', '', 'success');
        } finally {
            Swal.hideLoading();
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    {open}
                </div>
                <IconButton onClick={handleClose} size='small'>
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container className='profile-grid'>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            label='Master Service Category'
                            value={formData?.servicetype || ''}
                            fullWidth
                            size='small'
                            select
                            onChange={e => setFormValue('servicetype', e.target.value)}
                            {...(errors.servicetype ? { error: true, helperText: 'This field is required.' } : {})}
                        >
                            {testMasterCategories.map(sm => <MenuItem key={`sm-menu-${sm.value}`} value={sm.value} name={sm.label}>{sm.label}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            label='Category'
                            value={formData?.category || ''}
                            fullWidth
                            size='small'
                            select
                            onChange={e => setFormValue('category', e.target.value)}
                            {...(errors.category ? { error: true, helperText: 'This field is required.' } : {})}
                        >
                            {testSubCategories.map(sm => <MenuItem key={`sm-menu-${sm.value}`} value={sm.value} name={sm.label}>{sm.label}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            name='Outlined'
                            label='Service Name'
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={formData?.servicename}
                            onChange={e => setFormValue('servicename', e.target.value)}
                            {...(errors.servicename ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            label='Service Price (INR)'
                            type='number'
                            value={formData?.serviceprice}
                            onChange={e => setFormValue('serviceprice', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.serviceprice ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            label='Description'
                            multiline
                            rows={4}
                            value={formData?.description}
                            onChange={e => setFormValue('description', e.target.value)}
                            fullWidth
                            size='small'
                            {...(errors.description ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item sm='12' md='12' xs='12'>
                        <TextField
                            label='Status'
                            value={formData?.status || ''}
                            fullWidth
                            size='small'
                            select
                            onChange={e => setFormValue('status', e.target.value)}
                            {...(errors.status ? { error: true, helperText: 'This field is required.' } : {})}
                        >
                            <MenuItem value={'active'}>Active</MenuItem>
                            <MenuItem value={'in_active'}>In-Active</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <FormControl>
                    <Button size='small' variant='outlined' color='secondary' onClick={handleClose}>Cancel</Button>
                </FormControl>
                <FormControl >
                    <Button size='small' variant='contained' color='primary' onClick={handleSave}>Save</Button>
                </FormControl>
            </DialogActions>
        </Dialog>
    );
}

AddEditTest.propTypes = {
    open: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    data: PropTypes.object,
};