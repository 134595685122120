
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getFieldExecutives = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('fieldexecutive-list')).then(res => res.data.data);
};

export const createFieldExecutive = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('create-fieldexecutive'), payload).then(res => res.data);
};

export const updateFieldExecutive = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('update-fieldexecutive'), payload).then(res => res.data);
};

export const deleteFieldExecutive = (id) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id }).then(res => res.data);
};