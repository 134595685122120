
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export const getDoctors = () => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('doctor')).then(res => res.data.data);
};

export const createDoctor = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('doctor'), payload).then(res => res.data);
};

export const updateDoctor = (id, payload) => {
    return httpClient.put(APIUtils.getAdminAPIEndpoint('doctor') + `/${id}`, payload).then(res => res.data);
};

export const deleteDoctor = (id) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id }).then(res => res.data);
};

export const importDoctors = (payload) => {
    return httpClient.post(APIUtils.getAdminAPIEndpoint('import-doctor'), payload).then(res => res.data);
};

export const getDoctorDetails = (id) => {
    return httpClient.get(APIUtils.getAdminAPIEndpoint('doctor') + '/' + id).then(res => res.data.data);
};