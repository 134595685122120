/* eslint-disable */
import consult_a_doctor from '../../../assets/images/consult_a_doctor.svg';
import book_dialysis from '../../../assets/images/book_dialysis.svg';
import book_checkup from '../../../assets/images/book_checkup.svg';
import advanced_surgery from '../../../assets/images/advanced_surgery.svg';
import emergency_assistance from '../../../assets/images/emergency_assistance.svg';
import medicine_online from '../../../assets/images/medicine_online.svg';
import other_tests from '../../../assets/images/other_tests.svg';
import pathology_test from '../../../assets/images/pathology_test.svg';
import treat_at_home from '../../../assets/images/treat_at_home.svg';
import xray_service from '../../../assets/images/xray_service.svg';
import abhimanyu from '../../../assets/images/abhimanyu.svg';
import vikash from '../../../assets/images/vikash.svg';
import trushna from '../../../assets/images/trushna.svg';
import tapas from '../../../assets/images/tapas.svg';

const serviceImages = [
	{ label: 'Consult a Doctor', src: consult_a_doctor },
	{ label: 'Pathology Test', src: pathology_test },
	{ label: 'Xray/CT/MRI/Ultrasound', src: xray_service },
	{ label: 'Other Tests', src: other_tests },
	{ label: 'Medicine Online', src: medicine_online },
	{ label: 'Book Dialysis', src: book_dialysis },
	{ label: 'Treat at Home', src: treat_at_home },
	{ label: 'Book your Health Checkup', src: book_checkup },
	{ label: 'Book For Advanced Surgery', src: advanced_surgery },
	{ label: 'Emergency Assistance', src: emergency_assistance },
];
/* eslint-disable */

const doctors = [
	{
		name: 'Dr. ABHIMANYU ACHARYA',
		designation: 'Consultant Ophthalmologist',
		audioConsultation: 400,
		videoConsultation: 500,
		image: abhimanyu,
	},
	{
		name: 'Dr. VIKASH AGRAWAL',
		designation: 'CONSULTANT ORTHOPAEDICS',
		audioConsultation: 400,
		videoConsultation: 500,
		image: vikash,
	},
	{
		name: 'Dr. TRUSHNA MOHANTY',
		designation: 'CONSULTANT GYNAECOLOGIST',
		audioConsultation: 400,
		videoConsultation: 500,
		image: trushna,
	},
	{
		name: 'Dr. TAPAS MISHRA',
		designation: 'Consultant Bariatric Surgery',
		audioConsultation: 400,
		videoConsultation: 500,
		image: tapas,
	},
];

export { serviceImages, doctors };
