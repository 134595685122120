import cardiology from '../../../../assets/logos/department/cardiology.png';
import dental from '../../../../assets/logos/department/dental.png';
import ctvs from '../../../../assets/logos/department/ctvssurgery.png';
import ent from '../../../../assets/logos/department/ent.png';
import general from '../../../../assets/logos/department/generalsurgery.png';
import neuro from '../../../../assets/logos/department/neuro.png';
import opthalmology from '../../../../assets/logos/department/opthalmology.png';
import urology from '../../../../assets/logos/department/urology.png';
import ortho from '../../../../assets/logos/department/orthopaedics.png';

const surgeries = [
	{ label: 'Cardiology', src: cardiology, id: 1 },
	{ label: 'Ctvs Surgery', src: ctvs, id: 2 },
	{ label: 'Ent', src: ent, id: 3 },
	{ label: 'General Surgery', src: general, id: 4 },
	{ label: 'Interventional Neurology', src: cardiology, id: 5 },
	{ label: 'Neuro & Spine', src: neuro, id: 6 },
	{ label: 'O&g', src: dental, id: 7 },
	{ label: 'Opthalmology', src: opthalmology, id: 8 },
	{ label: 'Orthopaedics', src: ortho, id: 9 },
	{ label: 'Urology', src: urology, id: 10 },
];

export { surgeries };