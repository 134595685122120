import * as adminTypes from '../types/admintypes';

const initialAdminState = {
    loading: 0,
    departments: undefined,
    qualifications: undefined,
    servicetypes: undefined,
};

export default function adminReducer(state = initialAdminState, action) {
    switch (action.type) {
        case adminTypes.ON_ADMIN_DATA_LOADING_STARTED:
            return {
                ...state,
                loading: state.loading + 1
            };
        case adminTypes.ON_ADMIN_DATA_LOADING_FINISHED:
            return {
                ...state,
                loading: state.loading - 1
            };
        case adminTypes.ON_DEPARTMENTS_LOAD_SUCCESS:
            return {
                ...state,
                departments: action.payload
            };
        case adminTypes.ON_QUALIFICATIONS_LOAD_SUCCESS:
            return {
                ...state,
                qualifications: action.payload
            };
        case adminTypes.ON_SERVICETYPES_LOAD_SUCCESS:
            return {
                ...state,
                servicetypes: action.payload
            };
        default:
            return state;
    }
}