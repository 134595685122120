import React from 'react';
import './style.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Grid, Button, TextField, Box, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { createServiceType, updateServiceType } from './service';
import { isActiveStatus } from '../../../utils/constants';
import CustomDropdown from '../../../components/customdropdown';
import PropTypes from 'prop-types';

function AddEditServiceType(props) {
    const { open, setOpen, data, refresh } = props;

    const initialValues = {
        name: data?.name || '',
        isActive: !data?.isActive ? 0 : 1
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please enter name'),
        isActive: Yup.number()
            .oneOf(['', 0, 1], 'Please select status')
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (values) => {
        try {
            let res;
            if (!data) {
                values.isActive = 1;
                res = await createServiceType(values);
            } else {
                values.id = data.id;
                res = await updateServiceType(values);
            }
            if (res?.status === 'success') {
                Swal.fire(res?.message, '', 'success');
                handleClose();
                refresh();
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    return (
        <Dialog open={open} maxWidth='xs' fullWidth>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {data ? 'Update' : 'Add'} ServiceType
                            </div>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className='page-content'>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        required
                                        fullWidth
                                        size='small'
                                        name='name'
                                        id='name'
                                        label='Name'
                                        placeholder='ServiceType Name'
                                        helperText={<ErrorMessage name='name' />}
                                    />
                                </Grid>
                                {
                                    data &&
                                    <Grid item xs={12}>
                                        <Field
                                            required
                                            id='isActive'
                                            name='isActive'
                                            label='Status'
                                            options={isActiveStatus}
                                            component={CustomDropdown}
                                        />
                                        <ErrorMessage name='isActive'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{ textAlign: 'right', p: 2 }}>
                                <Button variant='outlined' color='secondary' sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
                                <Button type='submit' variant='contained' color='primary'>Save</Button>
                            </Box>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

AddEditServiceType.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    data: PropTypes.object,
    refresh: PropTypes.func.isRequired
};

export default AddEditServiceType;
