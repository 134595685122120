import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, TextField, Typography, Box, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { createDepartment, updateDepartment } from './service';
import { isActiveStatus } from '../../../utils/constants';
import CustomDropdown from '../../../components/customdropdown';
import { convertBase64, objectToFormData } from '../../../utils/utils';
import PropTypes from 'prop-types';

const AddEditDepartment = ({ open, setOpen, data, refresh }) => {
    const inputImageRef = useRef();
    const [imageName, setImageName] = useState('');
    const [previewImage, setPreviewImage] = useState('');

    const initialValues = useMemo(() => ({
        name: data?.name || '',
        designation: data?.designation || '',
        image: data?.image || '',
        description: data?.description || '',
        isActive: data ? (data.isActive ? 1 : 0) : 1
    }), [data]);

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string().required('Please enter name'),
        designation: Yup.string().required('Please enter designation'),
        imageNew: data ? Yup.string().optional() : Yup.string().required('Please browse image'),
        isActive: Yup.number().oneOf([0, 1], 'Please select status')
    }), [data]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const browseImage = useCallback(async (e, form, field) => {
        const file = e.currentTarget.files[0];
        setImageName(file.name);
        form.setFieldValue(field.name, file);
        setPreviewImage(await convertBase64(file));
    }, []);

    const handleSubmit = useCallback(async (values) => {
        try {
            const formData = objectToFormData(values);
            const res = data ? await updateDepartment(data.id, formData) : await createDepartment(formData);

            if (res?.status === 'success') {
                Swal.fire(res.message, '', 'success');
                handleClose();
                refresh();
            } else {
                Swal.fire(res.message, '', 'error');
            }
        } catch (error) {
            Swal.fire(error.response?.data?.message || 'Some error occurred!', '', 'error');
        }
    }, [data, handleClose, refresh]);

    useEffect(() => {
        if (data?.image) {
            setPreviewImage(data.image);
            setImageName(data.image.split('/').pop());
        } else {
            setPreviewImage('');
            setImageName('');
        }
    }, [data]);

    return (
        <Dialog open={open} maxWidth='xs' fullWidth>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ flex: 1 }}>
                            {data ? 'Update' : 'Add'} Department
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    fullWidth
                                    size='small'
                                    name='name'
                                    label='Name'
                                    placeholder='Department Name'
                                    helperText={<ErrorMessage name='name' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    fullWidth
                                    size='small'
                                    name='designation'
                                    label='Designation'
                                    placeholder='Designation'
                                    helperText={<ErrorMessage name='designation' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field name='imageNew'>
                                    {({ field, form }) => (
                                        <Box
                                            className='fileUploadArea'
                                            sx={{ borderColor: !imageName ? '#ff0000' : '' }}
                                        >
                                            <Button color='secondary' onClick={() => inputImageRef.current.click()}>
                                                <FileUploadOutlinedIcon />
                                                <input
                                                    type='file'
                                                    accept='image/png, image/gif, image/jpeg'
                                                    name={field.name}
                                                    hidden
                                                    ref={inputImageRef}
                                                    onChange={(e) => browseImage(e, form, field)}
                                                />
                                            </Button>
                                            <Typography>{imageName || 'Choose File'}</Typography>
                                        </Box>
                                    )}
                                </Field>
                                {previewImage && <img style={{ maxWidth: '100px', marginTop: '5px' }} src={previewImage} alt='profile pic' />}
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    fullWidth
                                    size='small'
                                    name='description'
                                    label='Description'
                                    placeholder='Description'
                                    multiline
                                    rows={4}
                                    helperText={<ErrorMessage name='description' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='isActive'
                                    label='Status'
                                    options={isActiveStatus}
                                    component={CustomDropdown}
                                />
                                <ErrorMessage name='isActive'>
                                    {msg => <div className='valErrMsg'>{msg}</div>}
                                </ErrorMessage>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ textAlign: 'right', p: 2 }}>
                            <Button variant='outlined' color='secondary' sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
                            <Button type='submit' variant='contained' color='primary'>Save</Button>
                        </Box>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

AddEditDepartment.propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    setOpen: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default AddEditDepartment;
