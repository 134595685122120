import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Typography, IconButton, Paper, Button, Chip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddMedicineCategory from './add';
import { getMedicineCategories, deleteMedicineCategory } from './service';
import './style.css';

const MedicineCategoryList = () => {
    const [openAddMedicineCategory, setOpenAddMedicineCategory] = useState(false);
    const [medicineCategoryList, setMedicineCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const results = await getMedicineCategories();
            setMedicineCategoryList(results);
        } catch (error) {
            console.error(error);
            setMedicineCategoryList([]);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const editMedicineCategory = useCallback((category) => {
        setSelectedCategory(category);
        setOpenAddMedicineCategory(true);
    }, []);

    const deleteRow = useCallback((category) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteMedicineCategory(category.id);
                setMedicineCategoryList(prevList => prevList.filter(item => item.id !== category.id));
                Swal.fire('Deleted!', 'Medicine Category deleted successfully!', 'success');
            } else {
                Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
            }
        });
    }, []);

    const columns = useMemo(() => [
        { field: 'index', headerName: 'Sl No', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => (
                row.isActive
                    ? <Chip label="Active" color="success" />
                    : <Chip label="Inactive" color="warning" />
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <IconButton onClick={() => editMedicineCategory(row)}><EditOutlinedIcon /></IconButton>
                    <IconButton onClick={() => deleteRow(row)}><DeleteOutlineOutlinedIcon /></IconButton>
                </>
            ),
        },
    ], [editMedicineCategory, deleteRow]);

    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ p: 2 }}>
            <div style={{ flex: 1 }}>
                <GridToolbarQuickFilter />
            </div>
            <Button
                size="small"
                className="btn-square"
                variant="contained"
                color="primary"
                onClick={() => {
                    setSelectedCategory(null);
                    setOpenAddMedicineCategory(true);
                }}
            >
                Add Medicine Category
            </Button>
        </GridToolbarContainer>
    );

    return (
        <div className="admin-area-2">
            <Typography variant="h5">Medicine Category List</Typography>
            <div className="page-content">
                <AddMedicineCategory
                    open={openAddMedicineCategory}
                    setOpen={setOpenAddMedicineCategory}
                    data={selectedCategory}
                    refresh={fetchData}
                />
                <Paper>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        columns={columns}
                        pageSize={25}
                        slots={{ toolbar: CustomToolbar }}
                        rows={medicineCategoryList}
                        className={clsx('empty-datagrid', {
                            'datagrid-loaded': medicineCategoryList.length !== 0,
                        })}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                showExport: false,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Paper>
            </div>
        </div>
    );
};

export default MedicineCategoryList;
