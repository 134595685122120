import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Typography, IconButton, Paper, Chip, Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { RemoveRedEyeOutlined, CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import clsx from 'clsx';
import httpClient from '../../../services/http';
import { APIUtils } from '../../../utils/apiutils';

export default function Customers() {
    const [rows, setRows] = useState([]);
    const [userData, setUserData] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);

    const deleteUser = async (data) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('delete-user'), { id: data.user.id });
            setRows(rows.filter(item => item.id !== data.id));
            Swal.fire('Deleted!', 'Doctor has been deleted.', 'success');
        } else {
            Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
        }
    };

    const updateStatus = async (data) => {
        const newStatus = data.user.status === 'active' ? 'inactive' : 'active';
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${newStatus === 'active' ? 'Activate?' : 'Deactivate?'}`,
            icon: newStatus === 'active' ? 'info' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        });

        if (result.isConfirmed) {
            await httpClient.post(APIUtils.getAdminAPIEndpoint('update-user-status'), { id: data.user.id, status: newStatus });
            setRows(rows.map(item => item.id === data.id ? { ...item, user: { ...item.user, status: newStatus } } : item));
            Swal.fire('Status!', newStatus === 'active' ? 'Activated Successfully!' : 'Deactivated Successfully!', 'success');
        }
    };

    const columns = [
        { field: 'id', headerName: 'Sl No', flex: 1 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            valueGetter: ({ row }) => row.user.email
        },
        { field: 'mobileNumber', headerName: 'Phone No', flex: 1 },
        { field: 'age', headerName: 'Age', flex: 1 },
        { field: 'gender', headerName: 'Gender', flex: 1 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => (
                <Chip
                    label={row.user.status === 'active' ? 'Active' : 'InActive'}
                    onClick={() => updateStatus(row)}
                    color={row.user.status === 'active' ? 'success' : 'warning'}
                />
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <IconButton onClick={() => viewPatientDetails(row)}><RemoveRedEyeOutlined /></IconButton>
                    <IconButton onClick={() => deleteUser(row)}><DeleteOutlineOutlined /></IconButton>
                </>
            )
        }
    ];

    const handleClose = () => setOpenPreview(false);

    const viewPatientDetails = (row) => {
        setUserData(row);
        setOpenPreview(true);
    };

    const getPatientList = async () => {
        try {
            const res = await httpClient.get(APIUtils.getAdminAPIEndpoint('patient-list'));
            setRows(res.data.data);
        } catch (e) {
            console.error(e);
            setRows([]);
        }
    };

    useEffect(() => {
        getPatientList();
    }, []);

    const CustomToolbar = () => (
        <GridToolbarContainer sx={{ p: 2 }}>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );

    return (
        <>
            <div className='admin-area-2'>
                <Typography variant='h5'>Customers List</Typography>
                <div className='page-content'>
                    <Paper>
                        <DataGrid
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            columns={columns}
                            rows={rows}
                            pageSize={25}
                            slots={{ toolbar: CustomToolbar }}
                            className={clsx('empty-datagrid', { 'datagrid-loaded': rows.length !== 0 })}
                        />
                    </Paper>
                </div>
            </div>
            <Dialog
                open={openPreview}
                keepMounted
                onClose={handleClose}
                fullWidth
                maxWidth='md'
                className='viewDetails'
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>Patient Info</div>
                    <IconButton onClick={handleClose}><CloseOutlined /></IconButton>
                </DialogTitle>
                <DialogContent>
                    {userData && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}><Typography>Name</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.firstName}</Typography></Grid>
                            <Grid item xs={6}><Typography>Email ID</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.user.email}</Typography></Grid>
                            <Grid item xs={6}><Typography>Mobile Number</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.mobileNumber}</Typography></Grid>
                            <Grid item xs={6}><Typography>Gender</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.gender}</Typography></Grid>
                            <Grid item xs={6}><Typography>Age</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.age}</Typography></Grid>
                            <Grid item xs={6}><Typography>Status</Typography></Grid>
                            <Grid item xs={6}><Typography>{userData.user.status}</Typography></Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}
