import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import httpClient from '../../../services/http';
import { USER_ROLES } from '../../../utils/constants';
import AddVendor from '../../admin/vendors/addVendors';
import SuperAdminProfile from './adminprofile';
import useInfo from '../../../customhooks/user/useinfo';
import { APIUtils } from '../../../utils/apiutils';

export default function AdminProfile() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const userInfo = useInfo();

    const getProfile = async () => {
        try {
            let res = await httpClient.get(APIUtils.getAPIEndpoint('get-profile'));
            setDataLoaded(true);
            if (res?.data?.status === 'success') {
                setInitialValues(res?.data?.data);
            } else {
                Swal.fire(res?.data?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <>
            {
                dataLoaded &&
                <>
                    {userInfo.role === USER_ROLES.SUPER_ADMIN && <SuperAdminProfile data={initialValues} />}
                    {userInfo.role === USER_ROLES.VENDOR && <AddVendor data={initialValues} isEdit={true} />}
                </>
            }
        </>
    );
}
