export const ON_ADMIN_DATA_LOADING_STARTED = 'ON_ADMIN_DATA_LOADING_STARTED';
export const ON_ADMIN_DATA_LOADING_FINISHED = 'ON_ADMIN_DATA_LOADING_FINISHED';

export const ON_DEPARTMENTS_LOAD_SUCCESS = 'ON_DEPARTMENTS_LOAD_SUCCESS';
export const ON_DEPARTMENTS_LOAD_FAILURE = 'ON_DEPARTMENTS_LOAD_FAILURE';

export const ON_QUALIFICATIONS_LOAD_SUCCESS = 'ON_QUALIFICATIONS_LOAD_SUCCESS';
export const ON_QUALIFICATIONS_LOAD_FAILURE = 'ON_QUALIFICATIONS_LOAD_FAILURE';

export const ON_SERVICETYPES_LOAD_SUCCESS = 'ON_SERVICETYPES_LOAD_SUCCESS';
export const ON_SERVICETYPES_LOAD_FAILURE = 'ON_SERVICETYPES_LOAD_FAILURE';
