export const USER_ROLES = {
    'SUPER_ADMIN': 'superadmin',
    'VENDOR': 'vendor',
    'DOCTOR': 'doctor',
    'FIELD_EXECUTIVE': 'fieldexecutive',
    'PATIENT': 'patient',
};

export const vendorTypes = [
    { id: 'Hospital', name: 'Hospital' },
    { id: 'Clinic', name: 'Clinic' },
    { id: 'Doctor', name: 'Doctor' },
];

export const settlementTypes = [
    { id: 'Bank', name: 'Bank' },
    { id: 'UPI', name: 'UPI' },
];

export const adminTypes = [
    { id: 'Owner', name: 'Owner' },
    { id: 'Employee', name: 'Employee' },
];

export const genders = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
];

export const isActiveStatus = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'InActive' }
];

// Regular expression for a password with at least 8 characters,
// containing at least one uppercase letter, one lowercase letter,
// one digit, and one special character
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const invalidPwdMsg = 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character';

export const INDIAN_STATES = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram',
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi',
    'Puducherry', 'Ladakh', 'Jammu and Kashmir'
];

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:00';
export const DEFAULT_START_TIME = '09:00';
export const DEFAULT_END_TIME = '18:00';
export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const DEFAULT_AVAILIBILITY = {
    mondayAvail: 0,
    mondayStartTime: DEFAULT_START_TIME,
    mondayEndTime: DEFAULT_END_TIME,

    tuesdayAvail: 0,
    tuesdayStartTime: DEFAULT_START_TIME,
    tuesdayEndTime: DEFAULT_END_TIME,

    wednesdayAvail: 0,
    wednesdayStartTime: DEFAULT_START_TIME,
    wednesdayEndTime: DEFAULT_END_TIME,

    thursdayAvail: 0,
    thursdayStartTime: DEFAULT_START_TIME,
    thursdayEndTime: DEFAULT_END_TIME,

    fridayAvail: 0,
    fridayStartTime: DEFAULT_START_TIME,
    fridayEndTime: DEFAULT_END_TIME,

    saturdayAvail: 0,
    saturdayStartTime: DEFAULT_START_TIME,
    saturdayEndTime: DEFAULT_END_TIME,

    sundayAvail: 0,
    sundayStartTime: DEFAULT_START_TIME,
    sundayEndTime: DEFAULT_END_TIME,

    address: ''
};