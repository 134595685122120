import dayjs from 'dayjs';
import Swal from 'sweetalert2';

// export default class Utils {
//     //Getting API description based on environment
//     static getAPIDescription(signature) {
//         // eslint-disable-next-line
//         let env = process.env.REACT_APP_ENV || 'prod';
//         let apiGateWay = '';

//         if (env === 'prod') {
//             apiGateWay = '/api';
//         } else {
//             apiGateWay = 'http://localhost:3001/api';
//         }

//         switch (signature) {
//             case 'log-in':
//                 return apiGateWay + '/login';
//             case 'sign-up':
//                 return apiGateWay + '/signup';
//             case 'update-profile':
//                 return apiGateWay + '/update-profile';
//             case 'get-profile':
//                 return apiGateWay + '/get-profile';
//             case 'change-password':
//                 return apiGateWay + '/change-password';

//             case 'admin-login':
//                 return apiGateWay + '/admin/login';
//             case 'admin-signup':
//                 return apiGateWay + '/admin/signup';

//             case 'admin-create-department':
//                 return apiGateWay + '/admin/create-department';
//             case 'admin-update-department':
//                 return apiGateWay + '/admin/update-department';
//             case 'admin-department-list':
//                 return apiGateWay + '/admin/department-list';
//             case 'admin-delete-department':
//                 return apiGateWay + '/admin/delete-department';

//             case 'admin-create-qualification':
//                 return apiGateWay + '/admin/create-qualification';
//             case 'admin-update-qualification':
//                 return apiGateWay + '/admin/update-qualification';
//             case 'admin-qualification-list':
//                 return apiGateWay + '/admin/qualification-list';
//             case 'admin-delete-qualification':
//                 return apiGateWay + '/admin/delete-qualification';

//             case 'admin-create-servicetype':
//                 return apiGateWay + '/admin/create-servicetype';
//             case 'admin-update-servicetype':
//                 return apiGateWay + '/admin/update-servicetype';
//             case 'admin-servicetype-list':
//                 return apiGateWay + '/admin/servicetype-list';
//             case 'admin-delete-servicetype':
//                 return apiGateWay + '/admin/delete-servicetype';

//             case 'admin-create-doctor':
//                 return apiGateWay + '/admin/create-doctor';
//             case 'admin-update-doctor':
//                 return apiGateWay + '/admin/update-doctor';
//             case 'admin-doctor-list':
//                 return apiGateWay + '/admin/doctor-list';
//             case 'admin-import-doctor':
//                 return apiGateWay + '/admin/import-doctor';

//             case 'admin-create-vendor':
//                 return apiGateWay + '/admin/create-vendor';
//             case 'admin-update-vendor':
//                 return apiGateWay + '/admin/update-vendor';
//             case 'admin-vendor-list':
//                 return apiGateWay + '/admin/vendor-list';
//             case 'admin-import-vendor':
//                 return apiGateWay + '/admin/import-vendor';

//             case 'admin-create-fieldexecutive':
//                 return apiGateWay + '/admin/create-fieldexecutive';
//             case 'admin-update-fieldexecutive':
//                 return apiGateWay + '/admin/update-fieldexecutive';
//             case 'admin-fieldexecutive-list':
//                 return apiGateWay + '/admin/fieldexecutive-list';

//             case 'admin-patient-list':
//                 return apiGateWay + '/admin/patient-list';
//             case 'admin-update-user-status':
//                 return apiGateWay + '/admin/update-user-status';
//             case 'admin-delete-user':
//                 return apiGateWay + '/admin/delete-user';

//             case 'admin-addmedicine':
//                 return apiGateWay + '/admin/addmedicine';
//             case 'admin-editmedicine':
//                 return apiGateWay + '/admin/editmedicine';
//             case 'admin-uploadmedicine':
//                 return apiGateWay + '/admin/uploadmedicine';
//             case 'admin-medicinelist':
//                 return apiGateWay + '/admin/medicinelist';
//             case 'admin-delete-medicine':
//                 return apiGateWay + '/admin/delete-medicine';

//             case 'admin-addtest':
//                 return apiGateWay + '/admin/addtest';
//             case 'admin-edittest':
//                 return apiGateWay + '/admin/edittest';
//             case 'admin-uploadtest':
//                 return apiGateWay + '/admin/uploadtest';
//             case 'admin-testlist':
//                 return apiGateWay + '/admin/testlist';
//             case 'admin-delete-test':
//                 return apiGateWay + '/admin/delete-test';

//             case 'admin-appointment-list':
//                 return apiGateWay + '/admin/appointment-list';
//             case 'admin-update-appointment-status':
//                 return apiGateWay + '/admin/update-appointment-status';
//             case 'admin-update-appointment-time':
//                 return apiGateWay + '/admin/update-appointment-time';

//             case 'upload-img-to-s3':
//                 return apiGateWay + '/upload-img-to-s3';

//             case 'departments':
//                 return apiGateWay + '/departments';
//             case 'doctors':
//                 return apiGateWay + '/doctors';
//             case 'doctor-details':
//                 return apiGateWay + '/doctor-details';
//             case 'book-appointment':
//                 return apiGateWay + '/book-appointment';
//             case 'create-order':
//                 return apiGateWay + '/create-order';
//             case 'servicetype-list':
//                 return apiGateWay + '/servicetype-list';
//             case 'myappointment-list':
//                 return apiGateWay + '/myappointment-list';
//             case 'addresses':
//                 return apiGateWay + '/addresses';
//             default:
//             // code block
//         }
//     }
// }

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const getUserType = () => {
    if (window.location.href.indexOf('/admin') !== -1) {
        return 'admin';
    }
    return 'user';
};

export const getBaseUrl = () => {
    // eslint-disable-next-line
    let env = process.env.REACT_APP_ENV || 'local';
    let apiGateWay = '';

    if (env === 'DEV') {
        apiGateWay = 'http://';
    } else {
        apiGateWay = 'https://b82d8jivm0.execute-api.us-east-1.amazonaws.com';
    }
    return apiGateWay;
};

export const objectToFormData = (obj) => {
    const formData = new FormData();
    for (const key in obj) {
        formData.append(key, obj[key]);
    }
    return formData;
};

export const objectToFormDataNested = (obj, formData = new FormData(), parentKey = '') => {
    if (obj && typeof obj === 'object' && !(obj instanceof Date) && !(obj instanceof File)) {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            const formKey = parentKey ? `${parentKey}[${key}]` : key;
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    const arrayKey = `${formKey}[${index}]`;
                    objectToFormData(item, formData, arrayKey);
                });
            } else if (value && typeof value === 'object') {
                objectToFormData(value, formData, formKey);
            } else {
                formData.append(formKey, value);
            }
        });
    } else {
        formData.append(parentKey, obj);
    }
    return formData;
};


export const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const removeEmptyProperties = (obj) => {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            delete obj[key];
        }
    }
    return obj;
};

export const formatDate = (time) => {
    const date = new Date();
    if (time) {
        const [hours, minutes] = time.split(':');
        date.setHours(parseInt(hours), parseInt(minutes));
    }
    return dayjs(date);
};

export const convertTimeFrom24To12 = (time24) => {
    // Split the time string into hours, minutes, and seconds
    const timeSplit = time24.split(':');
    let hours = parseInt(timeSplit[0], 10);
    let minutes = parseInt(timeSplit[1], 10);

    // Determine the period (AM or PM)
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format minutes (pad with leading zero if necessary)
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Construct the 12-hour time string
    const time12 = hours + ':' + minutes + ' ' + period;

    return time12;
};

export const formatDateTime = (inputDateString) => {
    const date = new Date(inputDateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${day} ${month} ${year} ${formattedHours}:${formattedMinutes}${period}`;
};

export const confirmBox = (text = 'You want to proceed', title = 'Are you sure?', icon = 'warning') => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(true); // Resolve the promise when confirmed
            } else {
                resolve(false); // Resolve the promise when canceled or dismissed
            }
        }).catch(error => {
            reject(error); // Reject the promise if there is an error
        });
    });
};
