import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signup } from '../../../redux/actions/authActions';
import { Stack } from '@mui/system';

export default function SignUp() {
	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		gender: '',
		mobileNumber: '',
		age: '',
		password: '',
		confirmPassword: '',
	};

	const loading = useSelector(state => state.signup.loading);
	const result = useSelector(state => state.signup.signupResult);

	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const [msg, setMsg] = useState('');

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().min(3, 'It\'s too short').required('Required'),
		lastName: Yup.string().min(3, 'It\'s too short').required('Required'),
		email: Yup.string().email('Enter valid email').required('Required'),
		gender: Yup.string()
			.oneOf(['male', 'female'], 'Required')
			.required('Required'),
		mobileNumber: Yup.number()
			.typeError('Enter valid Mobile Number')
			.required('Required'),
		age: Yup.number().typeError('Enter valid Age').required('Required'),
		password: Yup.string()
			.min(8, 'Password minimum length should be 8')
			.required('Required'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password')], 'Password not matched')
			.required('Required'),
	});

	const handleSubmit = (values) => {
		setSuccess(false);
		setFailure(false);
		signup(values);
	};

	useEffect(() => {
		if (result) {
			if (result?.status === 'success') {
				setSuccess(true);
				setFailure(false);
			} else {
				setSuccess(false);
				setFailure(true);
			}
			setMsg(result?.message);
		}
	}, [result]);

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 2,
					marginBottom: 6,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Grid container justifyContent='flex-start'>
					<Typography component='h1' variant='h5'>
						Signup
					</Typography>
				</Grid>
				<Box sx={{ mt: 3 }}>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{() => (
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											name='firstName'
											id='firstName'
											label='First name'
											autoFocus
											placeholder='First name'
											required
											helperText={<ErrorMessage name='firstName' />}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											name='lastName'
											id='lastName'
											label='Last name'
											placeholder='Last name'
											required
											helperText={<ErrorMessage name='lastName' />}
										/>
									</Grid>
									<Grid item xs={8}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											required
											id='mobile'
											name='mobileNumber'
											label='Mobile Number'
											placeholder='Mobile number'
											helperText={<ErrorMessage name='mobileNumber' />}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant='outlined'
											size='medium'
											sx={{ height: 40 }}
										>
											Verify
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											required
											name='email'
											label='Email'
											id='email'
											placeholder='Email address'
											helperText={<ErrorMessage name='email' />}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl component='fieldset'>
											<Stack direction={'row'} alignItems={'center'}>
												<FormLabel component='legend' sx={{fontWeight: 'bold'}}>Gender</FormLabel>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												<Field
													row
													as={RadioGroup}
													aria-label='gender'
													name='gender'
													style={{ display: 'initial' }}
													defaultValue='male'
													helperText={<ErrorMessage name='age' />}
												>
													<FormControlLabel
														value='male'
														control={<Radio />}
														label='Male'
													/>
													<FormControlLabel
														value='female'
														control={<Radio />}
														label='Female'
													/>
												</Field>
											</Stack>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											required
											placeholder='Age'
											name='age'
											label='Age'
											type='number'
											id='age'
											helperText={<ErrorMessage name='age' />}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											required
											name='password'
											type='password'
											label='Password'
											placeholder='Enter your password'
											helperText={<ErrorMessage name='password' />}
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											as={TextField}
											fullWidth
											size='small'
											required
											name='confirmPassword'
											type='password'
											label='Enter Confirm Password'
											placeholder='Enter Confirm Password'
											helperText={<ErrorMessage name='confirmPassword' />}
										/>
									</Grid>
								</Grid>
								<Button
									type='submit'
									fullWidth
									size='small'
									variant='contained'
									sx={{ mt: 3, mb: 2 }}
									disabled={loading}
								>
									{loading ? 'Loading' : 'SIGN UP'}
								</Button>
								<Grid container justifyContent='center'>
									<Grid item>
										<Link href='/login' variant='body2'>
											Already have an account? Login
										</Link>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
					{
						success &&
						<Alert severity='success'>{msg}</Alert>
					}
					{
						failure &&
						<Alert severity='error'>{msg}</Alert>
					}
				</Box>
			</Box>
		</Container>
	);
}
