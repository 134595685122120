import React, { useEffect, useRef, useState } from 'react';
import './profile.css';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Paper, Container, Grid, TextField, Divider, Button, Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Stack } from '@mui/system';
import { convertBase64, objectToFormData } from '../../../utils/utils';
import { getProfile, updateProfile } from '../../../redux/actions/profileaction';
import { validateRequired } from '../../../utils/common';
const requiredFields = ['firstName', 'lastName', 'mobileNumber', 'email', 'age'];

export default function UserProfile() {
    const userInfo = useSelector(state => state.profile?.result);
    const fileRef = useRef(null);
    const [formDatas, setFormDatas] = useState(userInfo);
    const [errors, setErrors] = useState({});
    const formSubmitted = useRef(false);
    const [previewImg, setPreviewImg] = useState(userInfo?.profilePic);
    const [file, setFile] = useState(null);

    const handleFileChange = async (e) => {
        const fileData = e.target.files[0];
        setFile(fileData);
        const base64 = await convertBase64(fileData);
        setPreviewImg(base64);
    };

    const setFormValue = (field, value) => {
        const tmp = { ...formDatas, [field]: value };
        setFormDatas(tmp);
        if (formSubmitted.current) {
            const errors = validateRequired(requiredFields, tmp);
            setErrors(errors);
        }
    };

    const openFileInput = () => {
        fileRef.current.click();
    };

    const handleUpdate = async () => {
        formSubmitted.current = true;
        const errors = validateRequired(requiredFields, formDatas);
        if (errors) {
            setErrors(errors); return;
        }
        Swal.showLoading();
        try {
            const formData = objectToFormData(formDatas);
            if (file) formData.append('profilePicNew', file);
            const res = await updateProfile(formData);
            if (res?.status === 'success') {
                Swal.fire(res?.message, '', 'success');
                getProfile();
            } else {
                Swal.fire(res?.message, '', 'error');
            }
        } catch (e) {
            Swal.fire('Something Went Wrong!', '', 'error');
        }
    };

    useEffect(() => {
        if (userInfo) {
            setFormDatas(userInfo);
            setPreviewImg(userInfo?.profilePic);
        } else {
            getProfile();
        }
    }, [userInfo]);


    return (
        <Container component='main' maxWidth='lg' sx={{ mt: 3, mb: 3 }}>
            <Paper elevation={3} className={'profile-grid'}>
                <Box>
                    <Typography variant='h5' className={'padding-20'} sx={{ textAlign: 'left' }}>
                        Profile
                    </Typography>
                </Box>
                <Divider />
                <Grid container>
                    <Grid item md={6} sm={12} xs={12} className='profile-picture-grid'>
                        <Box className={'profile-picture'} onClick={openFileInput}>
                            <CameraAltOutlinedIcon />
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box display={'flex'} justifyContent={'center'}>
                            {
                                previewImg &&
                                <img style={{ maxWidth: '280px' }} src={previewImg} alt='profile pic' />
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <input
                            type='file'
                            accept='image/png, image/gif, image/jpeg'
                            ref={fileRef}
                            name='upload'
                            hidden
                            onChange={handleFileChange}
                        />
                        <TextField
                            label='First Name'
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={formDatas?.firstName || ''}
                            onChange={e => setFormValue('firstName', e.target.value)}
                            {...(errors.firstName ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            label='Last Name'
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={formDatas?.lastName || ''}
                            onChange={e => setFormValue('lastName', e.target.value)}
                            {...(errors.lastName ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            label='Mobile Number'
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={formDatas?.mobileNumber || ''}
                            onChange={e => setFormValue('mobileNumber', e.target.value)}
                            {...(errors.mobileNumber ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            label='Email'
                            disabled={true}
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={formDatas?.email || ''}
                            onChange={e => setFormValue('email', e.target.value)}
                            {...(errors.email ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            label='Age'
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={formDatas?.age || ''}
                            onChange={e => setFormValue('age', e.target.value)}
                            {...(errors.email ? { error: true, helperText: 'This field is required.' } : {})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <FormControl component='fieldset'>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'left'}>
                                <FormLabel component='legend' sx={{ fontWeight: 'bold' }}>Gender &nbsp;</FormLabel>
                                <FormControlLabel
                                    value='male'
                                    control={<Radio checked={formDatas?.gender === 'male'} onChange={e => setFormValue('gender', e.target.value)} />}
                                    label='Male'
                                />
                                <FormControlLabel
                                    value='female'
                                    control={<Radio checked={formDatas?.gender === 'female'} onChange={e => setFormValue('gender', e.target.value)} />}
                                    label='Female'
                                />
                            </Stack>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                <Box className={'padding-20'} sx={{ textAlign: 'right' }}>
                    <Button variant='contained' sx={{ width: 135 }} fullWidth className='btn-square' onClick={handleUpdate}>Save</Button>
                </Box>
            </Paper>
        </Container>
    );
}