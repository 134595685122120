import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const CustomDropdown = ({ field, label, options, ...props }) => {
    return (
        <FormControl fullWidth size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                {...field}
                {...props}
                label={label}
            >
                <MenuItem value="">{label}</MenuItem>
                {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

CustomDropdown.propTypes = {
    field: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
    options: PropTypes.any.isRequired,
    props: PropTypes.any, // props is optional
};

export default CustomDropdown;


