import React, { useMemo, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, TextField, Box, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { createMedicineCategory, updateMedicineCategory } from './service';
import { isActiveStatus } from '../../../utils/constants';
import CustomDropdown from '../../../components/customdropdown';
import PropTypes from 'prop-types';
import './style.css';

const AddEditMedicineCategory = ({ open, setOpen, data, refresh }) => {
    const initialValues = useMemo(() => ({
        name: data?.name || '',
        isActive: data?.isActive ? 1 : 0,
    }), [data]);

    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string().required('Please enter name'),
        isActive: Yup.number().oneOf([0, 1], 'Please select status')
    }), []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback(async (values) => {
        try {
            const res = data
                ? await updateMedicineCategory(data.id, values)
                : await createMedicineCategory({ ...values, isActive: 1 });

            if (res?.status === 'success') {
                Swal.fire(res.message, '', 'success');
                handleClose();
                refresh();
            } else {
                Swal.fire(res.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occurred!';
            Swal.fire(errMsg, '', 'error');
        }
    }, [data, handleClose, refresh]);

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {data ? 'Update' : 'Add'} Medicine Category
                            </div>
                            <IconButton onClick={handleClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        size="small"
                                        name="name"
                                        label="Name"
                                        placeholder="Medicine Category Name"
                                        helperText={<ErrorMessage name="name" />}
                                    />
                                </Grid>
                                {data && (
                                    <Grid item xs={12}>
                                        <Field
                                            name="isActive"
                                            label="Status"
                                            options={isActiveStatus}
                                            component={CustomDropdown}
                                        />
                                        <ErrorMessage name="isActive">
                                            {msg => <div className="valErrMsg">{msg}</div>}
                                        </ErrorMessage>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{ textAlign: 'right', p: 2 }}>
                                <Button variant="outlined" color="secondary" sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
                                <Button type="submit" variant="contained" color="primary">Save</Button>
                            </Box>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

AddEditMedicineCategory.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    data: PropTypes.object,
    refresh: PropTypes.func.isRequired,
};

export default AddEditMedicineCategory;
