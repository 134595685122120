import React, { useState } from 'react';
import AddAddress from './addAddress';
import ViewAddress from './viewAddress';

export default function Address() {
    const [isAddEdit, setIsAddEdit] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(null);

    const setAddEdit = (val) => {
        setIsAddEdit(val);
    };


    return (
        <>
            {isAddEdit ? <AddAddress setAddEdit={setAddEdit} currentAddress={currentAddress} /> : <ViewAddress setAddEdit={setAddEdit} setCurrentAddress={setCurrentAddress} />}
        </>
    );
}