// TimePicker.js
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const TimePicker = ({ selectedTime, ...otherProps }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
            <MobileTimePicker
                value={selectedTime}
                size="small"
                slotProps={{
                    textField: {
                        size: 'small',
                        InputProps: {
                            endAdornment: <InputAdornment position="end">
                                <AccessTimeIcon />
                            </InputAdornment>
                        }
                    }
                }}
                {...otherProps}
            />
        </LocalizationProvider>
    );
};

TimePicker.propTypes = {
    selectedTime: PropTypes.instanceOf(dayjs).isRequired,
    otherProps: PropTypes.any, // otherProp is optional
};

export default TimePicker;


