import React, { useEffect, useState } from 'react';
import './style.css';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Typography, IconButton, Paper, Button, Chip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddDepartment from './add';
import { getDepartments, deleteDepartment } from './service';
import { deepClone } from '../../../utils/utils';

export default function DepartmentList() {
    const [openAddDepartment, setOpenAddDepartment] = useState(false);
    const [departmentList, setDepartmentList] = useState([]);
    const [data, setData] = useState(null);

    const editDepartment = (row) => {
        setData(deepClone(row));
        setOpenAddDepartment(true);
    };

    const deleteRow = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteDepartment(data.id);
                const updatedRows = departmentList.filter(item => item.id !== data.id);
                setDepartmentList(updatedRows);
                Swal.fire('Deleted!', 'Department deleted successfully!', 'success');
            } else {
                Swal.fire('Cancelled', 'Deletion Cancelled!', 'info');
            }
        });
    };

    const columns = [
        { field: 'id', headerName: 'Sl No', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'designation', headerName: 'Designation', flex: 1 },
        {
            field: 'image',
            headerName: 'Image',
            flex: 1,
            renderCell: ({ row }) => <img src={row.image} alt={row.image} height='50' width='50' />
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: ({ row }) => {
                return row.isActive
                    ? <Chip label='Active' color='success' />
                    : <Chip label='InActive' color='warning' />;
            }
        },
        {
            field: 'adf',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <>
                    <IconButton onClick={() => editDepartment(row)}><EditOutlinedIcon /></IconButton>
                    <IconButton onClick={() => deleteRow(row)}><DeleteOutlineOutlinedIcon /></IconButton>
                </>
            )
        },
    ];

    const bootstrapApplication = async () => {
        try {
            let res = await getDepartments();
            setDepartmentList(res.data);
        } catch (e) {
            console.log(e);
            setDepartmentList([]);
        }
    };

    useEffect(() => {
        bootstrapApplication();
    }, []);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ p: 2 }}>
                <div style={{ flex: 1 }}>
                    <GridToolbarQuickFilter />
                </div>
                <Button
                    size='small'
                    className='btn-square'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setData(null);
                        setOpenAddDepartment(true);
                    }}
                >Add Department</Button>
            </GridToolbarContainer>
        );
    };

    return (
        <div className='admin-area-2'>
            <Typography variant='h5'>Department List</Typography>
            <div className='page-content'>
                <AddDepartment
                    open={openAddDepartment}
                    setOpen={setOpenAddDepartment}
                    data={data}
                    refresh={bootstrapApplication}
                />
                <Paper>
                    <DataGrid
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        columns={columns}
                        pageSize={25}
                        slots={{ toolbar: CustomToolbar }}
                        rows={departmentList}
                        className={clsx('empty-datagrid', {
                            'datagrid-loaded': departmentList.length !== 0
                        })}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                showExport: false,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Paper>
            </div>
        </div>
    );
}
