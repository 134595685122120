const surgeryBookings = [
	{
		label: 'General Bed',
		id: 1,
		price: '24,000.00',
		inclusions: [
			{ id: 1, label: 'BED CHARGES' },
			{ id: 2, label: 'MEDICINES' },
			{ id: 3, label: 'INVESTIGATIONS' },
			{ id: 4, label: 'STAY-2DAYS ONLY' },
		],
		exclusions: [{ id: 1, label: 'PRE OPERATIVE INVESTIGATIONS' }],
	},
	{
		label: 'AC SEMI PRIVATE (3 SHARING)',
		id: 2,
		price: '29,000.00',
		inclusions: [
			{ id: 1, label: 'BED CHARGES' },
			{ id: 2, label: 'MEDICINES' },
			{ id: 3, label: 'INVESTIGATIONS' },
			{ id: 4, label: 'STAY-2DAYS ONLY' },
		],
		exclusions: [{ id: 1, label: 'PRE OPERATIVE INVESTIGATIONS' }],
	},
	{
		label: 'AC CABIN (SINGLE ROOM)',
		id: 3,
		price: '40,000.00',
		inclusions: [
			{ id: 1, label: 'BED CHARGES' },
			{ id: 2, label: 'MEDICINES' },
			{ id: 3, label: 'INVESTIGATIONS' },
			{ id: 4, label: 'STAY-2DAYS ONLY' },
		],
		exclusions: [{ id: 1, label: 'PRE OPERATIVE INVESTIGATIONS' }],
	},
];

export { surgeryBookings };
