import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Grid, Typography, IconButton, Divider, TextField, Paper, Button, Box } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomDropdown from '../../../components/customdropdown';
import httpClient from '../../../services/http';
import { objectToFormData, convertBase64 } from '../../../utils/utils';
import { genders } from '../../../utils/constants';
import PropTypes from 'prop-types';
import { APIUtils } from '../../../utils/apiutils';

function AdminProfile(props) {
    const { data } = props;
    const navigate = useNavigate();
    const [previewImg, setPreviewImg] = useState(data?.profilePic);
    const [profilePicName, setProfilePicName] = useState('');

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name Required'),
        lastName: Yup.string().required('Last Name Required'),
        email: Yup.string().required('Email is required').email('Invalid email address'),
        mobileNumber: Yup.string().required('Mobile Number Required'),
    });

    const browseProfilePic = async (e, form, field) => {
        setProfilePicName(e.currentTarget.files[0].name);
        form.setFieldValue(field.name, e.currentTarget.files[0]);
        const fileData = e.target.files[0];
        const base64 = await convertBase64(fileData);
        setPreviewImg(base64);
    };

    const handleSubmit = async (values) => {
        try {
            const formData = objectToFormData(values);
            let res = await httpClient.post(APIUtils.getAPIEndpoint('update-profile'), formData);
            if (res?.data?.status === 'success') {
                Swal.fire(res?.data?.message, '', 'success');
                navigate('/admin');
            } else {
                Swal.fire(res?.data?.message, '', 'error');
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message || 'Some error occured!';
            Swal.fire(errMsg, '', 'error');
        }
    };

    return (
        <div className='admin-area-2'>
            <div className='page-header'>
                <IconButton><ArrowBackIcon /></IconButton>
                <Typography variant='h5'>Update Profile</Typography>
            </div>
            <div className='page-content'>
                <Formik
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form>
                            <Paper>
                                <Box className='input-content-center' sx={{ mt: 0, pt: 1, pb: 2 }}>
                                    <Grid container className='profile-grid' maxWidth='xs'>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                name='firstName'
                                                id='firstName'
                                                label='First Name'
                                                placeholder='First Name'
                                                helperText={<ErrorMessage name='firstName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='middleName'
                                                id='middleName'
                                                label='Middle Name'
                                                placeholder='Middle Name'
                                                helperText={<ErrorMessage name='middleName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                required
                                                size='small'
                                                name='lastName'
                                                id='lastName'
                                                label='Last Name'
                                                placeholder='Last Name'
                                                helperText={<ErrorMessage name='lastName' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                required
                                                id='gender'
                                                name='gender'
                                                label='Gender'
                                                options={genders}
                                                component={CustomDropdown}
                                            />
                                            <ErrorMessage name='gender'>{msg => <div className='valErrMsg'>{msg}</div>}</ErrorMessage>
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                id='mobile'
                                                name='mobileNumber'
                                                label='Mobile Number'
                                                placeholder='Mobile number'
                                                helperText={<ErrorMessage name='mobileNumber' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Field
                                                as={TextField}
                                                required
                                                fullWidth
                                                size='small'
                                                disabled={true}
                                                name='email'
                                                label='Email'
                                                id='email'
                                                placeholder='Email address'
                                                helperText={<ErrorMessage name='email' />}
                                            />
                                        </Grid>
                                        <Grid item md='6' sm='6' xs='12'>
                                            <Typography>Profile Pic:</Typography>
                                            <Field name='profilePicNew'>
                                                {({ field, form }) => (
                                                    <>
                                                        <Button variant='outlined' component='label'>
                                                            <input
                                                                type='file'
                                                                accept='image/png, image/gif, image/jpeg'
                                                                name={field.name}
                                                                hidden={true}
                                                                onChange={(e) => browseProfilePic(e, form, field)}
                                                            />
                                                            Chose File
                                                        </Button>
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant='span' sx={{ ml: 2 }}>{profilePicName ? profilePicName : 'No file selected'}</Typography>
                                            {previewImg && <div style={{ marginTop: '5px' }}><img style={{ maxWidth: '150px' }} src={previewImg} alt='profile pic' /></div>}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box sx={{ textAlign: 'right', p: 2 }}>
                                    <Button variant='outlined' color='secondary' sx={{ mr: 1 }} onClick={() => navigate('/admin')}>Cancel</Button>
                                    <Button type='submit' variant='contained' color='primary'>Save</Button>
                                </Box>
                            </Paper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

AdminProfile.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AdminProfile;