import React, { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { serviceImages } from './constants';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));


function OurServices() {
	const navigate = useNavigate();
	const onServiceClicked = (label) => {
		switch (label) {
			case 'Consult a Doctor':
				navigate('/finddoctors');
				break;
			default:
			// code block
		}
	};

	return (
		<>
			<Typography variant='h5' style={{ textAlign: 'center' }}>
				Our Services
			</Typography>
			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					margin: '80px 160px 100px 160px',
					flexDirection: 'column',
				}}
			>
				<Grid
					container
					spacing={4}
					columns={{ xs: 1, sm: 8, md: 12 }}
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					{serviceImages.map(({ src, label }, index) => (
						<Grid item key={'serviceImage-' + index}>
							<Item
								className='service-tile'
								sx={{
									width: 200,
									height: 180,
									boxSizing: 'border-box',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									gap: 2
								}}

								onClick={() => onServiceClicked(label)}
							>
								<img src={src} alt={`service${index}`} />
								<Typography
									className='service-text'
									style={{ textAlign: 'center' }}
								>
									{label}
								</Typography>
							</Item>
						</Grid>
					))}
				</Grid>
			</Box>
		</>
	);
}

export default OurServices;
