import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid, Button, Avatar, Box, Card, CardContent } from '@mui/material';
import { convertTimeFrom24To12 } from '../../../utils/utils';
import httpClient from '../../../services/http';
import { BookAppointment } from './bookApointmentPopup';
import './doctordetails.css';
import { useSelector } from 'react-redux';
import { openLoginPopup } from '../../../redux/actions/authActions';
import { APIUtils } from '../../../utils/apiutils';

export default function DoctorDetails() {
	const { doctorId } = useParams();
	const [doctorDetails, setDoctorDetails] = useState({});
	const [openConsulationPopup, setOpenConsulationPopup] = useState(false);
	const [appointmentType, setAppointmentType] = useState(1);
	const userState = useSelector(state => state.auth.user);

	useEffect(() => {
		const fetchDoctorDetails = async () => {
			try {
				const url = `${APIUtils.getAPIEndpoint('doctor-details')}/${doctorId}`;
				const { data } = await httpClient.get(url);
				if (data.status === 'success') {
					setDoctorDetails(data.data);
				} else {
					setDoctorDetails({});
				}
			} catch (error) {
				console.error('Error fetching doctor details:', error);
				setDoctorDetails({});
			}
		};

		fetchDoctorDetails();
	}, [doctorId]);

	const bookAppointment = useCallback((type) => {
		if (!userState) {
			openLoginPopup();
		} else {
			setOpenConsulationPopup(true);
			setAppointmentType(type);
		}
	}, [userState]);

	const renderConsultationBox = useCallback((type, price) => (
		<Box component="section" className="consultation-box">
			<Typography variant="body2" color="text.secondary" sx={{ color: '#000' }}>
				{type} : <span className="consultation-price">₹{price}</span>
			</Typography>
			<Button
				size="small"
				variant="contained"
				className="btn-square book-now-button"
				onClick={() => bookAppointment(type === 'Personal Visit' ? 1 : 2)}>
				<Typography variant="body2" sx={{ fontWeight: 600 }}>Book Now</Typography>
			</Button>
		</Box>
	), [bookAppointment]);

	const renderAvailability = useCallback((availability, idx) => {
		if (!availability) return null;

		const days = [
			{ day: 'Monday', isAvailable: availability.mondayAvail, startTime: availability.mondayStartTime, endTime: availability.mondayEndTime },
			{ day: 'Tuesday', isAvailable: availability.tuesdayAvail, startTime: availability.tuesdayStartTime, endTime: availability.tuesdayEndTime },
			{ day: 'Wednesday', isAvailable: availability.wednesdayAvail, startTime: availability.wednesdayStartTime, endTime: availability.wednesdayEndTime },
			{ day: 'Thursday', isAvailable: availability.thursdayAvail, startTime: availability.thursdayStartTime, endTime: availability.thursdayEndTime },
			{ day: 'Friday', isAvailable: availability.fridayAvail, startTime: availability.fridayStartTime, endTime: availability.fridayEndTime },
			{ day: 'Saturday', isAvailable: availability.saturdayAvail, startTime: availability.saturdayStartTime, endTime: availability.saturdayEndTime },
			{ day: 'Sunday', isAvailable: availability.sundayAvail, startTime: availability.sundayStartTime, endTime: availability.sundayEndTime },
		];

		return (
			<Box key={idx} className="service-timing-grid">
				<div className="heading-section">
					<Typography className="heading-section-font" sx={{ ml: 0 }}>{availability?.address}</Typography>
				</div>
				<Box component="section" sx={{ textAlign: 'left', mt: 1, mb: 1 }}>
					{days.filter(day => day.isAvailable).map(({ day, startTime, endTime }) => (
						<Box key={day} className="availability-item">
							<Typography className=""><strong>{day}</strong>: {convertTimeFrom24To12(startTime)} - {convertTimeFrom24To12(endTime)}</Typography>
						</Box>
					))}
				</Box>
			</Box>
		);
	}, []);

	return (
		<>
			<Container className="`container`">
				<Grid container fullWidth className="grid-container">
					<Grid item md={2} sm={2} xs={2}>
						<Avatar className="doc-avatar" alt="Doctor's Photo" src={doctorDetails.profilePic} variant="square" />
					</Grid>
					<Grid item md={10} sm={10} xs={10}>
						<Card className="card">
							<CardContent className="card-content">
								<Typography gutterBottom variant="h5">
									Dr. {[doctorDetails.firstName, doctorDetails.middleName, doctorDetails.lastName].filter(Boolean).join(' ')}
								</Typography>
								<Typography variant="body2" color="text.secondary" className="name-text">
									{doctorDetails?.qualifications}
								</Typography>
								<Typography variant="body2" color="text.secondary" className="name-text">
									{doctorDetails?.department?.designation}
								</Typography>
								<Typography variant="body2" color="text.secondary" className="experience-text">
									{doctorDetails.experience} Years of Experience
								</Typography>
							</CardContent>
						</Card>

						<Grid container className="consultation-grid">
							<Grid item>{renderConsultationBox('Personal Visit', doctorDetails.personalVisitPrice)}</Grid>
							{doctorDetails.teleConsultation > 0 && (
								<Grid item>{renderConsultationBox('Tele Consultation', doctorDetails.teleConsultationPrice)}</Grid>
							)}
						</Grid>

						<Box component="section">
							<Box component="div" className="heading-section">
								<Typography className="heading-section-font">About</Typography>
							</Box>
							<Box component="p" sx={{ textAlign: 'left', lineHeight: 1.5, padding: '10px 0' }}>
								{doctorDetails?.about}
							</Box>
						</Box>

						<Box component="section">
							<Box component="div" className="heading-section">
								<Typography className="heading-section-font">Awards</Typography>
							</Box>
							<Box component="p" sx={{ textAlign: 'left', lineHeight: 1.5, padding: '10px 0' }}>
								{doctorDetails?.awards}
							</Box>
						</Box>

						<Box className="section-container">
							<Box className="heading-section" sx={{ mt: 1, mb: 1 }}>
								<Typography className="heading-section-font">Service Address & Timings:</Typography>
							</Box>
							{doctorDetails?.availibilities?.map((availability, idx) => (
								renderAvailability(availability, idx)
							))}
						</Box>

					</Grid>
				</Grid>
			</Container>

			{openConsulationPopup && (
				<BookAppointment
					setOpen={setOpenConsulationPopup}
					open={openConsulationPopup}
					doctorDetails={doctorDetails}
					appointmentType={appointmentType}
					userInfo={userState}
				/>
			)}
		</>
	);
}

DoctorDetails.displayName = 'DoctorDetails';
