import React from 'react';
import './booksurgery.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { surgeryBookings } from './constants';

function BookSurgery() {

	return (
		<Container className='doc-cat-content'>
			<Box>
				<Typography className='ext-large-brown-txt'>
					Surgery Services
				</Typography>
			</Box>
			<Grid container spacing={2} justifyContent={'center'}>
				{surgeryBookings.map(({ exclusions, id, inclusions, label, price }) => (
					<Grid
						item
						xs={12}
						gap={10}
						sm={3}
						sx={{
							maxWidth: '100% !important',
						}}
						key={id}
					>
						<Card
							sx={{
								width: 320,
								height: 460,
								padding: '40px 20px',
								boxSizing: 'border-box',
								border: '1px solid #D8B651',
								boxShadow:
									'0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)',
								borderRadius: '20px',
							}}
						>
							<CardContent
								style={{ padding: 0 }}
								sx={{ boxSizing: 'border-box', marginBottom: '15px' }}
							>
								<Typography gutterBottom className='room-type'>
									{label.toLocaleUpperCase()}
								</Typography>
								<Typography gutterBottom className='room-price'>
									{price}
								</Typography>
							</CardContent>

							<CardContent
								style={{ padding: 0 }}
								sx={{
									boxSizing: 'border-box',
									marginBottom: '10px',
									textAlign: 'left',
									marginLeft: '20px',
								}}
							>
								<Typography gutterBottom className='room-type'>
									INCLUSION
								</Typography>
								{inclusions.map(({ id, label }) => {
									return (
										<Typography key={id} gutterBottom className='inclusions'>
											{label}
										</Typography>
									);
								})}
							</CardContent>

							<CardContent
								style={{ padding: 0 }}
								sx={{
									boxSizing: 'border-box',
									textAlign: 'left',
									marginLeft: '20px',
								}}
							>
								<Typography gutterBottom className='room-type'>
									EXCLUSION
								</Typography>
								{exclusions.map(({ label, id }) => {
									return (
										<Typography key={'exclusion-' + id} gutterBottom className='inclusions'>
											{label}
										</Typography>
									);
								})}
							</CardContent>
							<CardActions
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Button
									variant='contained'
									className='btn-square'
									onClick={() => { }}
								>
									Book Now
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
export default BookSurgery;
