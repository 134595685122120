import store from '../store';
import * as adminTypes from '../types/admintypes';
import httpClient from '../../services/http';
import { APIUtils } from '../../utils/apiutils';

const dispatch = store.dispatch;

export const getDepartmentList = async () => {
	try {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_STARTED });
		const endpoint = APIUtils.getAdminAPIEndpoint('department');
		const res = await httpClient.get(endpoint);
		const data = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: adminTypes.ON_DEPARTMENTS_LOAD_SUCCESS, payload: data });
	} catch {
		dispatch({ type: adminTypes.ON_DEPARTMENTS_LOAD_SUCCESS, payload: [] });
	} finally {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_FINISHED });
	}
};

export const getQualificationList = async () => {
	try {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_STARTED });
		const endpoint = APIUtils.getAdminAPIEndpoint('qualification');
		const res = await httpClient.get(endpoint);
		const data = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: adminTypes.ON_QUALIFICATIONS_LOAD_SUCCESS, payload: data });
	} catch {
		dispatch({ type: adminTypes.ON_QUALIFICATIONS_LOAD_SUCCESS, payload: [] });
	} finally {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_FINISHED });
	}
};

export const getServiceTypeList = async () => {
	try {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_STARTED });
		const endpoint = APIUtils.getAdminAPIEndpoint('servicetype');
		const res = await httpClient.get(endpoint);
		const data = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: adminTypes.ON_SERVICETYPES_LOAD_SUCCESS, payload: data });
	} catch {
		dispatch({ type: adminTypes.ON_SERVICETYPES_LOAD_SUCCESS, payload: [] });
	} finally {
		dispatch({ type: adminTypes.ON_ADMIN_DATA_LOADING_FINISHED });
	}
};