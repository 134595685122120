import store from '../store';
import * as vendorTypes from '../types/vendortypes';
import httpClient from '../../services/http';
import { APIUtils } from '../../utils/apiutils';

const dispatch = store.dispatch;

export const vendorSignup = async (payload) => {
	await httpClient.post(APIUtils.getAPIEndpoint('vendor-signup'), payload).then((res) => {
		dispatch({ type: vendorTypes.ON_VENDOR_SIGNUP_SUCCESS, payload: res.data });
	}).catch((err) => {
		dispatch({ type: vendorTypes.ON_VENDOR_SIGNUP_FAILURE, payload: err });
	});
};

export const doctorSignup = async (payload) => {
	await httpClient.post(APIUtils.getAPIEndpoint('doctor-signup'), payload).then((res) => {
		dispatch({ type: vendorTypes.ON_DOCTOR_SIGNUP_SUCCESS, payload: res.data });
	}).catch((err) => {
		dispatch({ type: vendorTypes.ON_DOCTOR_SIGNUP_FAILURE, payload: err });
	});
};

export const getVendorTypes = async () => {
	await httpClient.get(APIUtils.getAPIEndpoint('get-all-vendor-types')).then((res) => {
		dispatch({ type: vendorTypes.ON_VENDOR_TYPES_LOAD_SUCCESS, payload: res.data });
	}).catch((err) => {
		dispatch({ type: vendorTypes.ON_VENDOR_TYPES_LOAD_FAILURE, payload: err });
	});
};

export const getDepartmentList = async () => {
	await httpClient.get(APIUtils.getAdminAPIEndpoint('department')).then((res) => {
		const departmentList = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: vendorTypes.ON_DEPARTMENT_TYPES_LOAD_SUCCESS, payload: departmentList });
	}).catch(() => {
		dispatch({ type: vendorTypes.ON_DEPARTMENT_TYPES_LOAD_FAILURE, payload: [] });
	});
};

export const getQualifications = async () => {
	await httpClient.get(APIUtils.getAdminAPIEndpoint('qualification')).then((res) => {
		const qualifications = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: vendorTypes.ON_QUALIFICATIONS_LOAD_SUCCESS, payload: qualifications });
	}).catch(() => {
		dispatch({ type: vendorTypes.ON_QUALIFICATIONS_LOAD_FAILURE, payload: [] });
	});
};

export const getServiceTypes = async () => {
	await httpClient.get(APIUtils.getAPIEndpoint('servicetype-list')).then((res) => {
		const serviceTypes = res.data.data.filter(e => e.isActive).map(({ id, name }) => ({ id, name }));
		dispatch({ type: vendorTypes.ON_SERVICETYPES_LOAD_SUCCESS, payload: serviceTypes });
	}).catch(() => {
		dispatch({ type: vendorTypes.ON_SERVICETYPES_LOAD_FAILURE, payload: [] });
	});
};